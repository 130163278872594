import React, { Component, Suspense } from "react";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Translation } from 'react-i18next';

import "./App.css";
import DepositForm from "./DepositForm";
import Header from './components/header/Header';
import WithdrawalForm from "./WithdrawalForm";
import { Footer, Card } from "react-materialize";
import FontAwesome from "react-fontawesome";

import LanguageModal from './Modal/LanguageModal';
import SelectLanguage from './SelectLanguage';
import { resources } from './translation/translations';


//// for local dev
// export const backendUrl = process.env.REACT_APP_BACKEND_DEV_URL;

//// for deploy
export const backendUrl = process.env.REACT_APP_BACKEND_URL;


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  showModalHandler = () => {
    this.setState({
      showModal: true,
    });
  }

  hideModalHandler = () => {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const selectedLanguage = resources[i18n.language].translations.LANGUAGE;

    return (
      <Suspense fallback={null}>
        <I18nextProvider i18n={i18n}>
          <div>

            <Header config={this.props.config}/>

            {/* <nav
              className="light-blue" 
              style={{ background: "white", color: "rgb(153, 204, 255)" }}
            >
              <div className="nav-wrapper container">
                <FontAwesome name="anchor" /> 
                <div>
                  <span className="App-title">
                  {this.props.config.UI.anchorName}
                  </span>
                  <span>              
                    <img src={SampleImage.threeLineMenu} className="Idbook__DisplayImage" 
                      style={{ height: "45px", width: "50px", cursor:"pointer", marginRight:"0.5rem",zIndex: "" }}
                      onClick={showModalHandler} 
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </nav> */}

            <div className="container">
              <Card>
                {/* The first Stripe USD anchor for Stellar. <br /> Deposit and withdraw
            with a card, and we never have access to your payment details so
            you're totally secure. */}
                <div>
                  {/* Deposit XLM to your Account ID with a credit card. */}
                  <Translation>{(t, { i18n }) => <span>{t('toppage.text1')}</span>}</Translation>
                </div>
                <br />
                <div>
                  {/* (If you don't have Account ID, you can generate Account ID using App at StellarSpace) */}
                  <Translation>{(t, { i18n }) => <span>{t('toppage.text2')}</span>}</Translation>
                  <br />
                  <a
                    style={{ textDecoration: "none" }}
                    // href={`https://buy.moonpay.io/`}
                    href={'https://app.spaceeight.work/#signup/'}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  > Mobile App, </a>
                  <span> </span>
                  <a
                    style={{ textDecoration: "none" }}
                    // href={`https://buy.moonpay.io/`}
                    href={'https://www.spaceeight.work/#signup/'}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  > Desktop App</a>
                  {/* <Translation>{(t, {i18n}) => <span>{t('general.text1')}</span>}</Translation> */}
                </div>
              </Card>
              <DepositForm config={this.props.config} i18n={i18n} />
              <WithdrawalForm config={this.props.config} i18n={i18n} />


              <div className="languageSelect-Container">
                <div onClick={this.showModalHandler}>
                  {/* Language: {selectedLanguage} */}
                  <Translation>{(t, { i18n }) => <span>{t('toppage.text3')}</span>}</Translation>: {selectedLanguage}
                  <span className="priceUpdateButton">
                    {/* Change */}
                    <Translation>{(t, { i18n }) => <span>{t('toppage.text4')}</span>}</Translation>
                  </span>
                </div>
                <LanguageModal
                  showModal={this.state.showModal}
                  hideModalHandler={this.hideModalHandler}
                >
                  <div className="closeModalButton"
                    onClick={this.hideModalHandler}
                  >
                    X
                  </div>

                  <SelectLanguage
                    hideModalHandler={this.hideModalHandler}
                  />

                  <div className="closeModalButton"
                    onClick={this.hideModalHandler}
                  >
                    X
                  </div>
                </LanguageModal>
              </div>

            </div>

            {/* <Footer
          // style={{ background:"rgb(153, 204, 255)" }}
          // className="light-blue"
          // copyrights={
          //   // "We never have access to your payment details. Problems? Email " +
          //   // this.props.config.UI.supportEmail
          //   "Problems? Email " + this.props.config.UI.supportEmail
          // }
        /> */}
          </div>
        </I18nextProvider>
      </Suspense>
    );
  }
}

export default App;
