import React, { useEffect } from 'react';
import { useState } from 'react';
import BigNumber from 'bignumber.js';
// import { useTranslation, setDefaults } from 'react-i18next';

import HeaderBackdrop from './HeaderBackdrop';
// import LanguageSelector from './I18nextLSelector.jsx';
// const images = require('../images');

// import * as firebase from "firebase/app";

// // Add the Firebase services that you want to use
// import "firebase/auth";
// import "firebase/firestore";


const LanguageModal = props => {
  // console.log('HeaderModal-props', props);

  const [showModal2, setShowModal2] = useState(false);
  const [installStyle, setInstallStyle] = useState('none');

  // const { t, i18n } = useTranslation();

  useEffect(() => {

  });

  let headerModal;
  let languageModalStyle;
  if (props.showModal) {
    languageModalStyle = {
      position: "fixed",
      // width: "80%",
      // height: "30%",
      maxHeight:"80%",
      left: "10%",
      top: "8vh",
      top: "5%",
      background: "white",
      // background: "var(--island-background)",
      borderRadius: "5px",
      zIndex: "500",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.26)",
      // fontSize: "60px",
      fontWeight: "bolder",
      // opacity: props.showModal ? "1" : "0"
      padding: "10px",
      overflowY: "auto"
    };

    const modalEmelntStyle = {
      padding: "30px"
    }

    headerModal = (
      <div style={languageModalStyle} >

        {/* <div>headermodal-content</div> */}

        {props.children}

        {/* <div onClick={props.showModal2Handler}>xxx</div> */}
        {/* <div onClick={() => {clickHandler()}}>clicked?</div> */}
      </div>
    )
  }



  return (
    <div>
      <HeaderBackdrop 
        showModal={props.showModal} 
        // clicked={props.hideModalHandler} 
      />

      <div>
        {headerModal}
      </div>
      {/* {headerModal2} */}
    </div>
  )
};

export default LanguageModal;