import React from "react";
import { useState } from "react";
import { useTranslation } from 'react-i18next'
import _ from 'lodash';

import { resources } from './translation/translations'

import "./SelectLanguage.css";

const SelectLanguage = (props) => {

  const { t, i18n } = useTranslation();

  const [showLanguageList, setShowLanguageList] = useState(false);

  // const showLanguageListHandler = () => {
  //   setShowLanguageList(!showLanguageList);
  // };

  const changeLanguage = (input) => {
    i18n.changeLanguage(input);
  }

  const languageNameList = [];

  for (const element in resources) {
    // console.log(element, resources[element].translations.LANGUAGE);
    languageNameList.push({
      code: element,
      LANGUAGE: resources[element].translations.LANGUAGE
    });
  }
  console.log(languageNameList)

  const sortedLanguageList = _.sortBy(languageNameList, 'LANGUAGE') 
  const languageListBody = (
      <ul>
        {sortedLanguageList.map((ele, index) => {
            return (
            <div key={index} className="listElement">
              {/* {ele.code}  */}
              <span 
                onClick={() => {
                  changeLanguage(ele.code);
                  props.hideModalHandler();
                }}
              >
                {ele.LANGUAGE}
              </span>
            </div>
            );
          })
        }
      </ul>

  );

  return (
    <div>
        <div>
          {languageListBody}
        </div>
    </div>

  );
}

export default SelectLanguage