import React, { useEffect } from 'react';
import { useState } from 'react';
import BigNumber from 'bignumber.js';
// import { useTranslation, setDefaults } from 'react-i18next';

import HeaderBackdrop from './HeaderBackdrop';
// import LanguageSelector from './I18nextLSelector.jsx';
// const images = require('../images');

// import * as firebase from "firebase/app";

// // Add the Firebase services that you want to use
// import "firebase/auth";
// import "firebase/firestore";


const DepositFormModal = props => {
  // console.log('HeaderModal-props', props);

  const [showModal2, setShowModal2] = useState(false);
  const [installStyle, setInstallStyle] = useState('none');

  // const { t, i18n } = useTranslation();




  useEffect(() => {

    // window.addEventListener('DOMContentLoaded', () => {
    //   console.log('in DOMContentLoaded')
    //   let displayMode = 'browser tab';
    //   if (navigator.standalone) {
    //     displayMode = 'standalone-ios';
    //   }
    //   if (window.matchMedia('(display-mode: standalone)').matches) {
    //     displayMode = 'standalone';
    //   }
    //   // Log launch display mode to analytics
    //   console.log('DISPLAY_MODE_LAUNCH:', displayMode);
    // });





    // const addBtn = document.getElementById('add-button');
    // console.log('addBtn headermodal', addBtn);
    // addBtn.style.display = 'none';

  
      // window.addEventListener('beforeinstallprompt', (event) => {
      //   console.log('beforeinstallprompt fired installhandler headermodal');
      //   event.preventDefault();
      //   // deferredPrompt = event;
      //   console.log('in beforeinstallprompt', event);
      //   // addBtn.style.display = 'block';

      //   // props.d.driverFbUser.handlers.setDeferredPrompt(event);
      //   console.log('driver deferredPrompt', props.d.driverFbUser.deferredPrompt);
      //   setInstallStyle('block');
  
      // });
  });



  // const fbfsSignOutHandler = () => {
  //   firebase.auth().onAuthStateChanged(function (user) {
  //     if (user) {
  //       // User is signed in.
  //       firebase.database().ref('users/' + user.uid).update({
  //         pass2fa: false
  //       }, function (error) {
  //         if (error) {
  //           console.log('error occured in pass2fa false');
  //         } else {
  //           console.log('user pass2fa false');

  //           firebase.auth().signOut().then(function () {
  //             // Sign-out successful.
  //             console.log('fb sign out success');
  //             localStorage.removeItem('fb-token-expiration');
  //             localStorage.removeItem('fb-token');
  //             localStorage.removeItem('fbfs-token');

  //             props.fbUserStateHandler({
  //               state: 'signout'
  //             });

  //             props.d.session.handlers.logout();

  //             props.hideModalHandler();
  //             // setFbDisplayname('');
  //             // setFbUserId('');
  //             // setfbEmailVerification(false);

  //           }).catch(function (error) {
  //             // An error happened.
  //             console.log(error);
  //           });

  //         }
  //       });

  //     } else {
  //       // No user is signed in.
  //       console.log('no fb user');
  //       props.d.session.handlers.logout();
  //     }
  //   });

  // }

  // const moveHomePageHandler = () => {
  //   const wl = window.location;
  //   window.location.href = wl.protocol + '//' + wl.host + '/#fbsignin'
  // }

  // const logoutConfirmHandler = () => {
  //   if (window.confirm(`Is it no problem to logout? ${t('subNav.text5')}?`)) {
  //     fbfsSignOutHandler();
  //     moveHomePageHandler();
  //   }
  // }

  // const installHandler = () => {
  //   const deferredPrompt = props.d.driverFbUser.deferredPrompt;
  //   console.log('in installHandler', deferredPrompt);

  //   if (deferredPrompt) {
  //     deferredPrompt.prompt();

  //     deferredPrompt.userChoice
  //       .then((choiceResult) => {
  //       console.log(choiceResult.outcome);
  //       if (choiceResult.outcome === 'dismissed') {
  //         console.log('user canceled install');
  //       } else {
  //         console.log('user added to home screen');
  //         setInstallStyle('none');
  //         props.hideModalHandler();
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  //     // deferredPrompt = null;
  //   }
  // }

  let headerModal;
  let headerModalStyle;
  if (props.showModal) {
    headerModalStyle = {
      position: "fixed",
      width: "80%",
      // height: "30%",
      maxHeight:"80%",
      left: "10%",
      top: "8vh",
      top: "12px",
      background: "white",
      // background: "var(--island-background)",
      borderRadius: "5px",
      zIndex: "500",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.26)",
      // fontSize: "60px",
      fontWeight: "bolder",
      // opacity: props.showModal ? "1" : "0"
      padding: "10px",
      overflowY: "auto"
    };

    const modalEmelntStyle = {
      padding: "30px"
    }

    headerModal = (
      <div style={headerModalStyle} >

        {/* <div>headermodal-content</div> */}

        {props.children}

        {/* <div onClick={props.showModal2Handler}>xxx</div> */}
        {/* <div onClick={() => {clickHandler()}}>clicked?</div> */}
      </div>
    )
  }



  return (
    <div>
      <HeaderBackdrop 
        showModal={props.showModal} 
        // clicked={props.hideModalHandler} 
      />

      <div>
        {headerModal}
      </div>
      {/* {headerModal2} */}
    </div>
  )
};

export default DepositFormModal;