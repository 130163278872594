import React, { Component } from "react";

import { Translation } from 'react-i18next';
import StellarSdk from 'stellar-sdk';

import { deposit, getXLMPrice } from "./apiUtils.js";
import { Button, Card, Modal } from "react-materialize";
import CurrencyInput from "react-currency-input";
import StripeCheckout from "react-stripe-checkout";

import DepositFormModal from "./Modal/DepositFormModal";

import StripeCardPay from './StripeCardPay';

const minCentAmount = 500;

class DepositForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stellarAddress: "",
      amount: "0.00",
      centAmount: 0,
      transactionID: "",
      showModal: false,
      xlmPrice: null,
      getXlmAmount: null,
      invalidAddressMessage: '',
    };
    this.handleAddressChange = this.handleAddressChange.bind(this);

    this.handleAmountChange = this.handleAmountChange.bind(this);
  }

  componentDidMount() {
    this.getXLMAmountHandler();
    
    //// get account id from query params and fill in address
    const url = new URL(window.location.href);
    const queryParams = url.searchParams;
    const paramsId = queryParams.get('id');
    // console.log(url);
    // console.log(queryParams, paramsId);
    if (paramsId) {
      this.setState({ stellarAddress: paramsId });
    }
  }

  handleAddressChange(event) {
    this.setState({ stellarAddress: event.target.value });

    if (this.state.amount && event.target.value && 
      event.target.value.length === 56 &&
      Number(this.state.amount.split('$')[1]) >= 5
    ) {
      this.getXLMAmountHandler();
    }

    this.checkValidAddressHandler(event.target.value);
  }

  handleAmountChange(event, maskedValue, floatValue) {
    this.setState({
      amount: event.target.value,
      centAmount: Math.round(floatValue * 100)
    });

    if (this.state.amount !== event.target.value &&
      Number(event.target.value.split('$')[1]) >= 5 && 
      this.state.stellarAddress.length === 56) {
      this.getXLMAmountHandler();
    }
  }

  onToken = token => {
    console.log(token);
    window.$(document).ready(function() {
      window
        .$("#depModal")
        .modal({
          dismissible: false
        })
        .modal("open");
    });
    this.setState({ showModal: true });

    deposit(token, this.state.stellarAddress, this.state.centAmount)
      .then(transactionID => {
        console.log(transactionID);
        this.setState({ transactionID });
      })
      .catch(err => {
        console.log("caught");
        console.log(err);
        this.setState({ transactionID: "FAIL" });
      });
  };

  hideModalHandler = () => {
    this.setState({ 
      showModal: false,
      transactionID: ''
     });
    window.location.reload();
  }

  getXLMAmountHandler = () => {
    this.setState({ getXlmAmount: '' })
    getXLMPrice()
    .then(res => {
      console.log(res);
      this.setState({ xlmPrice: res.toFixed(3) });
      // console.log(this.state.amount.split('$'));

      const xlmAmount = Number(this.state.amount.split('$')[1]) / res * 0.964;
      
      let displayAmount
      
      if (xlmAmount >= 100) {
        displayAmount = xlmAmount.toFixed(0);
      } else {
        displayAmount = xlmAmount.toFixed(2);
      }
      console.log(this.state.amount);
      console.log(Number(this.state.amount.split('$')[1]) / res * 0.964);
      
      this.setState({ getXlmAmount: displayAmount })
    })
    .catch(err => {
      console.log(err);
    })
  }

  checkValidAddressHandler = (input) => {
    
    if (input && !StellarSdk.StrKey.isValidEd25519PublicKey(input)) {
      // console.log('invalid Account ID');
      // this.setState({ invalidAddressMessage: 'invalid Account ID' });
      this.setState({ invalidAddressMessage: <Translation>{(t, {i18n}) => <span>{t('depositForm.text17')}</span>}</Translation> });
      return false;
    }

    this.setState({ invalidAddressMessage: '' });
    return true;
  }

  render() {
    console.log(this.state.transactionID);
    return (
      <div>
        <DepositFormModal 
          showModal={this.state.showModal}
          hideModalHandler={this.hideModalHandler}
        >
          <div>
          {/* {this.state.transactionID ? 
            this.state.transactionID !== "FAIL"
                ? "Deposit succeeded"
                : "Deposit failed"
              : "Processing deposit..."
          } */}
          {this.state.transactionID ? 
            this.state.transactionID !== "FAIL"
                ? <div>
                    {/* Deposit succeeded */}
                    <Translation>{(t, {i18n}) => <span>{t('depositForm.text1')}</span>}</Translation>
                  </div>
                : <div>
                    {/* Deposit failed */}
                    <Translation>{(t, {i18n}) => <span>{t('depositForm.text2')}</span>}</Translation>
                  </div>
              : <div>
                  {/* Processing deposit... */}
                  <Translation>{(t, {i18n}) => <span>{t('depositForm.text3')}</span>}</Translation>
                </div>
          }
          </div>
          <p>
            {this.state.transactionID ? (
              this.state.transactionID !== "FAIL" ? (
                <div>
                  {/* Your deposit has completed successfully with transaction ID:{" "} */}
                  <Translation>{(t, {i18n}) => <span>{t('depositForm.text4')}</span>}</Translation>
                  <br />{" "}
                  <a
                    href={
                      "https://stellar.expert/explorer/public/tx/" + this.state.transactionID
                    }
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    {this.state.transactionID}
                  </a>
                  {/* {this.state.transactionID} */}
                </div>
              ) : (
                // "Your deposit did not complete successfully. If you have been charged, please contact support at " +
                // this.props.config.UI.supportEmail

                <div>
                  {/* Your deposit did not complete successfully. If you have been charged, please contact support at */}
                  <Translation>{(t, {i18n}) => <span>{t('depositForm.text5')}</span>}</Translation>
                  <span> {this.props.config.UI.supportEmail}</span>
                  {/* <Translation>{(t, {i18n}) => <span>{t('general.text1')}</span>}</Translation>  */}
                </div>
              )
            ) : (
              // "Your deposit is being processed and we are attempting to send your funds."
              <div>
                {/* Your deposit is being processed and we are attempting to send your funds. */}
                <Translation>{(t, {i18n}) => <span>{t('depositForm.text6')}</span>}</Translation>
              </div>
            )}
          </p>

          {this.state.transactionID ? 
            <button onClick={this.hideModalHandler}>
              {/* close */}
              <Translation>{(t, {i18n}) => <span>{t('depositForm.text7')}</span>}</Translation>
            </button>
          : null
          }
        </DepositFormModal>
        {/* <Modal
          id="depModal"
          header={
            this.state.transactionID
              ? this.state.transactionID !== "FAIL"
                ? "Deposit succeeded"
                : "Deposit failed"
              : "Processing deposit..."
          }
          modalOptions={{
            complete: () => {
              this.setState({ transactionID: "" });
            }
          }}
        >
          <p>
            {this.state.transactionID ? (
              this.state.transactionID !== "FAIL" ? (
                <div>
                  Your deposit has completed successfully with transaction ID:{" "}
                  <br />{" "}
                  <a
                    href={
                      "https://stellarchain.io/tx/" + this.state.transactionID
                    }
                  >
                    {this.state.transactionID}
                  </a>
                </div>
              ) : (
                "Your deposit did not complete successfully. If you have been charged, please contact support at " +
                this.props.config.UI.supportEmail
              )
            ) : (
              "Your deposit is being processed and we are attempting to send your funds."
            )}
          </p>
        </Modal> */}
        <Card title="Deposit">
          <p>
            {/* To be able to receive your deposit, you must trust issuer: <br />
            <b style={{ wordWrap: "break-word" }}>
              {this.props.config.bridge.issuerID}
            </b>{" "}
            <br /> */}
            {/* Deposits are charged the Stripe fee of 2.9% + $0.30, and the minimum
            is $10. */}

            {/* Deposits are charged the Stripe fee of 3.6% and the minimum is 5 USD ($5) amount (more than $10 is recommended). */}
            <Translation>{(t, {i18n}) => <span>{t('depositForm.text11')}</span>}</Translation>
            <br />
            {/* We are not responsible for incorrect Account IDs. */}
            <Translation>{(t, {i18n}) => <span>{t('depositForm.text12')}</span>}</Translation>
          </p>

          <div>
            {/* XLM price: ${this.state.xlmPrice} */}
            XLM <Translation>{(t, {i18n}) => <span>{t('depositForm.text15')}</span>}</Translation>: ${this.state.xlmPrice}
            <span className="priceUpdateButton" 
              onClick={this.getXLMAmountHandler}
            >
              {/* update */}
              <Translation>{(t, {i18n}) => <span>{t('depositForm.text16')}</span>}</Translation>
            </span>
            <br/>
          </div>

          <div style={{ textAlign: "center" }}>
            <label>
              {/* Deposit Quantity */}
              <Translation>{(t, {i18n}) => <span>{t('depositForm.text8')}</span>}</Translation>
              <CurrencyInput
                prefix="$"
                className="formInput"
                style={{ base: { fontSize: "18px" } }}
                onChangeEvent={this.handleAmountChange}
                value={this.state.amount}
              />
            </label>

            <label>
              {/* Stellar Account ID */}
              Stellar <Translation>{(t, {i18n}) => <span>{t('depositForm.text9')}</span>}</Translation>
              <input
                className="formInput"
                style={{ base: { fontSize: "18px" } }}
                value={this.state.stellarAddress}
                onChange={this.handleAddressChange}
                placeholder="(for example: GBE.....TPUZ)"
              />
            </label>


            {this.state.amount && this.state.stellarAddress && 
                this.state.stellarAddress.length === 56 && 
                !this.state.invalidAddressMessage &&
                Number(this.state.amount.split('$')[1]) >= 5 
              ? 
                <div>
                  {/* Estimated XLM amount you will have: {this.state.getXlmAmount} XLM  */}
                  {/* <Translation>{(t, {i18n}) => <span>{t('depositForm.text13')}</span>}</Translation>: {this.state.getXlmAmount} XLM */}
                  {/* <span> (based on XLM price)</span> */}
                  {/* <span> (<Translation>{(t, {i18n}) => <span>{t('depositForm.text14')}</span>}</Translation>)</span> */}
                  
                </div>
            : null
            }
            
            <div>
              {this.state.invalidAddressMessage}
            </div>

            {/* <StripeCheckout
              name={this.props.config.anchorName}
              token={this.onToken}
              stripeKey={this.props.config.stripe.publishableAPIKey}
              billingAddress={true}
              amount={this.state.centAmount}
              zipCode={true}
            >
              <Button
                disabled={
                  // this.state.centAmount < 1000 ||
                  this.state.centAmount < minCentAmount ||
                  this.state.stellarAddress.length !== 56 ||
                  this.state.invalidAddressMessage
                }
              >
                Pay with Credit Card
                <Translation>{(t, {i18n}) => <span>{t('depositForm.text10')}</span>}</Translation>
              </Button>
            </StripeCheckout> */}


            <StripeCardPay 
              centAmount={this.state.centAmount}
              stellarAddress={this.state.stellarAddress}
              invalidAddressMessage={this.state.invalidAddressMessage}
              minCentAmount={minCentAmount}
              config={this.props.config}
            />

          </div>


        </Card>
      </div>
    );
  }
}

export default DepositForm;
