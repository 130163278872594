import React from 'react';

const backdropStyle = {
  width: "100%",
  height: "100%",
  position: "fixed",
  zIndex: "100",
  left: "0",
  top: "0%",
  backgroundColor: "rgba(0, 0, 0, 0.3)"
}

const HeaderBackdrop = props => (
  props.showModal ? <div style={backdropStyle} onClick={props.clicked}></div> : null
);

export default HeaderBackdrop;