export const config = {
  UI: {
    // anchorName: "Skyhitz",
    // supportEmail: "alejandro@skyhitz.io",
    anchorName: process.env.REACT_APP_ANCHORNAME,
    supportEmail: process.env.REACT_APP_SUPPORT_EMAIL
  },
  bridge: {
    //this value also exists in serverConfig, public/.well-known/stellar.toml, bridge.cfg
    // issuerID: "GCFPBUGPOQUVBR3642IKYK2SLOXQFLNY4NIKVQUY6YWBO2ZRYWR5J4UQ"
    issuerID: process.env.REACT_APP_BRIDGE_ISSUERID
  },
  stripe: {
    // publishableAPIKey: "pk_test_jiDVP0p6iRtILtE4FzFfMACc",
    publishableAPIKey: process.env.REACT_APP_STRIPE_APIKEY,
    connectClientID: process.env.REACT_APP_CONNECT_CLIENTID
  }
};
