import React, { useState } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n";
import { Translation } from "react-i18next";

import HeaderModal from "./HeaderModal.jsx";

import "../../App.css";
import classes from "./Header.module.css";

import SampleImage from "../../images/images.json";

function Header(props) {
  const { config } = props;

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [profilePictureData, setProfilePictureData] = useState("");

  const showModalHandler = () => {
    if (showModal) {
      hideModalHandler();
    } else {
      setShowModal(true);
    }
    // setShowModal(!showModal);
  };
  const hideModalHandler = () => {
    setShowModal(false);
    setShowModal2(false);
  };
  const showModal2Handler = () => {
    setShowModal2(!showModal2);
  };

  return (
    <div>
      <HeaderModal
        showModal={showModal}
        showModal2={showModal2}
        showModal2Handler={showModal2Handler}
        hideModalHandler={hideModalHandler}
        // fbUser={props.fbUser}
        // fbUserStateHandler={props.fbUserStateHandler}
        // d={props.d}
      />

      <nav className={classes.header}>
        <div className={classes.navWrapper}>
          {/* <FontAwesome name="anchor" />  */}
          <div className={classes.headerItems}>
            <span className={classes.AppTitle}>{config.UI.anchorName}</span>
            <span>
              <img
                src={SampleImage.threeLineMenu}
                className="Idbook__DisplayImage"
                style={{
                  height: "45px",
                  width: "50px",
                  cursor: "pointer",
                  marginRight: "0.5rem",
                  zIndex: "",
                }}
                onClick={showModalHandler}
                alt=""
              />
            </span>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
