import React, { useEffect, useReducer, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Translation, useTranslation } from 'react-i18next';
import { Button, Card, Modal } from "react-materialize";

import HeaderBackdrop from './Modal/HeaderBackdrop';
import DepositFormModal from "./Modal/DepositFormModal";

// import './css/global.css';
// import './css/normalize.css';
import './StripeCardPay.css';

import { backendUrl } from './App';

const fetchCheckoutSession = async ({ quantity, amount, payMethod }) => {
  return fetch(backendUrl + '/create-checkout-session', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      quantity,
      amount,
      payMethod: payMethod || 'card',
      locale: window.navigator.language
    }),
  })
    .then((res) => res.json())
    .catch(err => {
      console.log(err);
    });
};

const formatPrice = ({ amount, currency, quantity }) => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  const total = (quantity * amount).toFixed(2);
  return numberFormat.format(total);
};

function reducer(state, action) {
  switch (action.type) {
    case 'useEffectUpdate':
      return {
        ...state,
        ...action.payload,
        price: formatPrice({
          amount: action.payload.unitAmount,
          currency: action.payload.currency,
          quantity: state.quantity,
        }),
      };
    case 'increment':
      return {
        ...state,
        quantity: state.quantity + 1,
        price: formatPrice({
          amount: state.unitAmount,
          currency: state.currency,
          quantity: state.quantity + 1,
        }),
      };
    case 'decrement':
      return {
        ...state,
        quantity: state.quantity - 1,
        price: formatPrice({
          amount: state.unitAmount,
          currency: state.currency,
          quantity: state.quantity - 1,
        }),
      };
    case 'setLoading':
      return { ...state, loading: action.payload.loading };
    case 'setError':
      return { ...state, error: action.payload.error };
    default:
      throw new Error();
  }
}

const StripeCardPay = (props) => {
  // console.log('props-StripeCardPay.js', props);

  const { t, i18n } = useTranslation();

  const [state, dispatch] = useReducer(reducer, {
    quantity: 1,
    price: null,
    loading: false,
    error: null,
    stripe: null,
  });

  const [inputAmount, setInputAmount] = useState('');
  const [centValue, setCentValue] = useState('');
  const [intentIdForCapture, setIntentIdForCapture] = useState('');
  const [createdIntent, setCreatedIntent] = useState('');
  const [captureResult, setCaptureResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [depositResult, setDepositResult] = useState(undefined);

  useEffect(() => {
    async function fetchConfig() {
      // Fetch config from our backend.
      const { publicKey, unitAmount, currency } = await fetch(backendUrl + 
        '/config'
      ).then((res) => res.json());
      // Make sure to call `loadStripe` outside of a component’s render to avoid
      // recreating the `Stripe` object on every render.
      dispatch({
        type: 'useEffectUpdate',
        payload: { unitAmount, currency, stripe: await loadStripe(publicKey) },
      });
    }
    // fetchConfig();
  }, []);

  useEffect(() => {

    if (centValue >= props.minCentAmount) {
      createIntentHandler();
    }
  }, [centValue]);

  useEffect(() => {
    // setCentValue(101);
  }, []);


  // const handleClick = async (event, payMethod) => {
  //   // Call your backend to create the Checkout session.
  //   dispatch({ type: 'setLoading', payload: { loading: true } });

  //   try {
  //     const { sessionId } = await fetchCheckoutSession({
  //       quantity: state.quantity,
  //       amount: inputAmount,
  //       payMethod: payMethod
  //     });
  //     // When the customer clicks on the button, redirect them to Checkout.
  //     const { error } = await state.stripe.redirectToCheckout({
  //       sessionId,
  //     });
  //     // If `redirectToCheckout` fails due to a browser or network
  //     // error, display the localized error message to your customer
  //     // using `error.message`.
  //     if (error) {
  //       dispatch({ type: 'setError', payload: { error } });
  //       dispatch({ type: 'setLoading', payload: { loading: false } });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     dispatch({ type: 'setError', payload: { error } });
  //     dispatch({ type: 'setLoading', payload: { loading: false } });
  //   }

  // };

  const setInputAmountHandler = (input) => {
    setInputAmount(input);
    console.log(input);
  }

  const setCentValueHandler = (input) => {
    // const inputCentValue = Math.floor(Number(input*100));
    // const inputCentValue = Math.floor(parseFloat(input * 100));
    // setCentValue(inputCentValue);
    setCentValue(input);
  }

  const showModalHandler = () => {
    setShowModal(true);
  };

  const hideModalHandler = () => {
    setShowModal(false);
    window.location.reload();
  };





  ///// from placing-a-hold client script.js
  var stripe;

  var orderData = {
    items: [{ id: "xlm-deposit" }],
    currency: "usd",
    // amount: centValue,
    amount: props.centAmount,
    description: props.stellarAddress,
    stellarAddress: props.stellarAddress,
  };

  // let intentId;

  const createIntentHandler = () => {
    // console.log('in createIntentHandler');
    setIsLoading(true);

    // fetch("/create-payment-intent", {
    fetch(backendUrl + "/intent/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(orderData)
    })
      .then(function (result) {
        return result.json();
      })
      .then(function (data) {
        console.log(data);
        // intentId = data.id;
        return setupElements(data);
      })
      .then(function ({ stripe, card, clientSecret }) {
        console.log(stripe, card, clientSecret);
        setIsLoading(false);
        setDepositResult('intent-created');

        document.getElementById('card-pay-container').style.display = 'inline-block';
        // document.querySelector("#submit").style = 'inline-block';
        document.querySelector("#submit").addEventListener("click", function (evt) {
          evt.preventDefault();
          // Initiate payment
          pay(stripe, card, clientSecret);
        });

        // document.getElementById('name').style.display = 'inline-block';
      })
      .catch(err => {
        console.log(err);
      });
  }

  const intentXlmDepositHandler = () => {
    console.log('in intentXlmDepositHandler');

    return new Promise((resolve, reject) => {
      // fetch("/create-payment-intent", {
      fetch(backendUrl + "/intent/intent-xlm-deposit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(orderData)
      })
        .then(function (result) {
          if (result.status !== 200 && result.status !== 201) {
            throw new Error("xlm payment failed!");
          }
          return result.json();
        })
        .then(function (data) {
          console.log(data);
          // intentId = data.id;
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
          // setCaptureResult('Deposit failed');
          setCaptureResult(depositFailBody);
          setDepositResult('fail');
          reject(err);
        })
    })

  }


  // Set up Stripe.js and Elements to use in checkout form
  var setupElements = async function (data) {
    // stripe = Stripe(data.publicKey);
    stripe = await loadStripe(data.publicKey)
    var elements = stripe.elements();
    var style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    var card = elements.create("card", {
      style: style,
      hidePostalCode: true,
    });
    card.mount("#card-element");

    return {
      stripe,
      card,
      clientSecret: data.clientSecret
    };
  };


  var pay = function (stripe, card, clientSecret) {
    setIsLoading(true);
    document.getElementById('card-pay-container').style.display = 'none';

    var cardholderName = document.querySelector("#name").value;

    var data = {
      card: card,
      billing_details: {}
    };

    if (cardholderName) {
      data["billing_details"]["name"] = cardholderName;
    }

    changeLoadingState(true);


    // Initiate the payment.
    // If authentication is required, confirmCardPayment will display a modal
    stripe
      .confirmCardPayment(clientSecret, { payment_method: data })
      .then(function (result) {
        if (result.error) {
          changeLoadingState(false);
          // setCaptureResult('Deposit failed');
          setCaptureResult(depositFailBody);
          setDepositResult('fail');
          setIsLoading(false);

          var errorMsg = document.querySelector(".sr-field-error");
          errorMsg.textContent = result.error.message;
          setTimeout(function () {
            errorMsg.textContent = "";
          }, 4000);
        } else {
          orderComplete(clientSecret);
        }
      })
      .catch(err => {
        console.log(err);
        // setCaptureResult('Deposit failed');
        setCaptureResult(depositFailBody);
        setDepositResult('fail');
        setIsLoading(false);
      });
  };

  // Show a spinner on payment submission
  var changeLoadingState = function (isLoading) {
    if (isLoading) {
      document.querySelector("button").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      document.querySelector("button").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  };

  /* Shows a success / error message when the payment is complete */
  var orderComplete = function (clientSecret) {
    let intentId;
    stripe.retrievePaymentIntent(clientSecret)
      .then(function (result) {
        console.log(result);

        var paymentIntent = result.paymentIntent;
        var paymentIntentJson = JSON.stringify(paymentIntent, null, 2);
        console.log(paymentIntent);

        changeLoadingState(false);
        setCreatedIntent(paymentIntentJson);
        setIntentIdForCapture(result.paymentIntent.id);
        intentId = result.paymentIntent.id;

        return intentXlmDepositHandler();

        // document.querySelectorAll(".payment-view").forEach(function(view) {
        //   view.classList.add("hidden");
        // });
        // document.querySelectorAll(".completed-view").forEach(function(view) {
        //   view.classList.remove("hidden");
        // });
        // document.querySelector(".hold-status").textContent =
        //   paymentIntent.status === "requires_capture"
        //     ? "successfully placed"
        //     : "did not place";
        // document.querySelector("pre").textContent = paymentIntentJson;

        // const captureButton = document.getElementById('capture-button');
        // console.log(captureButton);
        // captureButton.addEventListener('click', function() {
        //   captureIntent(intentId);
        // });
      })
      .then(result => {
        console.log(result);
        captureIntentHandler(intentId, result.data);
      })
      .catch(err => {
        console.log(err);
        // setCaptureResult('Deposit failed');
        setCaptureResult(depositFailBody);
        setDepositResult('fail');
        setIsLoading(false);
      });
  };



  const captureIntentHandler = (intentId, txId) => {
    // intent capture test
    console.log(intentId);
    var captureOrderDataObj = {
      items: [{ id: "xlm-deposit" }],
      currency: "usd",
      id: intentId
    };

    // fetch("/capture-payment-intent", {
    fetch(backendUrl + "/intent/capture-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(captureOrderDataObj)
    })
      .then(function (result) {
        return result.json();
      })
      .then(function (data) {
        console.log(data);
        const resultData = {
          message: data.message,
          data: data.data
        }

        setCaptureResult(
          // 'Deposit success'
          <div>
            <div>
              {/* Deposit succeeded */}
              <Translation>{(t, { i18n }) => <span>{t('depositForm.text1')}</span>}</Translation>
            </div>
            <br />
            <div>
              Transaction ID:
              <a
                href={
                  "https://stellar.expert/explorer/public/tx/" + txId
                }
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                {txId}
              </a>
            </div>
            <br />
          </div>
        );
        setIsLoading(false);
        showModalHandler();
        // return setupElements(data);
      })
      .catch(err => {
        console.log(err);
        // setCaptureResult(err.message);
        // setCaptureResult('Deposit failed');
        setCaptureResult(depositFailBody);
        setDepositResult('fail');
        setIsLoading(false);
      })
  }



  // const getIntentSecret = () => {
  //   fetch('/secret').then(function(response) {
  //     console.log(response);
  //     return response.json();
  //   })
  //   .then(function(responseJson) {
  //     console.log(responseJson);
  //     var clientSecret = responseJson.client_secret;

  //     // Call stripe.confirmCardPayment() with the client secret.
  //     return stripe.confirmCardPayment(clientSecret);
  //   })
  //   .then(result => {
  //     console.log(result);
  //   })
  //   .catch(err => {
  //     console.log(err);
  //   });
  // }
  // var response = fetch('/secret').then(function(response) {
  //   return response.json();
  // }).then(function(responseJson) {
  //   var clientSecret = responseJson.client_secret;
  //   // Call stripe.confirmCardPayment() with the client secret.
  // });


  const depositFailBody = (
    <div>
      <div>
        {/* Deposit failed */}
        <Translation>{(t, {i18n}) => <span>{t('depositForm.text2')}</span>}</Translation>
      </div>
      <div>
        {/* Your deposit did not complete successfully. If you have been charged, please contact support at */}
        <Translation>{(t, {i18n}) => <span>{t('depositForm.text5')}</span>}</Translation>
        <span> {props.config.UI.supportEmail}</span>
        {/* <Translation>{(t, {i18n}) => <span>{t('general.text1')}</span>}</Translation>  */}
      </div>
      <div>
        <button onClick={() => {window.location.reload()}}>
          {/* try again */}
          <Translation>{(t, {i18n}) => <span>{t('stripeCardPay.text1')}</span>}</Translation>
        </button>
      </div>
    </div>
  );

  let cardPaymentBody;
  // if (centValue) {
  cardPaymentBody = (
    <div id="card-pay-container"
      class="sr-payment-form payment-view"
      style={{ display: "none", width: "100%" }}
    >
      <div class="sr-form-row">
        <label for="card-element">
          Payment details
        </label>
        <div class="sr-combo-inputs">
          {/* <div class="sr-combo-inputs-row">
              <input
                type="text"
                id="name"
                placeholder="Name"
                autocomplete="cardholder"
                class="sr-input"
              />
            </div> */}
          <div class="sr-combo-inputs-row">
            <div class="sr-input sr-card-element" id="card-element"></div>
          </div>
          <div class="sr-combo-inputs-row">
            <input
              type="text"
              id="name"
              // placeholder="Name on card"
              placeholder={t('stripeCardPay.text3')}
              autocomplete="cardholder"
              class="sr-input"
            // style={{display:"none"}}
            />
          </div>
        </div>
        <div class="sr-field-error" id="card-errors" role="alert"></div>
      </div>
      <Button
        id="submit"
        disabled={
          // props.centAmount < 100
          props.centAmount < props.minCentAmount ||
          props.stellarAddress.length !== 56 ||
          props.invalidAddressMessage ||
          isLoading
        }
      // style={{display: "none"}}
      >
        <div class="spinner hidden" id="spinner"></div>
          <span id="button-text">
            {/* Pay with Credit Card */}
          <Translation>{(t, {i18n}) => <span>{t('depositForm.text10')}</span>}</Translation>
          </span>
      </Button>
      
      <br />
      <br />
      <div>
        <button onClick={() => {window.location.reload()}}>
          {/* Reset Inputs */}
          <Translation>{(t, {i18n}) => <span>{t('stripeCardPay.text4')}</span>}</Translation>
        </button>
      </div>


    </div>
  );


  return (
    <div className="sr-root">
      <div className="sr-main">
        <header className="sr-header">
          <div className="sr-header__logo"></div>
        </header>

        <DepositFormModal
          showModal={showModal}
          hideModalHandler={hideModalHandler}
        >
          <div className="cardPay__depositResult">
            {captureResult}
          </div>
          <div className="cardPay__modalCloseButton">
            <button onClick={hideModalHandler}>
              {/* close */}
              <Translation>{(t, { i18n }) => <span>{t('depositForm.text7')}</span>}</Translation>
            </button>
          </div>
        </DepositFormModal>

        <section className="container">
          <div>
            <Button
              disabled={
                // props.centAmount < 100
                props.centAmount < props.minCentAmount ||
                props.stellarAddress.length !== 56 ||
                props.invalidAddressMessage ||
                isLoading ||
                depositResult === 'fail' ||
                depositResult === 'intent-created'
              }
              onClick={() => {
                // setCentValueHandler(inputAmount);
                setCentValueHandler(props.centAmount);
              }}
            >
              {/* proceed to card payment */}
              <Translation>{(t, { i18n }) => <span>{t('stripeCardPay.text2')}</span>}</Translation>
            </Button>

            {isLoading ?
              <div>

                <HeaderBackdrop
                  showModal={props.showModal}
                  showModal={true}
                // clicked={props.hideModalHandler} 
                />

                <div className='Loader'>
                  loading...
              </div>

              </div>
              : null
            }


            {cardPaymentBody}

            <div className="cardPay__depositResult">
              {captureResult}
            </div>

            {/* <button onClick={() => {window.location.reload()}}>
              Restart-demo-again
            </button> */}

            {/* <div 
              // className={classes.Loader}
              className='Loader'
            >
              loader...
            </div> */}

          </div>

        </section>
      </div>
    </div>
  );
};

export default StripeCardPay;
