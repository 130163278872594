export const forTranslations = [
  {
    "LANGUAGE": "English",
    "CODE": "en",
    "general.text1": "Hello",
    "general.text2": "Thank you",
    "general.text3": "Welcome",
    "general.text4": "Thanks",
    "general.text5": "stock exchange",
    "general.text6": "exchange",
    "general.text7": "wallet",
    "general.text8": "send",
    "general.text9": "send money",
    "general.text10": "receive",
    "general.text11": "receive money",
    "general.text12": "trading",
    "general.text13": "stock trading",
    "general.text14": "smartphone",
    "general.text15": "in your hand",
    "general.text16": "description",
    "general.text17": "date",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "market",
    "general.text21": "information",
    "general.text22": "sell",
    "general.text23": "buy",
    "general.text24": "transaction",
    "general.text25": "type",
    "general.text26": "manage",
    "general.text27": "control",
    "general.text28": "Search",
    "general.text29": "xx",
    "general.text30": "balances",
    "general.text31": "accept",
    "general.text32": "history",
    "general.text33": "transaction record",
    "general.text34": "xx",
    "general.text35": "destination",
    "general.text36": "select",
    "general.text37": "set",
    "general.text38": "asset",
    "general.text39": "input",
    "general.text40": "enter",
    "general.text41": "amount",
    "general.text42": "find path",
    "general.text43": "estimated cost",
    "general.text44": "fee",
    "general.text45": "excluding",
    "general.text46": "recommended",
    "general.text47": "show your balance",
    "general.text48": "confirm inputs",
    "general.text49": "clear inputs",
    "general.text50": "account",
    "general.text51": "bank account",
    "general.text52": "payment",
    "general.text53": "two step",
    "general.text54": "your",
    "general.text55": "xx",
    "general.text56": "terms of use",
    "general.text57": "privacy policy",
    "": "",
    "toppage.text1": "Deposit XLM to your Account ID with a credit card",
    "toppage.text2": "(If you don't have Account ID, you can generate Account ID using App at StellarSpace)",
    "toppage.text3": "Language",
    "toppage.text4": "Change",
    "__1": "",
    "depositForm.text1": "Deposit succeeded",
    "depositForm.text2": "Deposit failed",
    "depositForm.text3": "Processing deposit...",
    "depositForm.text4": "Your deposit has completed successfully with transaction ID:",
    "depositForm.text5": "Your deposit did not complete successfully. If you have been charged, please contact support at",
    "depositForm.text6": "Your deposit is being processed and we are attempting to send your funds.",
    "depositForm.text7": "close",
    "depositForm.text8": "Deposit Quantity",
    "depositForm.text9": "Account ID",
    "depositForm.text10": "Pay with Credit Card",
    "depositForm.text11": "Deposits are charged the credit card fee of 3.6% and the minimum is 5 USD ($5) amount (more than $10 is recommended).",
    "depositForm.text12": "We are not responsible for incorrect Account IDs.",
    "depositForm.text13": "Estimated XLM amount you will have",
    "depositForm.text14": "based on XLM price",
    "depositForm.text15": "price",
    "depositForm.text16": "update",
    "depositForm.text17": "invalid Account ID",
    "__2": "",
    "stripeCardPay.text1": "Try again",
    "stripeCardPay.text2": "Proceed to card payment",
    "stripeCardPay.text3": "Name on credit card",
    "stripeCardPay.text4": "reset inputs"
  },
  {
    "LANGUAGE": "Chinese",
    "CODE": "zh",
    "general.text1": "你好",
    "general.text2": "谢谢",
    "general.text3": "欢迎",
    "general.text4": "谢谢",
    "general.text5": "股票交易",
    "general.text6": "交换",
    "general.text7": "钱包",
    "general.text8": "发送",
    "general.text9": "寄钱",
    "general.text10": "接收",
    "general.text11": "收到钱",
    "general.text12": "贸易",
    "general.text13": "股票交易",
    "general.text14": "手机",
    "general.text15": "在你的手",
    "general.text16": "描述",
    "general.text17": "日期",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "市场",
    "general.text21": "信息",
    "general.text22": "卖",
    "general.text23": "购买",
    "general.text24": "交易",
    "general.text25": "类型",
    "general.text26": "管理",
    "general.text27": "控制",
    "general.text28": "搜索",
    "general.text29": "XX",
    "general.text30": "结余",
    "general.text31": "接受",
    "general.text32": "历史",
    "general.text33": "交易记录",
    "general.text34": "XX",
    "general.text35": "目的地",
    "general.text36": "选择",
    "general.text37": "组",
    "general.text38": "财富",
    "general.text39": "输入",
    "general.text40": "输入",
    "general.text41": "量",
    "general.text42": "找到路径",
    "general.text43": "估计费用",
    "general.text44": "费用",
    "general.text45": "排除",
    "general.text46": "推荐的",
    "general.text47": "显示您的余额",
    "general.text48": "确认输入",
    "general.text49": "清除输入",
    "general.text50": "帐户",
    "general.text51": "银行账户",
    "general.text52": "付款",
    "general.text53": "两步",
    "general.text54": "你的",
    "general.text55": "XX",
    "general.text56": "使用条款",
    "general.text57": "隐私政策",
    "": "",
    "toppage.text1": "存款XLM到您的帐户ID与信用卡",
    "toppage.text2": "（如果你没有账号，你可以在StellarSpace使用App生成的帐号ID）",
    "toppage.text3": "语言",
    "toppage.text4": "改变",
    "__1": "",
    "depositForm.text1": "存款成功",
    "depositForm.text2": "存款失败",
    "depositForm.text3": "处理存款......",
    "depositForm.text4": "您的存款已经与交易ID成功完成：",
    "depositForm.text5": "您的存款没有成功完成。如果你已被起诉，请联系支持在",
    "depositForm.text6": "你的存款正在处理中，我们试图把你的资金。",
    "depositForm.text7": "关闭",
    "depositForm.text8": "存款数量",
    "depositForm.text9": "帐户ID",
    "depositForm.text10": "用信用卡",
    "depositForm.text11": "存款的收费是3.6％的刷卡手续费，最低为5美元（$ 5）量（超过10 $推荐）。",
    "depositForm.text12": "我们不负责不正确的帐户ID。",
    "depositForm.text13": "估计XLM量，你将有",
    "depositForm.text14": "基于XLM价格",
    "depositForm.text15": "价格",
    "depositForm.text16": "更新",
    "depositForm.text17": "无效帐户ID",
    "__2": "",
    "stripeCardPay.text1": "再试一次",
    "stripeCardPay.text2": "前往卡支付",
    "stripeCardPay.text3": "信用卡上的名字",
    "stripeCardPay.text4": "复位输入"
  },
  {
    "LANGUAGE": "Afrikaans",
    "CODE": "af",
    "general.text1": "hallo",
    "general.text2": "Dankie",
    "general.text3": "Welkom",
    "general.text4": "Dankie",
    "general.text5": "aandelebeurs",
    "general.text6": "ruil",
    "general.text7": "beursie",
    "general.text8": "stuur",
    "general.text9": "stuur geld",
    "general.text10": "ontvang",
    "general.text11": "geld ontvang",
    "general.text12": "handel",
    "general.text13": "-beurs",
    "general.text14": "smartphone",
    "general.text15": "in jou hand",
    "general.text16": "beskrywing",
    "general.text17": "datum",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "mark",
    "general.text21": "inligting",
    "general.text22": "sell",
    "general.text23": "buy",
    "general.text24": "transaksie",
    "general.text25": "tipe",
    "general.text26": "bestuur",
    "general.text27": "beheer",
    "general.text28": "Soek",
    "general.text29": "xx",
    "general.text30": "teenwigte",
    "general.text31": "aanvaar",
    "general.text32": "geskiedenis",
    "general.text33": "transaksie rekord",
    "general.text34": "xx",
    "general.text35": "bestemming",
    "general.text36": "kies",
    "general.text37": "stel",
    "general.text38": "bate",
    "general.text39": "insette",
    "general.text40": "betree",
    "general.text41": "bedrag",
    "general.text42": "vind pad",
    "general.text43": "Beraamde koste",
    "general.text44": "fooi",
    "general.text45": "uitgesluit",
    "general.text46": "aanbeveel",
    "general.text47": "wys jou balans",
    "general.text48": "bevestig insette",
    "general.text49": "duidelik insette",
    "general.text50": "rekening",
    "general.text51": "bankrekening",
    "general.text52": "betaling",
    "general.text53": "twee stap",
    "general.text54": "jou",
    "general.text55": "xx",
    "general.text56": "terme van gebruik",
    "general.text57": "Privaatheidsbeleid",
    "": "",
    "toppage.text1": "Deposito XLM om jou rekening ID met 'n kredietkaart",
    "toppage.text2": "(As jy nie rekening ID, kan jy genereer rekening ID behulp van App by StellarSpace)",
    "toppage.text3": "Taal",
    "toppage.text4": "verandering",
    "__1": "",
    "depositForm.text1": "deposito daarin geslaag",
    "depositForm.text2": "deposito misluk",
    "depositForm.text3": "Verwerking van deposito ...",
    "depositForm.text4": "Jou deposito suksesvol voltooi het met transaksie ID:",
    "depositForm.text5": "Jou deposito nie suksesvol af te handel. As jy is aangekla, kontak ondersteuning aan",
    "depositForm.text6": "Jou deposito word tans verwerk en ons probeer om jou geld te stuur.",
    "depositForm.text7": "naby",
    "depositForm.text8": "deposito Hoeveelheid",
    "depositForm.text9": "Rekening ID",
    "depositForm.text10": "Pay met kredietkaart",
    "depositForm.text11": "Deposito gehef die kredietkaart fooi van 3,6% en die minimum is 5 dollar ($ 5) bedrag (meer as $ 10 word aanbeveel).",
    "depositForm.text12": "Ons is nie verantwoordelik vir verkeerde rekening-ID's.",
    "depositForm.text13": "Beraamde XLM bedrag wat jy sal hê",
    "depositForm.text14": "gebaseer op XLM prys",
    "depositForm.text15": "prys",
    "depositForm.text16": "Opdateer",
    "depositForm.text17": "ongeldig rekening ID",
    "__2": "",
    "stripeCardPay.text1": "Probeer weer",
    "stripeCardPay.text2": "Voort te gaan na die kaart betaling",
    "stripeCardPay.text3": "Naam op krediet kaart",
    "stripeCardPay.text4": "reset insette"
  },
  {
    "LANGUAGE": "Arabic",
    "CODE": "ar",
    "general.text1": "مرحبا",
    "general.text2": "شكرا جزيلا",
    "general.text3": "أهلا بك",
    "general.text4": "شكر",
    "general.text5": "تداول الاسهم",
    "general.text6": "تبادل",
    "general.text7": "محفظة نقود",
    "general.text8": "إرسال",
    "general.text9": "إرسال الأموال",
    "general.text10": "تسلم",
    "general.text11": "إستلم نقود",
    "general.text12": "تجارة",
    "general.text13": "تداول الأسهم",
    "general.text14": "هاتف ذكي",
    "general.text15": "في يدك",
    "general.text16": "وصف",
    "general.text17": "تاريخ",
    "general.text18": "س س",
    "general.text19": "س س",
    "general.text20": "سوق",
    "general.text21": "معلومات",
    "general.text22": "يبيع",
    "general.text23": "يشترى",
    "general.text24": "عملية تجارية",
    "general.text25": "اكتب",
    "general.text26": "يدير",
    "general.text27": "مراقبة",
    "general.text28": "بحث",
    "general.text29": "س س",
    "general.text30": "أرصدة",
    "general.text31": "قبول",
    "general.text32": "التاريخ",
    "general.text33": "سجل المعاملات",
    "general.text34": "س س",
    "general.text35": "المكان المقصود",
    "general.text36": "تحديد",
    "general.text37": "جلس",
    "general.text38": "الأصول",
    "general.text39": "إدخال",
    "general.text40": "أدخل",
    "general.text41": "كمية",
    "general.text42": "العثور على المسار",
    "general.text43": "التكلفة المتوقعة",
    "general.text44": "رسوم",
    "general.text45": "ازالة",
    "general.text46": "موصى به",
    "general.text47": "عرض رصيدك",
    "general.text48": "المدخلات تأكيد",
    "general.text49": "المدخلات واضحة",
    "general.text50": "الحساب",
    "general.text51": "حساب البنك",
    "general.text52": "دفع",
    "general.text53": "خطوتان",
    "general.text54": "ك",
    "general.text55": "س س",
    "general.text56": "شروط الاستخدام",
    "general.text57": "سياسة خاصة",
    "": "",
    "toppage.text1": "إيداع XLM لمعرف الحساب الخاص بك مع بطاقة الائتمان",
    "toppage.text2": "(إذا لم يكن لديك حساب ID، يمكنك توليد معرف الحساب استخدام التطبيقات في StellarSpace)",
    "toppage.text3": "لغة",
    "toppage.text4": "يتغيرون",
    "__1": "",
    "depositForm.text1": "نجح الودائع",
    "depositForm.text2": "فشل إيداع",
    "depositForm.text3": "تجهيز الودائع ...",
    "depositForm.text4": "أكملت الودائع الخاصة بك بنجاح مع ID الصفقة:",
    "depositForm.text5": "لم مقدمك يتم إكمال بنجاح. إذا كنت قد اتهم، يرجى الاتصال بالدعم في",
    "depositForm.text6": "يتم معالجة مقدمك ونحاول أن ترسل أموالك.",
    "depositForm.text7": "أغلق",
    "depositForm.text8": "إيداع الكمية",
    "depositForm.text9": "ID حساب",
    "depositForm.text10": "دفع مع بطاقة الائتمان",
    "depositForm.text11": "واتهم الودائع رسوم بطاقات الائتمان بنسبة 3.6٪ والحد الأدنى هو 5 دولار أمريكي ($ 5) مبلغ (أكثر من 10 $ مستحسن).",
    "depositForm.text12": "نحن لسنا مسؤولين عن معرفات الحساب غير صحيحة.",
    "depositForm.text13": "تقدر كمية XLM سيكون لديك",
    "depositForm.text14": "على أساس السعر XLM",
    "depositForm.text15": "السعر",
    "depositForm.text16": "تحديث",
    "depositForm.text17": "ID حساب غير صالح",
    "__2": "",
    "stripeCardPay.text1": "حاول مرة أخري",
    "stripeCardPay.text2": "انتقل إلى بطاقة الدفع",
    "stripeCardPay.text3": "الاسم على بطاقة الائتمان",
    "stripeCardPay.text4": "مدخلات إعادة تعيين"
  },
  {
    "LANGUAGE": "Azeri (Latin)",
    "CODE": "az",
    "general.text1": "Salam",
    "general.text2": "Çox sağ ol",
    "general.text3": "xoş",
    "general.text4": "təşəkkür",
    "general.text5": "Birja",
    "general.text6": "mübadilə",
    "general.text7": "bumajnik",
    "general.text8": "göndər",
    "general.text9": "pul göndər",
    "general.text10": "almaq",
    "general.text11": "pul almaq",
    "general.text12": "ticarət",
    "general.text13": "fond ticarət",
    "general.text14": "smartphone",
    "general.text15": "Sizin tərəfdən",
    "general.text16": "təsvir",
    "general.text17": "Tarix",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "bazar",
    "general.text21": "məlumat",
    "general.text22": "satış",
    "general.text23": "buy",
    "general.text24": "əməliyyat",
    "general.text25": "növü",
    "general.text26": "idarə",
    "general.text27": "nəzarət",
    "general.text28": "axtarış",
    "general.text29": "xx",
    "general.text30": "qalıqlar",
    "general.text31": "qəbul",
    "general.text32": "tarix",
    "general.text33": "əməliyyat rekord",
    "general.text34": "xx",
    "general.text35": "təyinat",
    "general.text36": "seçmək",
    "general.text37": "dəst",
    "general.text38": "aktiv",
    "general.text39": "input",
    "general.text40": "girmək",
    "general.text41": "miqdar",
    "general.text42": "yolunu tapmaq",
    "general.text43": "təxmini dəyəri",
    "general.text44": "haqq",
    "general.text45": "istisna olmaqla",
    "general.text46": "tövsiyə",
    "general.text47": "balans göstərmək",
    "general.text48": "təsdiq giriş",
    "general.text49": "aydın giriş",
    "general.text50": "hesab",
    "general.text51": "Bank hesabı",
    "general.text52": "ödəniş",
    "general.text53": "iki addım",
    "general.text54": "sənin",
    "general.text55": "xx",
    "general.text56": "istifadə qaydaları",
    "general.text57": "Gizlilik Siyasəti",
    "": "",
    "toppage.text1": "kredit kartı ilə haqq-hesab ID Depozit XLM",
    "toppage.text2": "(Əgər Account ID yoxsa, siz StellarSpace at App istifadə edərək Hesabım ID yarada)",
    "toppage.text3": "dil",
    "toppage.text4": "dəyişiklik",
    "__1": "",
    "depositForm.text1": "Depozit nail",
    "depositForm.text2": "Depozit uğursuz",
    "depositForm.text3": "əmanət Qenerasiya ...",
    "depositForm.text4": "Sizin əmanət əməliyyat ID uğurla tamamladı:",
    "depositForm.text5": "Sizin əmanət uğurla başa gəlmədi. Siz ittiham varsa, əlaqə dəstək at edin",
    "depositForm.text6": "Sizin əmanət işlənir və biz sizin vəsait göndərmək üçün cəhd.",
    "depositForm.text7": "yaxın",
    "depositForm.text8": "Depozit Quantity",
    "depositForm.text9": "İstifadəçi ID",
    "depositForm.text10": "Kredit kartı ilə yetirin",
    "depositForm.text11": "Depozitlər 3,6% kredit kartı ödəniş və minimum (tövsiyə daha çox $ 10) 5 USD ($ 5) məbləği.",
    "depositForm.text12": "Biz yanlış hesab kimlikleri üçün cavabdeh deyil.",
    "depositForm.text13": "Tahmini XLM məbləği olacaq",
    "depositForm.text14": "XLM qiyməti əsasında",
    "depositForm.text15": "qiymət",
    "depositForm.text16": "yeniləmə",
    "depositForm.text17": "etibarsız hesab ID",
    "__2": "",
    "stripeCardPay.text1": "Yenidən cəhd elə",
    "stripeCardPay.text2": "kartı ödəniş davam",
    "stripeCardPay.text3": "kredit kartı adı",
    "stripeCardPay.text4": "reset giriş"
  },
  {
    "LANGUAGE": "Belarusian",
    "CODE": "be",
    "general.text1": "добры дзень",
    "general.text2": "Дзякуй",
    "general.text3": "жаданы",
    "general.text4": "дзякуй",
    "general.text5": "Фондавая біржа",
    "general.text6": "абмен",
    "general.text7": "папернік",
    "general.text8": "адправіць",
    "general.text9": "пасылаць грошы",
    "general.text10": "атрымаць",
    "general.text11": "атрымліваць грошы",
    "general.text12": "гандлёвы",
    "general.text13": "гандаль акцыямі",
    "general.text14": "смартфон",
    "general.text15": "у руцэ",
    "general.text16": "апісанне",
    "general.text17": "дата",
    "general.text18": "хх",
    "general.text19": "хх",
    "general.text20": "рынак",
    "general.text21": "інфармацыя",
    "general.text22": "прадам",
    "general.text23": "купіць",
    "general.text24": "здзелка",
    "general.text25": "тып",
    "general.text26": "кіраваць",
    "general.text27": "кантроль",
    "general.text28": "пошук",
    "general.text29": "хх",
    "general.text30": "проціваг",
    "general.text31": "прымаць",
    "general.text32": "гісторыя",
    "general.text33": "запіс транзакцыі",
    "general.text34": "хх",
    "general.text35": "прызначэння",
    "general.text36": "выбраць",
    "general.text37": "набор",
    "general.text38": "актыў",
    "general.text39": "уваход",
    "general.text40": "ўваходзіць",
    "general.text41": "сума",
    "general.text42": "знайсці шлях",
    "general.text43": "арыенціровачны кошт",
    "general.text44": "плата",
    "general.text45": "выключаў",
    "general.text46": "рэкамендуемы",
    "general.text47": "паказаць свой баланс",
    "general.text48": "пацвярджэнне ўводу",
    "general.text49": "выразныя ўваходы",
    "general.text50": "кошт",
    "general.text51": "банкаўскі рахунак",
    "general.text52": "аплата",
    "general.text53": "два кроку",
    "general.text54": "ваш",
    "general.text55": "хх",
    "general.text56": "Умовы выкарыстання",
    "general.text57": "Палітыка прыватнасці",
    "": "",
    "toppage.text1": "Дэпазіт XLM на свой ID рахунку з дапамогай крэдытнай карты",
    "toppage.text2": "(Калі ў вас няма ўліковага запісу ID, вы можаце стварыць ідэнтыфікатар акаўнта з дапамогай прыкладання на StellarSpace)",
    "toppage.text3": "мова",
    "toppage.text4": "змена",
    "__1": "",
    "depositForm.text1": "дэпазіт ўдалося",
    "depositForm.text2": "Плацёж не прайшоў",
    "depositForm.text3": "Апрацоўка дэпазіт ...",
    "depositForm.text4": "Ваш дэпазіт паспяхова завершаны з ідэнтыфікатарам транзакцыі:",
    "depositForm.text5": "Ваш дэпазіт ня быў паспяхова завершаны. Калі вы былі зараджаныя, калі ласка, звярніцеся ў службу падтрымкі на",
    "depositForm.text6": "Ваш дэпазіт апрацоўваецца, і мы спрабуем накіраваць свае сродкі.",
    "depositForm.text7": "блізка",
    "depositForm.text8": "дэпазіт Колькасць",
    "depositForm.text9": "ідэнтыфікатар акаўнта",
    "depositForm.text10": "Аплата з крэдытнай картай",
    "depositForm.text11": "Дэпазіты спаганяецца збор крэдытнай карты 3,6% і мінімум 5 долараў ЗША ($ 5) колькасць (больш за $ 10 рэкамендуецца).",
    "depositForm.text12": "Мы не нясем адказнасці за няправільныя ідэнтыфікатары рахункаў.",
    "depositForm.text13": "Разліковае колькасць XLM вы будзеце мець",
    "depositForm.text14": "на аснове XLM цэны",
    "depositForm.text15": "цана",
    "depositForm.text16": "абнаўленне",
    "depositForm.text17": "недапушчальны ідэнтыфікатар акаўнта",
    "__2": "",
    "stripeCardPay.text1": "Паспрабуй яшчэ",
    "stripeCardPay.text2": "Прыступіць да аплаты карты",
    "stripeCardPay.text3": "Імя на крэдытнай карце",
    "stripeCardPay.text4": "скіду уваходаў"
  },
  {
    "LANGUAGE": "Bulgarian",
    "CODE": "bg",
    "general.text1": "Здравейте",
    "general.text2": "Благодаря ти",
    "general.text3": "Добре дошли",
    "general.text4": "Благодаря",
    "general.text5": "стокова борса",
    "general.text6": "обмен",
    "general.text7": "портфейл",
    "general.text8": "изпрати",
    "general.text9": "изпрати пари",
    "general.text10": "получавам",
    "general.text11": "Получава пари",
    "general.text12": "търгуване",
    "general.text13": "склад за търговия",
    "general.text14": "смартфон",
    "general.text15": "в ръката си",
    "general.text16": "описание",
    "general.text17": "дата",
    "general.text18": "хх",
    "general.text19": "хх",
    "general.text20": "пазар",
    "general.text21": "информация",
    "general.text22": "продажба",
    "general.text23": "Купува",
    "general.text24": "транзакция",
    "general.text25": "Тип",
    "general.text26": "управлявам",
    "general.text27": "контрол",
    "general.text28": "Търсене",
    "general.text29": "хх",
    "general.text30": "салда",
    "general.text31": "приемам",
    "general.text32": "история",
    "general.text33": "рекорд сделка",
    "general.text34": "хх",
    "general.text35": "дестинация",
    "general.text36": "изберете",
    "general.text37": "комплект",
    "general.text38": "актив",
    "general.text39": "вход",
    "general.text40": "въведете",
    "general.text41": "количество",
    "general.text42": "да се намери пътека",
    "general.text43": "очаквани разходи",
    "general.text44": "такса",
    "general.text45": "с изключение на",
    "general.text46": "препоръчва се",
    "general.text47": "покажете баланс",
    "general.text48": "потвърждават входове",
    "general.text49": "ясни входове",
    "general.text50": "сметка",
    "general.text51": "банкова сметка",
    "general.text52": "плащане",
    "general.text53": "две стъпки",
    "general.text54": "Вашият",
    "general.text55": "хх",
    "general.text56": "условия за ползване",
    "general.text57": "декларация за поверителност",
    "": "",
    "toppage.text1": "Депозит XLM да си ID акаунт с кредитна карта",
    "toppage.text2": "(Ако нямате профил ID, можете да генерирате Account ID използване App в StellarSpace)",
    "toppage.text3": "език",
    "toppage.text4": "промяна",
    "__1": "",
    "depositForm.text1": "Депозит успели",
    "depositForm.text2": "Неуспешен депозит",
    "depositForm.text3": "Обработка депозит ...",
    "depositForm.text4": "Вашият депозит е завършила успешно с идентификационен номер:",
    "depositForm.text5": "Вашият депозит не завърши успешно. Ако сте били таксувани, моля, свържете с поддръжката на",
    "depositForm.text6": "Вашият депозит се обработва и се опитваме да изпратите парите си.",
    "depositForm.text7": "близо",
    "depositForm.text8": "Депозит Количество",
    "depositForm.text9": "Идентификационен номер на профила",
    "depositForm.text10": "Плати с кредитна карта",
    "depositForm.text11": "Депозити се таксуват с такса за кредитната карта на 3,6%, а минималната е 5 USD ($ 5) количество (повече от $ 10 е препоръчително).",
    "depositForm.text12": "Ние не сме отговорни за документи за самоличност на неправилния профил.",
    "depositForm.text13": "Очаквано XLM сума ще трябва",
    "depositForm.text14": "въз основа на XLM цена",
    "depositForm.text15": "цена",
    "depositForm.text16": "актуализация",
    "depositForm.text17": "невалиден идентификационен номер на профила",
    "__2": "",
    "stripeCardPay.text1": "Опитай пак",
    "stripeCardPay.text2": "Преминете към плащане с карта",
    "stripeCardPay.text3": "Наименование на кредитна карта",
    "stripeCardPay.text4": "нулират входове"
  },
  {
    "LANGUAGE": "Catalan",
    "CODE": "ca",
    "general.text1": "Hola",
    "general.text2": "Gràcies",
    "general.text3": "Benvingut",
    "general.text4": "gràcies",
    "general.text5": "borsa",
    "general.text6": "intercanvi",
    "general.text7": "cartera",
    "general.text8": "Enviar",
    "general.text9": "enviar diners",
    "general.text10": "rebre",
    "general.text11": "rebre diners",
    "general.text12": "comerç",
    "general.text13": "el comerç d'accions",
    "general.text14": "telèfon intel·ligent",
    "general.text15": "a la mà",
    "general.text16": "descripció",
    "general.text17": "data",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "mercat",
    "general.text21": "informació",
    "general.text22": "vendre",
    "general.text23": "comprar",
    "general.text24": "transacció",
    "general.text25": "tipus",
    "general.text26": "gestionar",
    "general.text27": "control",
    "general.text28": "Cerca",
    "general.text29": "xx",
    "general.text30": "saldos",
    "general.text31": "acceptar",
    "general.text32": "història",
    "general.text33": "registre de la transacció",
    "general.text34": "xx",
    "general.text35": "destí",
    "general.text36": "seleccionar",
    "general.text37": "conjunt",
    "general.text38": "basa",
    "general.text39": "entrada",
    "general.text40": "entrar",
    "general.text41": "quantitat",
    "general.text42": "trobar camí",
    "general.text43": "Cost estimat",
    "general.text44": "quota",
    "general.text45": "Excloent",
    "general.text46": "recomanat",
    "general.text47": "mostrar el seu saldo",
    "general.text48": "Confirmar les entrades",
    "general.text49": "contribueixin de forma clara",
    "general.text50": "compte",
    "general.text51": "compte bancari",
    "general.text52": "pagament",
    "general.text53": "dos passos",
    "general.text54": "seva",
    "general.text55": "xx",
    "general.text56": "condicions d'ús",
    "general.text57": "política de privacitat",
    "": "",
    "toppage.text1": "XLM dipòsit al compte d'identificació amb una targeta de crèdit",
    "toppage.text2": "(Si no té identificador de compte, pot generar ID del compte fent servir l'aplicació en StellarSpace)",
    "toppage.text3": "Llenguatge",
    "toppage.text4": "canvi",
    "__1": "",
    "depositForm.text1": "dipòsit va tenir èxit",
    "depositForm.text2": "ingrés fallit",
    "depositForm.text3": "El processament de dipòsit ...",
    "depositForm.text4": "El seu dipòsit s'ha completat amb èxit amb ID de transacció:",
    "depositForm.text5": "El seu dipòsit no es va completar correctament. Si vostè ha estat acusat, si us plau contacte en el suport",
    "depositForm.text6": "El seu dipòsit s'està processant i estem intentant enviar els seus fons.",
    "depositForm.text7": "Tanca",
    "depositForm.text8": "dipòsit Quantitat",
    "depositForm.text9": "Identificador de compte",
    "depositForm.text10": "Pagament amb Targeta de Crèdit",
    "depositForm.text11": "Els dipòsits es paguen la tarifa de la targeta de crèdit de l'3,6% i el mínim és de 5 dòlars ($ 5) quantitat (més de $ 10 es recomana).",
    "depositForm.text12": "No som responsables dels ID de compte incorrecte.",
    "depositForm.text13": "XLM quantitat estimada que tindrà",
    "depositForm.text14": "basat en el preu XLM",
    "depositForm.text15": "preu",
    "depositForm.text16": "actualització",
    "depositForm.text17": "Identificador de compte no vàlid",
    "__2": "",
    "stripeCardPay.text1": "Intenteu-ho de nou",
    "stripeCardPay.text2": "Continueu amb el pagament amb targeta",
    "stripeCardPay.text3": "Titular de la targeta de crèdit",
    "stripeCardPay.text4": "entrades de reposició"
  },
  {
    "LANGUAGE": "Czech",
    "CODE": "cs",
    "general.text1": "Ahoj",
    "general.text2": "Děkuju",
    "general.text3": "Vítejte",
    "general.text4": "dík",
    "general.text5": "burza",
    "general.text6": "výměna",
    "general.text7": "peněženka",
    "general.text8": "poslat",
    "general.text9": "poslat peníze",
    "general.text10": "dostávat",
    "general.text11": "dostat peníze",
    "general.text12": "obchodování",
    "general.text13": "obchodování na burze",
    "general.text14": "chytrý telefon",
    "general.text15": "v ruce",
    "general.text16": "popis",
    "general.text17": "datum",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "trh",
    "general.text21": "informace",
    "general.text22": "prodat",
    "general.text23": "Koupit",
    "general.text24": "transakce",
    "general.text25": "typ",
    "general.text26": "spravovat",
    "general.text27": "řízení",
    "general.text28": "Vyhledávání",
    "general.text29": "xx",
    "general.text30": "zůstatky",
    "general.text31": "přijmout",
    "general.text32": "Dějiny",
    "general.text33": "zaznamenávat údaje o transakcích",
    "general.text34": "xx",
    "general.text35": "destinace",
    "general.text36": "vybrat",
    "general.text37": "soubor",
    "general.text38": "aktivum",
    "general.text39": "vstup",
    "general.text40": "vstoupit",
    "general.text41": "množství",
    "general.text42": "najít cestu",
    "general.text43": "odhadované náklady",
    "general.text44": "poplatek",
    "general.text45": "nepočítaje",
    "general.text46": "doporučuje",
    "general.text47": "uváděli zůstatek",
    "general.text48": "potvrzují vstupy",
    "general.text49": "jasné vstupy",
    "general.text50": "účet",
    "general.text51": "bankovní účet",
    "general.text52": "Způsob platby",
    "general.text53": "dva kroky",
    "general.text54": "tvůj",
    "general.text55": "xx",
    "general.text56": "podmínky použití",
    "general.text57": "Zásady ochrany osobních údajů",
    "": "",
    "toppage.text1": "Záloha XLM do svého ID účtu kreditní kartou",
    "toppage.text2": "(Pokud nemáte ID účtu, můžete vygenerovat ID účtu pomocí App v StellarSpace)",
    "toppage.text3": "Jazyk",
    "toppage.text4": "Změna",
    "__1": "",
    "depositForm.text1": "záloha uspěl",
    "depositForm.text2": "vklad se nezdařil",
    "depositForm.text3": "Zpracování vkladu ...",
    "depositForm.text4": "Váš vklad byl úspěšně dokončen s ID transakce:",
    "depositForm.text5": "Váš vklad nebyl úspěšně dokončen. Pokud jste byli obviněni, obraťte se na podporu na",
    "depositForm.text6": "Váš vklad se zpracovává a my se snaží posílat své finanční prostředky.",
    "depositForm.text7": "zavřít",
    "depositForm.text8": "záloha Množství",
    "depositForm.text9": "Číslo účtu",
    "depositForm.text10": "Zaplatit kreditní kartou",
    "depositForm.text11": "Vklady se vztahuje poplatek za kreditní karty ve výši 3,6% a minimum je 5 USD ($ 5), množství (více než 10 $ je doporučeno).",
    "depositForm.text12": "Nejsme zodpovědní za chybné ID účtu.",
    "depositForm.text13": "Odhadovaná částka XLM, který bude mít",
    "depositForm.text14": "založený na XLM cenu",
    "depositForm.text15": "cena",
    "depositForm.text16": "Aktualizace",
    "depositForm.text17": "neplatné číslo účtu",
    "__2": "",
    "stripeCardPay.text1": "Zkus to znovu",
    "stripeCardPay.text2": "Přistoupit k platbě kartou",
    "stripeCardPay.text3": "jméno na kreditní kartě",
    "stripeCardPay.text4": "resetu vstupy"
  },
  {
    "LANGUAGE": "Danish",
    "CODE": "da",
    "general.text1": "Hej",
    "general.text2": "tak skal du have",
    "general.text3": "Velkommen",
    "general.text4": "Tak",
    "general.text5": "børs",
    "general.text6": "udveksle",
    "general.text7": "pung",
    "general.text8": "sende",
    "general.text9": "Send penge",
    "general.text10": "modtage",
    "general.text11": "modtage penge",
    "general.text12": "handel",
    "general.text13": "bestand handel",
    "general.text14": "smartphone",
    "general.text15": "i hånden",
    "general.text16": "beskrivelse",
    "general.text17": "dato",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "marked",
    "general.text21": "Information",
    "general.text22": "sælge",
    "general.text23": "købe",
    "general.text24": "transaktion",
    "general.text25": "type",
    "general.text26": "styre",
    "general.text27": "styring",
    "general.text28": "Søg",
    "general.text29": "xx",
    "general.text30": "saldi",
    "general.text31": "acceptere",
    "general.text32": "historie",
    "general.text33": "transaktion rekord",
    "general.text34": "xx",
    "general.text35": "bestemmelsessted",
    "general.text36": "Vælg",
    "general.text37": "sæt",
    "general.text38": "aktiv",
    "general.text39": "input",
    "general.text40": "gå ind",
    "general.text41": "beløb",
    "general.text42": "finde vej",
    "general.text43": "anslåede omkostninger",
    "general.text44": "betaling",
    "general.text45": "Eksklusive",
    "general.text46": "anbefalede",
    "general.text47": "vise din saldo",
    "general.text48": "Bekræft indgange",
    "general.text49": "klare indgange",
    "general.text50": "konto",
    "general.text51": "bankkonto",
    "general.text52": "betaling",
    "general.text53": "to trin",
    "general.text54": "jeres",
    "general.text55": "xx",
    "general.text56": "betingelser for brug",
    "general.text57": "Fortrolighedspolitik",
    "": "",
    "toppage.text1": "Depositum XLM til din konto-id med et kreditkort",
    "toppage.text2": "(Hvis du ikke har konto-id, kan du generere konto-id ved hjælp af App på StellarSpace)",
    "toppage.text3": "Sprog",
    "toppage.text4": "Lave om",
    "__1": "",
    "depositForm.text1": "depositum lykkedes",
    "depositForm.text2": "Indbetaling mislykkedes",
    "depositForm.text3": "Behandler depositum ...",
    "depositForm.text4": "Din indbetaling er gennemført med succes med transaktions-ID:",
    "depositForm.text5": "Din indbetaling blev ikke fuldført. Hvis du er blevet debiteret, bedes du kontakte support på",
    "depositForm.text6": "Din indbetaling er under behandling, og vi forsøger at sende dine midler.",
    "depositForm.text7": "tæt",
    "depositForm.text8": "depositum Mængde",
    "depositForm.text9": "Konto-id",
    "depositForm.text10": "Betal med kreditkort",
    "depositForm.text11": "Deposita bliver opkrævet gebyr kreditkort på 3,6% og den mindste er 5 USD ($ 5) beløb (mere end $ 10 anbefales).",
    "depositForm.text12": "Vi er ikke ansvarlige for ukorrekte id'er.",
    "depositForm.text13": "Anslået XLM beløb, du vil have",
    "depositForm.text14": "baseret på XLM pris",
    "depositForm.text15": "pris",
    "depositForm.text16": "opdatering",
    "depositForm.text17": "ugyldigt konto-id",
    "__2": "",
    "stripeCardPay.text1": "Prøv igen",
    "stripeCardPay.text2": "Fortsæt til betaling",
    "stripeCardPay.text3": "Navn på kreditkort",
    "stripeCardPay.text4": "resetindgangene"
  },
  {
    "LANGUAGE": "German",
    "CODE": "de",
    "general.text1": "Hallo",
    "general.text2": "Danke dir",
    "general.text3": "Herzlich willkommen",
    "general.text4": "Vielen Dank",
    "general.text5": "Börse",
    "general.text6": "Austausch",
    "general.text7": "Brieftasche",
    "general.text8": "senden",
    "general.text9": "sende Geld",
    "general.text10": "erhalten",
    "general.text11": "Geld erhalten",
    "general.text12": "Handel",
    "general.text13": "Aktienhandel",
    "general.text14": "Smartphone",
    "general.text15": "in deiner Hand",
    "general.text16": "Beschreibung",
    "general.text17": "Datum",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "Markt",
    "general.text21": "Information",
    "general.text22": "verkaufen",
    "general.text23": "Kaufen",
    "general.text24": "Transaktion",
    "general.text25": "Art",
    "general.text26": "verwalten",
    "general.text27": "Steuerung",
    "general.text28": "Suche",
    "general.text29": "xx",
    "general.text30": "Salden",
    "general.text31": "akzeptieren",
    "general.text32": "Geschichte",
    "general.text33": "Transaktionen Aufzeichnung",
    "general.text34": "xx",
    "general.text35": "Ziel",
    "general.text36": "wählen",
    "general.text37": "einstellen",
    "general.text38": "Anlagegut",
    "general.text39": "Eingang",
    "general.text40": "eingeben",
    "general.text41": "Menge",
    "general.text42": "finden Pfad",
    "general.text43": "geschätzte Kosten",
    "general.text44": "Gebühr",
    "general.text45": "ohne",
    "general.text46": "empfohlen",
    "general.text47": "zeigen Sie Ihre Balance",
    "general.text48": "Eingaben bestätigen",
    "general.text49": "Löscheingänge",
    "general.text50": "Konto",
    "general.text51": "Bankkonto",
    "general.text52": "Zahlung",
    "general.text53": "zwei Schritte",
    "general.text54": "Ihre",
    "general.text55": "xx",
    "general.text56": "Nutzungsbedingungen",
    "general.text57": "Datenschutz-Bestimmungen",
    "": "",
    "toppage.text1": "Kaution XLM zu Ihrem Konto-ID mit einer Kreditkarte",
    "toppage.text2": "(Wenn Sie nicht Konto-ID haben, können Sie Konto-ID mit App bei StellarSpace erzeugen)",
    "toppage.text3": "Sprache",
    "toppage.text4": "Veränderung",
    "__1": "",
    "depositForm.text1": "Kaution gelungen",
    "depositForm.text2": "Weisung fehlgeschlagen",
    "depositForm.text3": "Die Verarbeitung Ablagerung ...",
    "depositForm.text4": "Ihre Einzahlung erfolgreich mit Transaktions-ID abgeschlossen:",
    "depositForm.text5": "Ihre Kaution wurde nicht erfolgreich abgeschlossen. Wenn Sie geladen haben, kontaktieren Sie bitte Unterstützung bei",
    "depositForm.text6": "Ihre Einzahlung wird bearbeitet, und wir versuchen, Ihr Geld zu senden.",
    "depositForm.text7": "schließen",
    "depositForm.text8": "Kaution Menge",
    "depositForm.text9": "Konto-ID",
    "depositForm.text10": "Mit Kreditkarte bezahlen",
    "depositForm.text11": "Einlagen werden die Kreditkartengebühr von 3,6% erhoben und das Minimum beträgt 5 USD ($ 5) Menge (mehr als $ 10 wird empfohlen).",
    "depositForm.text12": "Wir sind nicht für falsches Konto-IDs verantwortlich.",
    "depositForm.text13": "Geschätzte XLM Betrag, den Sie haben",
    "depositForm.text14": "basierend auf XLM Preis",
    "depositForm.text15": "Preis",
    "depositForm.text16": "aktualisieren",
    "depositForm.text17": "ungültige Kontokennung",
    "__2": "",
    "stripeCardPay.text1": "Versuchen Sie es nochmal",
    "stripeCardPay.text2": "Fahren Sie mit Kartenzahlung",
    "stripeCardPay.text3": "Name auf der Kreditkarte",
    "stripeCardPay.text4": "Rücksetzeingänge"
  },
  {
    "LANGUAGE": "Divehi",
    "CODE": "dv",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "general.text24": "#VALUE!",
    "general.text25": "#VALUE!",
    "general.text26": "#VALUE!",
    "general.text27": "#VALUE!",
    "general.text28": "#VALUE!",
    "general.text29": "#VALUE!",
    "general.text30": "#VALUE!",
    "general.text31": "#VALUE!",
    "general.text32": "#VALUE!",
    "general.text33": "#VALUE!",
    "general.text34": "#VALUE!",
    "general.text35": "#VALUE!",
    "general.text36": "#VALUE!",
    "general.text37": "#VALUE!",
    "general.text38": "#VALUE!",
    "general.text39": "#VALUE!",
    "general.text40": "#VALUE!",
    "general.text41": "#VALUE!",
    "general.text42": "#VALUE!",
    "general.text43": "#VALUE!",
    "general.text44": "#VALUE!",
    "general.text45": "#VALUE!",
    "general.text46": "#VALUE!",
    "general.text47": "#VALUE!",
    "general.text48": "#VALUE!",
    "general.text49": "#VALUE!",
    "general.text50": "#VALUE!",
    "general.text51": "#VALUE!",
    "general.text52": "#VALUE!",
    "general.text53": "#VALUE!",
    "general.text54": "#VALUE!",
    "general.text55": "#VALUE!",
    "general.text56": "#VALUE!",
    "general.text57": "#VALUE!",
    "": "",
    "toppage.text1": "#VALUE!",
    "toppage.text2": "#VALUE!",
    "toppage.text3": "#VALUE!",
    "toppage.text4": "#VALUE!",
    "__1": "",
    "depositForm.text1": "#VALUE!",
    "depositForm.text2": "#VALUE!",
    "depositForm.text3": "#VALUE!",
    "depositForm.text4": "#VALUE!",
    "depositForm.text5": "#VALUE!",
    "depositForm.text6": "#VALUE!",
    "depositForm.text7": "#VALUE!",
    "depositForm.text8": "#VALUE!",
    "depositForm.text9": "#VALUE!",
    "depositForm.text10": "#VALUE!",
    "depositForm.text11": "#VALUE!",
    "depositForm.text12": "#VALUE!",
    "depositForm.text13": "#VALUE!",
    "depositForm.text14": "#VALUE!",
    "depositForm.text15": "#VALUE!",
    "depositForm.text16": "#VALUE!",
    "depositForm.text17": "#VALUE!",
    "__2": "",
    "stripeCardPay.text1": "#VALUE!",
    "stripeCardPay.text2": "#VALUE!",
    "stripeCardPay.text3": "#VALUE!",
    "stripeCardPay.text4": "#VALUE!"
  },
  {
    "LANGUAGE": "Greek",
    "CODE": "el",
    "general.text1": "Χαίρετε",
    "general.text2": "Ευχαριστώ",
    "general.text3": "καλως ΗΡΘΑΤΕ",
    "general.text4": "Ευχαριστώ",
    "general.text5": "χρηματιστήριο",
    "general.text6": "ανταλλαγή",
    "general.text7": "πορτοφόλι",
    "general.text8": "στείλετε",
    "general.text9": "Στείλε χρήματα",
    "general.text10": "λαμβάνω",
    "general.text11": "ΔΕΧΟΜΑΙ χρηματα",
    "general.text12": "εμπορία",
    "general.text13": "συναλλαγές μετοχών",
    "general.text14": "smartphone",
    "general.text15": "στο χέρι σου",
    "general.text16": "περιγραφή",
    "general.text17": "ημερομηνία",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "αγορά",
    "general.text21": "πληροφορίες",
    "general.text22": "Πουλώ",
    "general.text23": "αγορά",
    "general.text24": "συναλλαγή",
    "general.text25": "τύπος",
    "general.text26": "διαχειρίζονται",
    "general.text27": "έλεγχος",
    "general.text28": "Αναζήτηση",
    "general.text29": "xx",
    "general.text30": "υπολοίπων",
    "general.text31": "αποδέχομαι",
    "general.text32": "ιστορία",
    "general.text33": "ρεκόρ συναλλαγών",
    "general.text34": "xx",
    "general.text35": "προορισμός",
    "general.text36": "επιλέγω",
    "general.text37": "σειρά",
    "general.text38": "περιουσιακό στοιχείο",
    "general.text39": "εισαγωγή",
    "general.text40": "εισαγω",
    "general.text41": "ποσό",
    "general.text42": "βρείτε μονοπάτι",
    "general.text43": "εκτιμώμενο κόστος",
    "general.text44": "τέλη",
    "general.text45": "Χωρίς",
    "general.text46": "συνιστάται",
    "general.text47": "δείχνουν την ισορροπία σας",
    "general.text48": "εισόδους επιβεβαίωση",
    "general.text49": "σαφές εισόδους",
    "general.text50": "λογαριασμός",
    "general.text51": "τραπεζικός λογαριασμός",
    "general.text52": "πληρωμή",
    "general.text53": "δύο βήματα",
    "general.text54": "τα δικα σου",
    "general.text55": "xx",
    "general.text56": "όροι χρήσης",
    "general.text57": "πολιτική απορρήτου",
    "": "",
    "toppage.text1": "Κατάθεση ΧΛΜ με το αναγνωριστικό του λογαριασμού σας με πιστωτική κάρτα",
    "toppage.text2": "(Αν δεν έχετε Λογαριασμό ID, μπορείτε να δημιουργήσετε Λογαριασμό ID χρήση εφαρμογών σε StellarSpace)",
    "toppage.text3": "Γλώσσα",
    "toppage.text4": "Αλλαγή",
    "__1": "",
    "depositForm.text1": "Κατάθεση πέτυχε",
    "depositForm.text2": "Η κατάθεση απέτυχε",
    "depositForm.text3": "Επεξεργασία κατάθεση ...",
    "depositForm.text4": "Η κατάθεσή σας έχει ολοκληρωθεί επιτυχώς με το αναγνωριστικό συναλλαγής:",
    "depositForm.text5": "Η κατάθεσή σας δεν ολοκληρώθηκε με επιτυχία. Εάν σας έχει χρεωθεί, παρακαλώ επικοινωνήστε με την υποστήριξη σε",
    "depositForm.text6": "Η κατάθεσή σας είναι υπό επεξεργασία και προσπαθούμε να στείλετε τα χρήματά σας.",
    "depositForm.text7": "Κλείσε",
    "depositForm.text8": "Κατάθεση Ποσότητα",
    "depositForm.text9": "Αναγνωριστικό λογαριασμού",
    "depositForm.text10": "Πληρωμή με πιστωτική κάρτα",
    "depositForm.text11": "Οι καταθέσεις επιβαρύνονται με το τέλος της πιστωτικής κάρτας του 3,6% και το ελάχιστο είναι 5 δολάρια ($ 5) ποσό (πάνω από $ 10 συνιστάται).",
    "depositForm.text12": "Δεν είμαστε υπεύθυνοι για λανθασμένες ταυτότητες λογαριασμού.",
    "depositForm.text13": "Εκτιμώμενο ποσό ΧΛΜ θα έχετε",
    "depositForm.text14": "με βάση την ΧΛΜ τιμή",
    "depositForm.text15": "τιμή",
    "depositForm.text16": "εκσυγχρονίζω",
    "depositForm.text17": "Μη έγκυρο αναγνωριστικό λογαριασμού",
    "__2": "",
    "stripeCardPay.text1": "Προσπάθησε ξανά",
    "stripeCardPay.text2": "Προχωρήστε σε πληρωμή με κάρτα",
    "stripeCardPay.text3": "όνομα στην πιστωτική κάρτα",
    "stripeCardPay.text4": "επαναφορά εισόδους"
  },
  {
    "LANGUAGE": "English",
    "CODE": "en",
    "general.text1": "Hello",
    "general.text2": "Thank you",
    "general.text3": "Welcome",
    "general.text4": "Thanks",
    "general.text5": "stock exchange",
    "general.text6": "exchange",
    "general.text7": "wallet",
    "general.text8": "send",
    "general.text9": "send money",
    "general.text10": "receive",
    "general.text11": "receive money",
    "general.text12": "trading",
    "general.text13": "stock trading",
    "general.text14": "smartphone",
    "general.text15": "in your hand",
    "general.text16": "description",
    "general.text17": "date",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "market",
    "general.text21": "information",
    "general.text22": "sell",
    "general.text23": "buy",
    "general.text24": "transaction",
    "general.text25": "type",
    "general.text26": "manage",
    "general.text27": "control",
    "general.text28": "Search",
    "general.text29": "xx",
    "general.text30": "balances",
    "general.text31": "accept",
    "general.text32": "history",
    "general.text33": "transaction record",
    "general.text34": "xx",
    "general.text35": "destination",
    "general.text36": "select",
    "general.text37": "set",
    "general.text38": "asset",
    "general.text39": "input",
    "general.text40": "enter",
    "general.text41": "amount",
    "general.text42": "find path",
    "general.text43": "estimated cost",
    "general.text44": "fee",
    "general.text45": "excluding",
    "general.text46": "recommended",
    "general.text47": "show your balance",
    "general.text48": "confirm inputs",
    "general.text49": "clear inputs",
    "general.text50": "account",
    "general.text51": "bank account",
    "general.text52": "payment",
    "general.text53": "two step",
    "general.text54": "your",
    "general.text55": "xx",
    "general.text56": "terms of use",
    "general.text57": "privacy policy",
    "": "",
    "toppage.text1": "Deposit XLM to your Account ID with a credit card",
    "toppage.text2": "(If you don't have Account ID, you can generate Account ID using App at StellarSpace)",
    "toppage.text3": "Language",
    "toppage.text4": "Change",
    "__1": "",
    "depositForm.text1": "Deposit succeeded",
    "depositForm.text2": "Deposit failed",
    "depositForm.text3": "Processing deposit...",
    "depositForm.text4": "Your deposit has completed successfully with transaction ID:",
    "depositForm.text5": "Your deposit did not complete successfully. If you have been charged, please contact support at",
    "depositForm.text6": "Your deposit is being processed and we are attempting to send your funds.",
    "depositForm.text7": "close",
    "depositForm.text8": "Deposit Quantity",
    "depositForm.text9": "Account ID",
    "depositForm.text10": "Pay with Credit Card",
    "depositForm.text11": "Deposits are charged the credit card fee of 3.6% and the minimum is 5 USD ($5) amount (more than $10 is recommended).",
    "depositForm.text12": "We are not responsible for incorrect Account IDs.",
    "depositForm.text13": "Estimated XLM amount you will have",
    "depositForm.text14": "based on XLM price",
    "depositForm.text15": "price",
    "depositForm.text16": "update",
    "depositForm.text17": "invalid Account ID",
    "__2": "",
    "stripeCardPay.text1": "Try again",
    "stripeCardPay.text2": "Proceed to card payment",
    "stripeCardPay.text3": "Name on credit card",
    "stripeCardPay.text4": "reset inputs"
  },
  {
    "LANGUAGE": "Esperanto",
    "CODE": "eo",
    "general.text1": "Saluton",
    "general.text2": "Dankon",
    "general.text3": "Bonvenon",
    "general.text4": "Dankon",
    "general.text5": "borso",
    "general.text6": "interŝanĝo",
    "general.text7": "monujo",
    "general.text8": "alsendota",
    "general.text9": "alsendota monon",
    "general.text10": "ricevi",
    "general.text11": "ricevi monon",
    "general.text12": "komercanta",
    "general.text13": "stock komerco",
    "general.text14": "smartphone",
    "general.text15": "en via mano",
    "general.text16": "Priskribo",
    "general.text17": "dato",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "merkato",
    "general.text21": "informo",
    "general.text22": "sell",
    "general.text23": "Aĉeti",
    "general.text24": "transakcio",
    "general.text25": "tipo",
    "general.text26": "administri",
    "general.text27": "kontrolo",
    "general.text28": "serĉi",
    "general.text29": "xx",
    "general.text30": "ekvilibroj",
    "general.text31": "akcepti",
    "general.text32": "historio",
    "general.text33": "transakcioj rekordon",
    "general.text34": "xx",
    "general.text35": "celon",
    "general.text36": "elektu",
    "general.text37": "aro",
    "general.text38": "valoraĵo",
    "general.text39": "enigo",
    "general.text40": "eniras",
    "general.text41": "kvanto",
    "general.text42": "trovi padon",
    "general.text43": "taksis koston",
    "general.text44": "kotizon",
    "general.text45": "ekskludante",
    "general.text46": "rekomendita",
    "general.text47": "montri vian ekvilibron",
    "general.text48": "confirm enigoj",
    "general.text49": "klara enigoj",
    "general.text50": "konto",
    "general.text51": "bankokonto",
    "general.text52": "pago",
    "general.text53": "du paŝo",
    "general.text54": "vian",
    "general.text55": "xx",
    "general.text56": "Kondiĉoj de Uzado",
    "general.text57": "privateca politiko",
    "": "",
    "toppage.text1": "Deponejo XLM al via konto ID per kreditkarto",
    "toppage.text2": "(Se vi ne havas Konto ID, vi povas generi Konto ID uzante App ĉe StellarSpace)",
    "toppage.text3": "Lingvo",
    "toppage.text4": "ŝanĝo",
    "__1": "",
    "depositForm.text1": "deponejo sukcesis",
    "depositForm.text2": "deponejo malsukcesis",
    "depositForm.text3": "Procesante deponejo ...",
    "depositForm.text4": "Via deponejo kompletigis sukcese kun transakcioj ID:",
    "depositForm.text5": "Via deponejo ne kompletigis sukcese. Se vi estis ŝargita, bonvolu kontakti subtenon ĉe",
    "depositForm.text6": "Via deponejo estas procesitaj kaj ni sendos viajn financojn.",
    "depositForm.text7": "proksimaj",
    "depositForm.text8": "deponejo Kvanto",
    "depositForm.text9": "Konto ID",
    "depositForm.text10": "Pagu per kreditkarto",
    "depositForm.text11": "Arras estas ŝargita la kreditkarto kotizon de la 3,6% kaj la minimuma estas 5 USD ($ 5) kvanto (pli ol $ 10 rekomendas).",
    "depositForm.text12": "Ni ne estas respondecaj de malĝusta Konto IDs.",
    "depositForm.text13": "Takso XLM kvanto vi devas",
    "depositForm.text14": "surbaze XLM prezo",
    "depositForm.text15": "prezo",
    "depositForm.text16": "ĝisdatigo",
    "depositForm.text17": "nevalida Konto ID",
    "__2": "",
    "stripeCardPay.text1": "Provu denove",
    "stripeCardPay.text2": "Procedi al karto pago",
    "stripeCardPay.text3": "Nomo sur kreditkarto",
    "stripeCardPay.text4": "reset enigoj"
  },
  {
    "LANGUAGE": "Spanish",
    "CODE": "es",
    "general.text1": "Hola",
    "general.text2": "Gracias",
    "general.text3": "Bienvenidos",
    "general.text4": "Gracias",
    "general.text5": "bolsa",
    "general.text6": "intercambiar",
    "general.text7": "billetera",
    "general.text8": "enviar",
    "general.text9": "enviar dinero",
    "general.text10": "recibir",
    "general.text11": "recibir dinero",
    "general.text12": "comercio",
    "general.text13": "el comercio de acciones",
    "general.text14": "teléfono inteligente",
    "general.text15": "en tu mano",
    "general.text16": "descripción",
    "general.text17": "fecha",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "mercado",
    "general.text21": "información",
    "general.text22": "vender",
    "general.text23": "comprar",
    "general.text24": "transacción",
    "general.text25": "tipo",
    "general.text26": "gestionar",
    "general.text27": "controlar",
    "general.text28": "Buscar",
    "general.text29": "xx",
    "general.text30": "saldos",
    "general.text31": "aceptar",
    "general.text32": "historia",
    "general.text33": "registro de la transacción",
    "general.text34": "xx",
    "general.text35": "destino",
    "general.text36": "Seleccione",
    "general.text37": "conjunto",
    "general.text38": "activo",
    "general.text39": "entrada",
    "general.text40": "entrar",
    "general.text41": "cantidad",
    "general.text42": "encontrar camino",
    "general.text43": "costo estimado",
    "general.text44": "cuota",
    "general.text45": "Excluyendo",
    "general.text46": "recomendado",
    "general.text47": "mostrar su saldo",
    "general.text48": "Confirmar las entradas",
    "general.text49": "contribuyan de forma clara",
    "general.text50": "cuenta",
    "general.text51": "cuenta bancaria",
    "general.text52": "pago",
    "general.text53": "dos pasos",
    "general.text54": "tu",
    "general.text55": "xx",
    "general.text56": "términos de Uso",
    "general.text57": "política de privacidad",
    "": "",
    "toppage.text1": "XLM depósito a la cuenta de identificación con una tarjeta de crédito",
    "toppage.text2": "(Si usted no tiene ID de cuenta, puede generar ID de la cuenta usando la aplicación en StellarSpace)",
    "toppage.text3": "Idioma",
    "toppage.text4": "Cambio",
    "__1": "",
    "depositForm.text1": "depósito tuvo éxito",
    "depositForm.text2": "Ingreso fallido",
    "depositForm.text3": "El procesamiento de depósito ...",
    "depositForm.text4": "Su depósito se ha completado con éxito con ID de transacción:",
    "depositForm.text5": "Su depósito no se completó correctamente. Si usted ha sido acusado, por favor contacto en el apoyo",
    "depositForm.text6": "Su depósito se está procesando y estamos intentando enviar sus fondos.",
    "depositForm.text7": "cerrar",
    "depositForm.text8": "depósito Cantidad",
    "depositForm.text9": "ID de la cuenta",
    "depositForm.text10": "Paga con tarjeta de crédito",
    "depositForm.text11": "Los depósitos se pagan la tarifa de la tarjeta de crédito del 3,6% y el mínimo es de 5 dólares ($ 5) cantidad (más de $ 10 se recomienda).",
    "depositForm.text12": "No somos responsables de los ID de cuenta incorrecto.",
    "depositForm.text13": "XLM cantidad estimada que tendrá",
    "depositForm.text14": "basado en el precio XLM",
    "depositForm.text15": "precio",
    "depositForm.text16": "actualizar",
    "depositForm.text17": "ID de cuenta no válido",
    "__2": "",
    "stripeCardPay.text1": "Intentar otra vez",
    "stripeCardPay.text2": "Continúe con el pago con tarjeta",
    "stripeCardPay.text3": "Titular de la tarjeta de crédito",
    "stripeCardPay.text4": "entradas de reposición"
  },
  {
    "LANGUAGE": "Estonian",
    "CODE": "et",
    "general.text1": "Tere",
    "general.text2": "Aitäh",
    "general.text3": "Tere tulemast",
    "general.text4": "aitäh",
    "general.text5": "Börs",
    "general.text6": "vahetus",
    "general.text7": "rahakott",
    "general.text8": "saada",
    "general.text9": "saada raha",
    "general.text10": "saama",
    "general.text11": "saada raha",
    "general.text12": "kauplemine",
    "general.text13": "aktsia kauplemise",
    "general.text14": "nutikas telefon",
    "general.text15": "käes",
    "general.text16": "kirjeldus",
    "general.text17": "kuupäev",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "turg",
    "general.text21": "teave",
    "general.text22": "müüa",
    "general.text23": "Osta",
    "general.text24": "tehing",
    "general.text25": "tüüp",
    "general.text26": "juhtima",
    "general.text27": "kontroll",
    "general.text28": "Otsing",
    "general.text29": "xx",
    "general.text30": "saldod",
    "general.text31": "aktsepteerima",
    "general.text32": "ajalugu",
    "general.text33": "tehingu rekord",
    "general.text34": "xx",
    "general.text35": "sihtkoht",
    "general.text36": "valima",
    "general.text37": "komplekt",
    "general.text38": "eelis",
    "general.text39": "sisend",
    "general.text40": "sisenema",
    "general.text41": "summa",
    "general.text42": "leida tee",
    "general.text43": "hinnanguline maksumus",
    "general.text44": "tasu",
    "general.text45": "va",
    "general.text46": "soovitatav",
    "general.text47": "näidata oma tasakaalu",
    "general.text48": "Kinnita sisendite",
    "general.text49": "selge sisendite",
    "general.text50": "konto",
    "general.text51": "pangakonto",
    "general.text52": "makse",
    "general.text53": "kaheastmelise",
    "general.text54": "oma",
    "general.text55": "xx",
    "general.text56": "kasutustingimused",
    "general.text57": "privaatsuspoliitika",
    "": "",
    "toppage.text1": "Ettemaks XLM oma konto ID krediitkaardiga",
    "toppage.text2": "(Kui te ei ole konto ID, saate luua konto ID abil avaldaja StellarSpace)",
    "toppage.text3": "keel",
    "toppage.text4": "muutus",
    "__1": "",
    "depositForm.text1": "Ettemaks õnnestus",
    "depositForm.text2": "Makse ebaõnnestus",
    "depositForm.text3": "Töötlemine tagatisraha ...",
    "depositForm.text4": "Teie hoius on lõpetanud edukalt tehingu ID:",
    "depositForm.text5": "Teie hoius ei õnnestunud. Kui teil on laetud, võtke toetust",
    "depositForm.text6": "Teie hoius on töödeldud ja püüame saata oma raha.",
    "depositForm.text7": "Sulge",
    "depositForm.text8": "Ettemaks Kogus",
    "depositForm.text9": "Konto ID",
    "depositForm.text10": "Maksate krediitkaardiga",
    "depositForm.text11": "Hoiused tasuda krediitkaardi tasu 3,6% ja minimaalne on 5 USD ($ 5) kogus (rohkem kui $ 10 on soovitatav).",
    "depositForm.text12": "Me ei vastuta vale konto ID.",
    "depositForm.text13": "Eeldatav XLM summa, mida on",
    "depositForm.text14": "põhineb XML hind",
    "depositForm.text15": "hind",
    "depositForm.text16": "ajakohastama",
    "depositForm.text17": "Vale konto ID",
    "__2": "",
    "stripeCardPay.text1": "Proovi uuesti",
    "stripeCardPay.text2": "Jätkake kaardimakse",
    "stripeCardPay.text3": "Krediitkaardil olev nimi",
    "stripeCardPay.text4": "reset sisendite"
  },
  {
    "LANGUAGE": "Basque",
    "CODE": "eu",
    "general.text1": "Kaixo",
    "general.text2": "Eskerrik asko",
    "general.text3": "Ongi",
    "general.text4": "eskerrik asko",
    "general.text5": "Burtsa",
    "general.text6": "truke",
    "general.text7": "zorro",
    "general.text8": "bidali",
    "general.text9": "bidali dirua",
    "general.text10": "jaso",
    "general.text11": "dirua jaso",
    "general.text12": "merkataritza",
    "general.text13": "stock merkataritza",
    "general.text14": "smartphone",
    "general.text15": "eskua",
    "general.text16": "azalpena",
    "general.text17": "data",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "merkatua",
    "general.text21": "informazio",
    "general.text22": "saldu",
    "general.text23": "Erosi",
    "general.text24": "transakzio",
    "general.text25": "mota",
    "general.text26": "kudeatzeko",
    "general.text27": "kontrol",
    "general.text28": "Search",
    "general.text29": "xx",
    "general.text30": "saldoak",
    "general.text31": "onartu",
    "general.text32": "historia",
    "general.text33": "transakzio erregistroa",
    "general.text34": "xx",
    "general.text35": "helmuga",
    "general.text36": "hautatu",
    "general.text37": "multzo",
    "general.text38": "aktibo",
    "general.text39": "Sarrera-",
    "general.text40": "sartzen",
    "general.text41": "Zenbateko",
    "general.text42": "bidea aurkitu",
    "general.text43": "estimatu kostua",
    "general.text44": "kuota",
    "general.text45": "salbuetsiz",
    "general.text46": "gomendagarria",
    "general.text47": "zure oreka erakutsi",
    "general.text48": "berretsi sarrera",
    "general.text49": "argi sarrera",
    "general.text50": "kontu",
    "general.text51": "banku-kontu",
    "general.text52": "ordainketa",
    "general.text53": "bi urrats",
    "general.text54": "zure",
    "general.text55": "xx",
    "general.text56": "erabilera baldintzak",
    "general.text57": "pribatutasun politika",
    "": "",
    "toppage.text1": "Gordailua XLM zure kontua NAN kreditu txartel batekin",
    "toppage.text2": "(Ez baduzu dute kontua NAN, kontua NAN sortzeko dezakezu aplikazioa erabilita StellarSpace at)",
    "toppage.text3": "Hizkuntza",
    "toppage.text4": "Aldaketa",
    "__1": "",
    "depositForm.text1": "Gordailua lortu",
    "depositForm.text2": "Gordailua huts",
    "depositForm.text3": "gordailua prozesatzen ...",
    "depositForm.text4": "Zure gordailua arrakastaz bukatu du transakzio IDarekin:",
    "depositForm.text5": "Zure gordailua ez da guztiz arrakastasua. dute kobratuko dira bada, jarri harremanetan laguntza",
    "depositForm.text6": "Zure gordailua prozesatzen ari da, eta zure fondoen bidaltzen saiatzen ari gara.",
    "depositForm.text7": "itxi",
    "depositForm.text8": "Gordailua Kopurua",
    "depositForm.text9": "kontua NAN",
    "depositForm.text10": "Kreditu txartelarekin ordaindu",
    "depositForm.text11": "Gordailuak kobratuko kreditu txartelaren kuota% 3,6 eta gutxienez 5 dolar ($ 5) zenbatekoa ($ baino gehiago 10 gomendagarria da) da.",
    "depositForm.text12": "Ez gara okerra kontua identifikazioak ardura.",
    "depositForm.text13": "Aurreikusitako XLM zenbatekoa izango duzu",
    "depositForm.text14": "oinarritutako XLM prezioa",
    "depositForm.text15": "prezioa",
    "depositForm.text16": "eguneratzea",
    "depositForm.text17": "baliogabe kontua NAN",
    "__2": "",
    "stripeCardPay.text1": "Saiatu berriro",
    "stripeCardPay.text2": "txartela ordainketa jarraitzeko",
    "stripeCardPay.text3": "Izena kreditu txarteleko",
    "stripeCardPay.text4": "berrezarri sarrera"
  },
  {
    "LANGUAGE": "Farsi",
    "CODE": "fa",
    "general.text1": "سلام",
    "general.text2": "متشکرم",
    "general.text3": "خوش آمدی",
    "general.text4": "با تشکر",
    "general.text5": "بورس سهام",
    "general.text6": "تبادل",
    "general.text7": "کیف پول",
    "general.text8": "ارسال",
    "general.text9": "ارسال پول",
    "general.text10": "دريافت كردن",
    "general.text11": "دریافت پول",
    "general.text12": "تجارت",
    "general.text13": "معاملات سهام",
    "general.text14": "گوشی های هوشمند",
    "general.text15": "در دست تو",
    "general.text16": "شرح",
    "general.text17": "تاریخ",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "بازار",
    "general.text21": "اطلاعات",
    "general.text22": "فروش",
    "general.text23": "خرید",
    "general.text24": "معامله",
    "general.text25": "نوع",
    "general.text26": "مدیریت",
    "general.text27": "کنترل",
    "general.text28": "جستجو کردن",
    "general.text29": "XX",
    "general.text30": "موازنه",
    "general.text31": "تایید کنید",
    "general.text32": "تاریخ",
    "general.text33": "ثبت معامله",
    "general.text34": "XX",
    "general.text35": "مقصد",
    "general.text36": "انتخاب کنید",
    "general.text37": "تنظیم",
    "general.text38": "دارایی",
    "general.text39": "ورودی",
    "general.text40": "وارد",
    "general.text41": "میزان",
    "general.text42": "پیدا کردن مسیر",
    "general.text43": "هزینه تخمین زده شده",
    "general.text44": "پرداخت",
    "general.text45": "به استثنای",
    "general.text46": "توصیه شده",
    "general.text47": "تعادل خود را نشان دهد",
    "general.text48": "ورودی تایید",
    "general.text49": "ورودی روشن",
    "general.text50": "حساب",
    "general.text51": "حساب بانکی",
    "general.text52": "پرداخت",
    "general.text53": "دو قدم",
    "general.text54": "خود را",
    "general.text55": "XX",
    "general.text56": "شرایط استفاده",
    "general.text57": "سیاست حفظ حریم خصوصی",
    "": "",
    "toppage.text1": "سپرده XLM به خود ID حساب با کارت اعتباری",
    "toppage.text2": "(اگر شما حساب ID ندارد، شما می توانید حساب ID با استفاده از نرم افزار در StellarSpace تولید)",
    "toppage.text3": "زبان",
    "toppage.text4": "تغییر دادن",
    "__1": "",
    "depositForm.text1": "سپرده موفق",
    "depositForm.text2": "سپرده شکست خورده",
    "depositForm.text3": "پردازش سپرده ...",
    "depositForm.text4": "سپرده شما با موفقیت با ID معامله کامل:",
    "depositForm.text5": "سپرده خود را با موفقیت به پایان است. اگر شما هم متهم شده اند، لطفا با پشتیبانی تماس بگیرید",
    "depositForm.text6": "سپرده خود را در حال پردازش است و ما در حال تلاش برای ارسال پول خود را.",
    "depositForm.text7": "بستن",
    "depositForm.text8": "سپرده تعداد",
    "depositForm.text9": "شناسه حساب",
    "depositForm.text10": "پرداخت با کارت اعتباری",
    "depositForm.text11": "سپرده ها از 3.6٪ کل هزینه را پرداخت کارت اعتباری و حداقل 5 دلار ($ 5) مقدار (بیش از 10 $ توصیه می شود) است.",
    "depositForm.text12": "ما مسئول شناسه حساب نادرست نیست.",
    "depositForm.text13": "مقدار برآورد XLM شما باید",
    "depositForm.text14": "بر اساس XLM قیمت",
    "depositForm.text15": "قیمت",
    "depositForm.text16": "به روز رسانی",
    "depositForm.text17": "شناسه حساب نامعتبر",
    "__2": "",
    "stripeCardPay.text1": "دوباره امتحان کنید",
    "stripeCardPay.text2": "اقدام به پرداخت کارت",
    "stripeCardPay.text3": "نام روی کارت اعتباری",
    "stripeCardPay.text4": "ورودی تنظیم مجدد"
  },
  {
    "LANGUAGE": "Finnish",
    "CODE": "fi",
    "general.text1": "Hei",
    "general.text2": "Kiitos",
    "general.text3": "Tervetuloa",
    "general.text4": "Kiitos",
    "general.text5": "arvopaperipörssi",
    "general.text6": "vaihto",
    "general.text7": "lompakko",
    "general.text8": "lähettää",
    "general.text9": "Lähetä rahaa",
    "general.text10": "vastaanottaa",
    "general.text11": "Vastaanottaa rahaa",
    "general.text12": "kaupankäynti",
    "general.text13": "varastossa kaupankäynnin",
    "general.text14": "älypuhelin",
    "general.text15": "käteen",
    "general.text16": "kuvaus",
    "general.text17": "Päivämäärä",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "markkinoida",
    "general.text21": "tiedot",
    "general.text22": "myydä",
    "general.text23": "ostaa",
    "general.text24": "liiketoimi",
    "general.text25": "tyyppi",
    "general.text26": "hoitaa",
    "general.text27": "ohjaus",
    "general.text28": "Hae",
    "general.text29": "xx",
    "general.text30": "saldot",
    "general.text31": "hyväksyä",
    "general.text32": "historia",
    "general.text33": "kirjaavat",
    "general.text34": "xx",
    "general.text35": "määränpää",
    "general.text36": "valita",
    "general.text37": "aseta",
    "general.text38": "etu",
    "general.text39": "panos",
    "general.text40": "tulla sisään",
    "general.text41": "määrä",
    "general.text42": "löytää polku",
    "general.text43": "kustannusarvio",
    "general.text44": "maksu",
    "general.text45": "lukuun ottamatta",
    "general.text46": "suositeltava",
    "general.text47": "osoittavat saldosi",
    "general.text48": "Vahvista panokset",
    "general.text49": "kirkas tulot",
    "general.text50": "tili",
    "general.text51": "pankkitili",
    "general.text52": "maksu",
    "general.text53": "kaksi askelta",
    "general.text54": "teidän",
    "general.text55": "xx",
    "general.text56": "käyttöehdot",
    "general.text57": "Tietosuojakäytäntö",
    "": "",
    "toppage.text1": "Talletus XLM Tilin tunnus luottokortilla",
    "toppage.text2": "(Jos sinulla ei ole tilin tunnus, voit luoda tilin tunnus sovelluslaajennusten at StellarSpace)",
    "toppage.text3": "Kieli",
    "toppage.text4": "Muuttaa",
    "__1": "",
    "depositForm.text1": "talletus onnistui",
    "depositForm.text2": "talletus epäonnistui",
    "depositForm.text3": "Processing talletus ...",
    "depositForm.text4": "Talletus on onnistunut kanssa tapahtuman ID:",
    "depositForm.text5": "Talletus ei onnistunut. Jos sinua on veloitettu, ota yhteyttä tukeen",
    "depositForm.text6": "Talletus käsitellään ja yritämme lähettää varoja.",
    "depositForm.text7": "kiinni",
    "depositForm.text8": "talletus Määrä",
    "depositForm.text9": "Tilin tunnus",
    "depositForm.text10": "Maksaa luottokortilla",
    "depositForm.text11": "Talletukset veloitetaan luottokortin maksu 3,6% ja pienin on 5 dollaria ($ 5) määrä (yli $ 10 suositellaan).",
    "depositForm.text12": "Emme ole vastuussa virheellisestä tilitunnukset.",
    "depositForm.text13": "Arvioitu XLM summa joudut",
    "depositForm.text14": "perustuu XLM hinta",
    "depositForm.text15": "hinta",
    "depositForm.text16": "päivittää",
    "depositForm.text17": "Virheellinen tilin tunnus",
    "__2": "",
    "stripeCardPay.text1": "Yritä uudelleen",
    "stripeCardPay.text2": "Siirry korttimaksamisen",
    "stripeCardPay.text3": "Nimi luottokortissa",
    "stripeCardPay.text4": "palautustu-"
  },
  {
    "LANGUAGE": "Faroese",
    "CODE": "fo",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "general.text24": "#VALUE!",
    "general.text25": "#VALUE!",
    "general.text26": "#VALUE!",
    "general.text27": "#VALUE!",
    "general.text28": "#VALUE!",
    "general.text29": "#VALUE!",
    "general.text30": "#VALUE!",
    "general.text31": "#VALUE!",
    "general.text32": "#VALUE!",
    "general.text33": "#VALUE!",
    "general.text34": "#VALUE!",
    "general.text35": "#VALUE!",
    "general.text36": "#VALUE!",
    "general.text37": "#VALUE!",
    "general.text38": "#VALUE!",
    "general.text39": "#VALUE!",
    "general.text40": "#VALUE!",
    "general.text41": "#VALUE!",
    "general.text42": "#VALUE!",
    "general.text43": "#VALUE!",
    "general.text44": "#VALUE!",
    "general.text45": "#VALUE!",
    "general.text46": "#VALUE!",
    "general.text47": "#VALUE!",
    "general.text48": "#VALUE!",
    "general.text49": "#VALUE!",
    "general.text50": "#VALUE!",
    "general.text51": "#VALUE!",
    "general.text52": "#VALUE!",
    "general.text53": "#VALUE!",
    "general.text54": "#VALUE!",
    "general.text55": "#VALUE!",
    "general.text56": "#VALUE!",
    "general.text57": "#VALUE!",
    "": "",
    "toppage.text1": "#VALUE!",
    "toppage.text2": "#VALUE!",
    "toppage.text3": "#VALUE!",
    "toppage.text4": "#VALUE!",
    "__1": "",
    "depositForm.text1": "#VALUE!",
    "depositForm.text2": "#VALUE!",
    "depositForm.text3": "#VALUE!",
    "depositForm.text4": "#VALUE!",
    "depositForm.text5": "#VALUE!",
    "depositForm.text6": "#VALUE!",
    "depositForm.text7": "#VALUE!",
    "depositForm.text8": "#VALUE!",
    "depositForm.text9": "#VALUE!",
    "depositForm.text10": "#VALUE!",
    "depositForm.text11": "#VALUE!",
    "depositForm.text12": "#VALUE!",
    "depositForm.text13": "#VALUE!",
    "depositForm.text14": "#VALUE!",
    "depositForm.text15": "#VALUE!",
    "depositForm.text16": "#VALUE!",
    "depositForm.text17": "#VALUE!",
    "__2": "",
    "stripeCardPay.text1": "#VALUE!",
    "stripeCardPay.text2": "#VALUE!",
    "stripeCardPay.text3": "#VALUE!",
    "stripeCardPay.text4": "#VALUE!"
  },
  {
    "LANGUAGE": "French",
    "CODE": "fr",
    "general.text1": "Bonjour",
    "general.text2": "Je vous remercie",
    "general.text3": "Bienvenue",
    "general.text4": "Merci",
    "general.text5": "Bourse",
    "general.text6": "échange",
    "general.text7": "portefeuille",
    "general.text8": "envoyer",
    "general.text9": "envoyer de l'argent",
    "general.text10": "recevoir",
    "general.text11": "recevoir de l'argent",
    "general.text12": "commerce",
    "general.text13": "stock trading",
    "general.text14": "téléphone intelligent",
    "general.text15": "dans votre main",
    "general.text16": "la description",
    "general.text17": "Date",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "marché",
    "general.text21": "information",
    "general.text22": "vendre",
    "general.text23": "acheter",
    "general.text24": "transaction",
    "general.text25": "type",
    "general.text26": "gérer",
    "general.text27": "contrôle",
    "general.text28": "Chercher",
    "general.text29": "xx",
    "general.text30": "soldes",
    "general.text31": "J'accepte",
    "general.text32": "l'histoire",
    "general.text33": "relevé d'opération",
    "general.text34": "xx",
    "general.text35": "destination",
    "general.text36": "sélectionner",
    "general.text37": "ensemble",
    "general.text38": "atout",
    "general.text39": "contribution",
    "general.text40": "entrer",
    "general.text41": "montant",
    "general.text42": "chemin trouver",
    "general.text43": "coût estimé",
    "general.text44": "frais",
    "general.text45": "à l'exclusion",
    "general.text46": "conseillé",
    "general.text47": "montrer votre équilibre",
    "general.text48": "Confirmer entrées",
    "general.text49": "entrées claires",
    "general.text50": "Compte",
    "general.text51": "compte bancaire",
    "general.text52": "Paiement",
    "general.text53": "deux étapes",
    "general.text54": "votre",
    "general.text55": "xx",
    "general.text56": "conditions d'utilisation",
    "general.text57": "politique de confidentialité",
    "": "",
    "toppage.text1": "Dépôt XLM à votre ID de compte avec une carte de crédit",
    "toppage.text2": "(Si vous ne possédez pas de compte ID, vous pouvez générer l'identifiant de compte à l'aide App à StellarSpace)",
    "toppage.text3": "Langue",
    "toppage.text4": "Changement",
    "__1": "",
    "depositForm.text1": "dépôt réussi",
    "depositForm.text2": "Le dépôt a échoué",
    "depositForm.text3": "Dépôt de traitement ...",
    "depositForm.text4": "Votre dépôt a terminé avec succès avec l'ID de transaction:",
    "depositForm.text5": "Votre dépôt n'a pas terminé avec succès. Si vous avez été accusé, s'il vous plaît contactez l'assistance",
    "depositForm.text6": "Votre dépôt est en cours de traitement et nous essayons d'envoyer vos fonds.",
    "depositForm.text7": "Fermer",
    "depositForm.text8": "Quantité de dépôt",
    "depositForm.text9": "identifiant de compte",
    "depositForm.text10": "Payez avec une carte de crédit",
    "depositForm.text11": "Les dépôts sont facturés les frais de carte de crédit de 3,6% et le minimum est de 5 USD (5 $) montant (plus de 10 $ est recommandé).",
    "depositForm.text12": "Nous ne sommes pas responsables des identifiants incorrects de compte.",
    "depositForm.text13": "Montant estimé de XLM vous aurez",
    "depositForm.text14": "en fonction du prix XLM",
    "depositForm.text15": "le prix",
    "depositForm.text16": "mettre à jour",
    "depositForm.text17": "invalide ID de compte",
    "__2": "",
    "stripeCardPay.text1": "Réessayer",
    "stripeCardPay.text2": "Procéder au paiement de la carte",
    "stripeCardPay.text3": "Nom sur la carte de crédit",
    "stripeCardPay.text4": "réinitialiser les entrées"
  },
  {
    "LANGUAGE": "Galician",
    "CODE": "gl",
    "general.text1": "Ola",
    "general.text2": "Grazas",
    "general.text3": "Benvido",
    "general.text4": "Grazas",
    "general.text5": "bolsa de valores",
    "general.text6": "cambio",
    "general.text7": "carteira",
    "general.text8": "Send",
    "general.text9": "cartos Send",
    "general.text10": "recibir",
    "general.text11": "recibir diñeiro",
    "general.text12": "comercio",
    "general.text13": "negociación de accións",
    "general.text14": "smartphones",
    "general.text15": "na súa man",
    "general.text16": "descrición",
    "general.text17": "data",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "mercado",
    "general.text21": "información",
    "general.text22": "Sell",
    "general.text23": "Buy",
    "general.text24": "transacción",
    "general.text25": "tipo",
    "general.text26": "xestionar",
    "general.text27": "control",
    "general.text28": "Buscar",
    "general.text29": "XX",
    "general.text30": "saldos",
    "general.text31": "aceptar",
    "general.text32": "historia",
    "general.text33": "rexistro de transaccións",
    "general.text34": "XX",
    "general.text35": "destino",
    "general.text36": "seleccionar",
    "general.text37": "conxunto",
    "general.text38": "trunfo",
    "general.text39": "entrada",
    "general.text40": "entrar",
    "general.text41": "cantidade",
    "general.text42": "atopar o camiño",
    "general.text43": "custo estimado",
    "general.text44": "taxa",
    "general.text45": "excluíndo",
    "general.text46": "recomendado",
    "general.text47": "mostrar o seu equilibrio",
    "general.text48": "entradas confirman",
    "general.text49": "contribucións claras",
    "general.text50": "conta",
    "general.text51": "conta bancaria",
    "general.text52": "pago",
    "general.text53": "dúas etapas",
    "general.text54": "seu",
    "general.text55": "XX",
    "general.text56": "Condicións de uso",
    "general.text57": "política de Privacidade",
    "": "",
    "toppage.text1": "Depósito XLM ao seu ID de conta cunha tarxeta de crédito",
    "toppage.text2": "(Se non ten conta ID, pode xerar conta ID usando App no ​​StellarSpace)",
    "toppage.text3": "Lingua",
    "toppage.text4": "cambio",
    "__1": "",
    "depositForm.text1": "depósito sucedido",
    "depositForm.text2": "depósito fallou",
    "depositForm.text3": "Procesamento de depósito ...",
    "depositForm.text4": "O seu depósito foi concluída con éxito con ID de transacción:",
    "depositForm.text5": "O seu depósito non se completa con éxito. Se foi acusado, por favor, contacta co soporte en",
    "depositForm.text6": "O depósito está a ser procesada e estamos tentando enviar os seus fondos.",
    "depositForm.text7": "preto",
    "depositForm.text8": "depósito Cantidade",
    "depositForm.text9": "ID da conta",
    "depositForm.text10": "Pago con tarxeta de crédito",
    "depositForm.text11": "Depósitos son cobrados a taxa de tarxeta de crédito de 3,6% eo mínimo é de 5 dólares ($ 5) cantidade (máis de US $ 10 se recomenda).",
    "depositForm.text12": "Non somos responsables IDs de conta incorrecto.",
    "depositForm.text13": "cantidade XLM estimado terá",
    "depositForm.text14": "con base no prezo XLM",
    "depositForm.text15": "prezo",
    "depositForm.text16": "actualizar",
    "depositForm.text17": "válido ID da conta",
    "__2": "",
    "stripeCardPay.text1": "ténteo de novo",
    "stripeCardPay.text2": "Avance para o pago con tarxeta",
    "stripeCardPay.text3": "Nome da tarxeta de crédito",
    "stripeCardPay.text4": "entradas de reset"
  },
  {
    "LANGUAGE": "Gujarati",
    "CODE": "gu",
    "general.text1": "નમસ્તે",
    "general.text2": "આભાર",
    "general.text3": "સ્વાગત",
    "general.text4": "આભાર",
    "general.text5": "શેરબજાર",
    "general.text6": "વિનિમય",
    "general.text7": "વૉલેટ",
    "general.text8": "મોકલો",
    "general.text9": "મોકલો નાણાં",
    "general.text10": "પ્રાપ્ત",
    "general.text11": "નાણાં પ્રાપ્ત",
    "general.text12": "વેપાર",
    "general.text13": "સ્ટોક ટ્રેડિંગ",
    "general.text14": "સ્માર્ટફોન",
    "general.text15": "તમારા હાથમાં",
    "general.text16": "વર્ણન",
    "general.text17": "તારીખ",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "બજાર",
    "general.text21": "માહિતી",
    "general.text22": "વેચાણ",
    "general.text23": "ખરીદો",
    "general.text24": "સોદા",
    "general.text25": "પ્રકાર",
    "general.text26": "મેનેજ કરો",
    "general.text27": "નિયંત્રણ",
    "general.text28": "શોધો",
    "general.text29": "XX",
    "general.text30": "બેલેન્સ",
    "general.text31": "સ્વીકારી",
    "general.text32": "ઇતિહાસ",
    "general.text33": "વ્યવહાર રેકોર્ડ",
    "general.text34": "XX",
    "general.text35": "ગંતવ્ય",
    "general.text36": "પસંદ કરો",
    "general.text37": "સમૂહ",
    "general.text38": "એસેટ",
    "general.text39": "ઇનપુટ",
    "general.text40": "દાખલ",
    "general.text41": "રકમ",
    "general.text42": "પાથ શોધવા",
    "general.text43": "અંદાજિત કિંમત",
    "general.text44": "ફી",
    "general.text45": "બાકાત",
    "general.text46": "ભલામણ",
    "general.text47": "તમારું બેલેન્સ બતાવી",
    "general.text48": "પુષ્ટિ ઇનપુટ્સ",
    "general.text49": "સ્પષ્ટ ઇનપુટ્સ",
    "general.text50": "એકાઉન્ટ",
    "general.text51": "બેંક એકાઉન્ટ",
    "general.text52": "ચુકવણી",
    "general.text53": "બે પગલાની",
    "general.text54": "તમારા",
    "general.text55": "XX",
    "general.text56": "વાપરવાના નિયમો",
    "general.text57": "ગોપનીયતા નીતિ",
    "": "",
    "toppage.text1": "ક્રેડિટ કાર્ડ સાથે તમારા એકાઉન્ટને ID ને ડિપોઝિટ XLM",
    "toppage.text2": "(જો તમે એકાઉન્ટ ID ન હોય તો, તમે StellarSpace ખાતે એપ્લિકેશનનો ઉપયોગ કરવા એકાઉન્ટ ID પેદા કરી શકે છે)",
    "toppage.text3": "ભાષાની",
    "toppage.text4": "બદલો",
    "__1": "",
    "depositForm.text1": "ડિપોઝિટ સફળ",
    "depositForm.text2": "ડિપોઝિટ નિષ્ફળ થઈ",
    "depositForm.text3": "થાપણ પ્રોસેસિંગ ...",
    "depositForm.text4": "તમારું ડિપોઝિટ વ્યવહાર ID સાથે સફળતાપૂર્વક પૂર્ણ કર્યા છે:",
    "depositForm.text5": "તમારું ડિપોઝિટ સફળતાપૂર્વક પૂર્ણ થઈ નહોતી. તમને ચાર્જ કરવામાં આવી છે, તો પર સપોર્ટનો સંપર્ક કરો કૃપા કરીને",
    "depositForm.text6": "તમારું થાપણ પ્રક્રિયા કરવામાં આવી રહી છે અને અમે તમારા ફંડ્સ મોકલવા માટે પ્રયાસ કરી રહ્યાં છો.",
    "depositForm.text7": "બંધ",
    "depositForm.text8": "ડિપોઝિટ જથ્થો",
    "depositForm.text9": "એકાઉન્ટ ID",
    "depositForm.text10": "ક્રેડિટ કાર્ડ સાથે ચૂકવણી",
    "depositForm.text11": "થાપણો 3.6% ક્રેડિટ કાર્ડ ફી ચાર્જ કરવામાં આવે છે અને લઘુત્તમ 5 ડોલર ($ 5) રકમ (કરતાં વધુ $ 10 આગ્રહણીય છે) છે.",
    "depositForm.text12": "અમે ખોટી એકાઉન્ટ ID માટે જવાબદાર નથી.",
    "depositForm.text13": "અંદાજિત XLM રકમ તમે પડશે",
    "depositForm.text14": "XLM કિંમત પર આધારિત",
    "depositForm.text15": "કિંમત",
    "depositForm.text16": "સુધારો",
    "depositForm.text17": "અમાન્ય એકાઉન્ટ ID",
    "__2": "",
    "stripeCardPay.text1": "ફરીથી પ્રયત્ન કરો",
    "stripeCardPay.text2": "કાર્ડ ચુકવણી માટે આગળ વધો",
    "stripeCardPay.text3": "ક્રેડિટ કાર્ડ પરનું નામ",
    "stripeCardPay.text4": "રીસેટ ઇનપુટ્સ"
  },
  {
    "LANGUAGE": "Hebrew",
    "CODE": "he",
    "general.text1": "שלום",
    "general.text2": "תודה",
    "general.text3": "ברוך הבא",
    "general.text4": "תודה",
    "general.text5": "בּוּרסָה",
    "general.text6": "לְהַחלִיף",
    "general.text7": "ארנק",
    "general.text8": "לִשְׁלוֹחַ",
    "general.text9": "שלח כסף",
    "general.text10": "לְקַבֵּל",
    "general.text11": "לקבל כסף",
    "general.text12": "מִסְחָר",
    "general.text13": "מסחר במניות",
    "general.text14": "טלפון חכם",
    "general.text15": "ביד שלך",
    "general.text16": "תיאור",
    "general.text17": "תַאֲרִיך",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "שׁוּק",
    "general.text21": "מֵידָע",
    "general.text22": "מכירה",
    "general.text23": "לִקְנוֹת",
    "general.text24": "עִסקָה",
    "general.text25": "סוּג",
    "general.text26": "לנהל",
    "general.text27": "לִשְׁלוֹט",
    "general.text28": "לחפש",
    "general.text29": "xx",
    "general.text30": "יתרה",
    "general.text31": "לְקַבֵּל",
    "general.text32": "הִיסטוֹרִיָה",
    "general.text33": "שיא עסקה",
    "general.text34": "xx",
    "general.text35": "יַעַד",
    "general.text36": "בחר",
    "general.text37": "מַעֲרֶכֶת",
    "general.text38": "נכס",
    "general.text39": "קֶלֶט",
    "general.text40": "להיכנס",
    "general.text41": "כמות",
    "general.text42": "למצוא נתיב",
    "general.text43": "מחיר משוער",
    "general.text44": "תַשְׁלוּם",
    "general.text45": "לְמַעֵט",
    "general.text46": "מוּמלָץ",
    "general.text47": "להציג את יתרת",
    "general.text48": "תשומות confirm",
    "general.text49": "תשומות ברורות",
    "general.text50": "חֶשְׁבּוֹן",
    "general.text51": "חשבון בנק",
    "general.text52": "תַשְׁלוּם",
    "general.text53": "שני צעדים",
    "general.text54": "שֶׁלךָ",
    "general.text55": "xx",
    "general.text56": "תנאי שימוש",
    "general.text57": "מדיניות פרטיות",
    "": "",
    "toppage.text1": "פיקדון XLM כדי מזהה החשבון שלך באמצעות כרטיס אשראי",
    "toppage.text2": "(אם אין לך חשבון ID, אתה יכול ליצור מזהה החשבון באמצעות App ב StellarSpace)",
    "toppage.text3": "שפה",
    "toppage.text4": "שינוי",
    "__1": "",
    "depositForm.text1": "פיקדון הצליח",
    "depositForm.text2": "ההפקדה נכשלה",
    "depositForm.text3": "עיבוד פיקדון ...",
    "depositForm.text4": "ההפקדה שלך השלימה בהצלחה עם מזהה עסקה:",
    "depositForm.text5": "ההפקדה שלך לא הושלמה בהצלחה. אם חויבת, אנא פנה לתמיכה ב",
    "depositForm.text6": "ההפקדה שלך נמצאת בתהליכי עיבוד ואנחנו מנסים לשלוח הכספים שלך.",
    "depositForm.text7": "סגור",
    "depositForm.text8": "כמות פיקדון",
    "depositForm.text9": "מזהה חשבון",
    "depositForm.text10": "שלם עם כרטיס אשראי",
    "depositForm.text11": "פיקדונות נזקפים דמי כרטיס אשראי של 3.6% ואת המינימום הוא 5 דולר (5 $) כמות (יותר מ 10 $ מומלץ).",
    "depositForm.text12": "אנחנו לא אחראים מזהי חשבון לא נכונים.",
    "depositForm.text13": "אומדן סכום XLM תצטרך",
    "depositForm.text14": "בהתבסס על מחיר XLM",
    "depositForm.text15": "מחיר",
    "depositForm.text16": "עדכון",
    "depositForm.text17": "מזהה חשבון לא חוקי",
    "__2": "",
    "stripeCardPay.text1": "נסה שוב",
    "stripeCardPay.text2": "המשך תשלום בכרטיס",
    "stripeCardPay.text3": "שם על כרטיס אשראי",
    "stripeCardPay.text4": "תשומות איפוס"
  },
  {
    "LANGUAGE": "Hindi",
    "CODE": "hi",
    "general.text1": "हैलो",
    "general.text2": "धन्यवाद",
    "general.text3": "स्वागत हे",
    "general.text4": "धन्यवाद",
    "general.text5": "शेयर बाजार",
    "general.text6": "अदला बदली",
    "general.text7": "बटुआ",
    "general.text8": "भेजने",
    "general.text9": "पैसे भेजो",
    "general.text10": "प्राप्त करना",
    "general.text11": "धन प्राप्त करें",
    "general.text12": "व्यापार",
    "general.text13": "पूंजी व्यापार",
    "general.text14": "स्मार्टफोन",
    "general.text15": "तुम्हारे हाथ में",
    "general.text16": "विवरण",
    "general.text17": "तारीख",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "मंडी",
    "general.text21": "जानकारी",
    "general.text22": "बेचना",
    "general.text23": "खरीद",
    "general.text24": "लेन-देन",
    "general.text25": "प्रकार",
    "general.text26": "प्रबंधन",
    "general.text27": "नियंत्रण",
    "general.text28": "खोज",
    "general.text29": "xx",
    "general.text30": "शेष राशि",
    "general.text31": "स्वीकार करना",
    "general.text32": "इतिहास",
    "general.text33": "लेनदेन का रिकॉर्ड",
    "general.text34": "xx",
    "general.text35": "गंतव्य",
    "general.text36": "चुनते हैं",
    "general.text37": "सेट",
    "general.text38": "संपत्ति",
    "general.text39": "इनपुट",
    "general.text40": "दर्ज",
    "general.text41": "रकम",
    "general.text42": "रास्ता ढूंढने",
    "general.text43": "अनुमानित लागत",
    "general.text44": "शुल्क",
    "general.text45": "के सिवा",
    "general.text46": "की सिफारिश की",
    "general.text47": "अपनी शेष राशि को दिखाने",
    "general.text48": "पुष्टि आदानों",
    "general.text49": "स्पष्ट आदानों",
    "general.text50": "लेखा",
    "general.text51": "बैंक खाता",
    "general.text52": "भुगतान",
    "general.text53": "दो कदम",
    "general.text54": "तुम्हारी",
    "general.text55": "xx",
    "general.text56": "उपयोग की शर्तें",
    "general.text57": "गोपनीयता नीति",
    "": "",
    "toppage.text1": "एक क्रेडिट कार्ड के साथ अपने खाता आईडी को जमा XLM",
    "toppage.text2": "(आप खाता आईडी की जरूरत नहीं है, तो आप StellarSpace पर App का उपयोग खाता आईडी उत्पन्न कर सकते हैं)",
    "toppage.text3": "भाषा: हिन्दी",
    "toppage.text4": "खुले पैसे",
    "__1": "",
    "depositForm.text1": "जमा सफल रहा",
    "depositForm.text2": "जमा विफल",
    "depositForm.text3": "जमा का संसाधन ...",
    "depositForm.text4": "आपके द्वारा जमा लेनदेन आईडी के साथ सफलतापूर्वक पूरा कर लिया है:",
    "depositForm.text5": "आपके द्वारा जमा सफलतापूर्वक पूर्ण नहीं हुआ। आप का आरोप लगाया गया है, पर सहायता से संपर्क करें",
    "depositForm.text6": "आपके द्वारा जमा कार्रवाई की जा रही है और हमें आपकी राशि भेजने के लिए प्रयास कर रहे हैं।",
    "depositForm.text7": "बंद करे",
    "depositForm.text8": "जमा मात्रा",
    "depositForm.text9": "खाता पहचान",
    "depositForm.text10": "क्रेडिट कार्ड से भुगतान करें",
    "depositForm.text11": "जमा 3.6% की क्रेडिट कार्ड शुल्क लिया जाता है और कम से कम 5 अमरीकी डालर ($ 5) राशि (एक से अधिक $ 10 की सिफारिश की है) है।",
    "depositForm.text12": "हम गलत खाता आईडी के लिए जिम्मेदार नहीं हैं।",
    "depositForm.text13": "अनुमानित XLM राशि है जो आप करना होगा",
    "depositForm.text14": "XLM मूल्य के आधार पर",
    "depositForm.text15": "कीमत",
    "depositForm.text16": "अपडेट करें",
    "depositForm.text17": "अमान्य खाता आईडी",
    "__2": "",
    "stripeCardPay.text1": "पुनः प्रयास करें",
    "stripeCardPay.text2": "कार्ड भुगतान के लिए आगे बढ़ें",
    "stripeCardPay.text3": "क्रेडिट कार्ड पर नाम",
    "stripeCardPay.text4": "रीसेट आदानों"
  },
  {
    "LANGUAGE": "Croatian",
    "CODE": "hr",
    "general.text1": "zdravo",
    "general.text2": "Hvala vam",
    "general.text3": "Dobrodošli",
    "general.text4": "Hvala",
    "general.text5": "burza",
    "general.text6": "razmjena",
    "general.text7": "novčanik",
    "general.text8": "poslati",
    "general.text9": "poslati novac",
    "general.text10": "dobiti",
    "general.text11": "primiti novac",
    "general.text12": "trgovanje",
    "general.text13": "trgovanje dionicama",
    "general.text14": "pametni telefon",
    "general.text15": "u ruci",
    "general.text16": "opis",
    "general.text17": "datum",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "tržište",
    "general.text21": "informacija",
    "general.text22": "prodavati",
    "general.text23": "kupiti",
    "general.text24": "transakcija",
    "general.text25": "tip",
    "general.text26": "upravljati",
    "general.text27": "kontrolirati",
    "general.text28": "traži",
    "general.text29": "xx",
    "general.text30": "stanja",
    "general.text31": "prihvatiti",
    "general.text32": "povijest",
    "general.text33": "transakcija rekord",
    "general.text34": "xx",
    "general.text35": "odredište",
    "general.text36": "Odaberi",
    "general.text37": "set",
    "general.text38": "imovina",
    "general.text39": "ulazni",
    "general.text40": "Unesi",
    "general.text41": "iznos",
    "general.text42": "naći put",
    "general.text43": "procijenjena vrijednost",
    "general.text44": "pristojba",
    "general.text45": "izuzimajući",
    "general.text46": "preporuča",
    "general.text47": "prikazivali stanje",
    "general.text48": "Potvrdi ulaza",
    "general.text49": "jasne ulaza",
    "general.text50": "račun",
    "general.text51": "bankovni račun",
    "general.text52": "plaćanje",
    "general.text53": "dva koraka",
    "general.text54": "vaš",
    "general.text55": "xx",
    "general.text56": "Uvjeti korištenja",
    "general.text57": "Izjava o privatnosti",
    "": "",
    "toppage.text1": "XLM depozit na svoj ID računa s kreditne kartice",
    "toppage.text2": "(Ako nemate ID račun, možete generirati ID račun pomoću aplikacije na StellarSpace)",
    "toppage.text3": "Jezik",
    "toppage.text4": "Promijeniti",
    "__1": "",
    "depositForm.text1": "depozit uspjelo",
    "depositForm.text2": "depozit nije uspio",
    "depositForm.text3": "Obrada depozit ...",
    "depositForm.text4": "Vaš depozit uspješno završena ID transakcije:",
    "depositForm.text5": "Vaš depozit nije uspješno dovršena. Ako ste platili, molimo kontaktirajte podršku na",
    "depositForm.text6": "Vaš depozit se obrađuje i nastojimo poslati svoje fondove.",
    "depositForm.text7": "Zatvoriti",
    "depositForm.text8": "depozit Količina",
    "depositForm.text9": "ID računa",
    "depositForm.text10": "Plaćate kreditnom karticom",
    "depositForm.text11": "Polozi naplaćuju naknadu kreditne kartice od 3,6%, a minimalno je 5 USD ($ 5) Iznos (više od 10 $ je preporučeno).",
    "depositForm.text12": "Mi nismo odgovorni za ID pogrešnih računa.",
    "depositForm.text13": "Procijenjena XLM iznos koji će imati",
    "depositForm.text14": "na temelju XLM cijeni",
    "depositForm.text15": "cijena",
    "depositForm.text16": "Ažuriraj",
    "depositForm.text17": "ID nevažeći račun",
    "__2": "",
    "stripeCardPay.text1": "Pokušaj ponovo",
    "stripeCardPay.text2": "Nastavite za plaćanje karticama",
    "stripeCardPay.text3": "ime na kreditnoj kartici",
    "stripeCardPay.text4": "reset ulaza"
  },
  {
    "LANGUAGE": "Hungarian",
    "CODE": "hu",
    "general.text1": "Szia",
    "general.text2": "Köszönöm",
    "general.text3": "Üdvözöljük",
    "general.text4": "Kösz",
    "general.text5": "tőzsde",
    "general.text6": "csere",
    "general.text7": "pénztárca",
    "general.text8": "Küld",
    "general.text9": "Küldj pénzt",
    "general.text10": "kap",
    "general.text11": "pénzt kapni",
    "general.text12": "kereskedés",
    "general.text13": "kereskedelmi készletek",
    "general.text14": "okostelefon",
    "general.text15": "a kezedben",
    "general.text16": "leírás",
    "general.text17": "dátum",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "piac",
    "general.text21": "információ",
    "general.text22": "elad",
    "general.text23": "megvesz",
    "general.text24": "tranzakció",
    "general.text25": "típus",
    "general.text26": "kezel",
    "general.text27": "ellenőrzés",
    "general.text28": "Keresés",
    "general.text29": "xx",
    "general.text30": "egyenlegek",
    "general.text31": "elfogad",
    "general.text32": "történelem",
    "general.text33": "tranzakció rekord",
    "general.text34": "xx",
    "general.text35": "rendeltetési hely",
    "general.text36": "választ",
    "general.text37": "készlet",
    "general.text38": "vagyontárgy",
    "general.text39": "bemenet",
    "general.text40": "belép",
    "general.text41": "összeg",
    "general.text42": "megtalálja útját",
    "general.text43": "becsült költség",
    "general.text44": "díj",
    "general.text45": "kizárás",
    "general.text46": "ajánlott",
    "general.text47": "mutatni a mérleg",
    "general.text48": "megerősítés bemenetek",
    "general.text49": "világos bemenetek",
    "general.text50": "számla",
    "general.text51": "bankszámla",
    "general.text52": "fizetés",
    "general.text53": "két lépés",
    "general.text54": "a te",
    "general.text55": "xx",
    "general.text56": "használati feltételek",
    "general.text57": "Adatvédelmi irányelvek",
    "": "",
    "toppage.text1": "Betét XLM a Fiókazonosító bankkártyával",
    "toppage.text2": "(Ha nincs Fiókazonosító, akkor létrehoz Fiókazonosító alkalmazásbővítményekkel meg StellarSpace)",
    "toppage.text3": "Nyelv",
    "toppage.text4": "változás",
    "__1": "",
    "depositForm.text1": "Betét sikerült",
    "depositForm.text2": "sikertelen utalás",
    "depositForm.text3": "Feldolgozás betét ...",
    "depositForm.text4": "A befizetés sikeresen befejeződött a tranzakciós ID:",
    "depositForm.text5": "A befizetés nem sikerült befejezni. Ha már fel van töltve, kérjük, lépjen kapcsolatba az ügyfélszolgálattal",
    "depositForm.text6": "A befizetés feldolgozásra kerül, és megpróbáljuk elküldeni az alapok.",
    "depositForm.text7": "Bezárás",
    "depositForm.text8": "Betét Mennyiség",
    "depositForm.text9": "felhasználónév",
    "depositForm.text10": "Pay Hitelkártya",
    "depositForm.text11": "Kauciókat a hitelkártya díja 3,6%, a minimális 5 USD ($ 5) Nagyobb mennyiség (több mint 10 $ ajánlott).",
    "depositForm.text12": "Nem vagyunk felelősek hibás számla azonosítók.",
    "depositForm.text13": "Becsült XLM összeg lesz",
    "depositForm.text14": "alapuló XLM ár",
    "depositForm.text15": "ár",
    "depositForm.text16": "frissítés",
    "depositForm.text17": "Érvénytelen Fiókazonosító",
    "__2": "",
    "stripeCardPay.text1": "Próbáld újra",
    "stripeCardPay.text2": "Folytassuk a kártyás fizetés",
    "stripeCardPay.text3": "név a hitelkártyán",
    "stripeCardPay.text4": "Reset bemenet"
  },
  {
    "LANGUAGE": "Armenian",
    "CODE": "hy",
    "general.text1": "Բարեւ Ձեզ",
    "general.text2": "Շնորհակալություն",
    "general.text3": "ողջույն",
    "general.text4": "շնորհակալություն",
    "general.text5": "ֆոնդային բորսա",
    "general.text6": "բորսա",
    "general.text7": "դրամապանակ",
    "general.text8": "ուղարկել",
    "general.text9": "գումար ուղարկել",
    "general.text10": "ստանալ",
    "general.text11": "ստանալ գումար",
    "general.text12": "առեւտրային",
    "general.text13": "ֆոնդային առեւտրային",
    "general.text14": "սմարթֆոնը",
    "general.text15": "ձեր ձեռքը",
    "general.text16": "նկարագրություն",
    "general.text17": "ամսաթիվ",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "շուկա",
    "general.text21": "տեղեկություն",
    "general.text22": "վաճառել",
    "general.text23": "Գնեք",
    "general.text24": "գործարքը",
    "general.text25": "տիպ",
    "general.text26": "կառավարել",
    "general.text27": "հսկողություն",
    "general.text28": "որոնել",
    "general.text29": "xx",
    "general.text30": "մնացորդները",
    "general.text31": "ընդունել",
    "general.text32": "պատմություն",
    "general.text33": "գործարքը ռեկորդային",
    "general.text34": "xx",
    "general.text35": "նշանակման վայր",
    "general.text36": "ընտրել",
    "general.text37": "հավաքածու",
    "general.text38": "ակտիվ",
    "general.text39": "մուտքագրում",
    "general.text40": "մտնել",
    "general.text41": "գումար",
    "general.text42": "գտնում ուղին",
    "general.text43": "մոտավոր արժեքը",
    "general.text44": "վճար",
    "general.text45": "Բացառությամբ",
    "general.text46": "խորհուրդ է տրվում",
    "general.text47": "ցույց տալ ձեր հաշվեկշիռը",
    "general.text48": "Հաստատել միջոցները",
    "general.text49": "հստակ միջոցները",
    "general.text50": "հաշիվ",
    "general.text51": "բանկային հաշիվ",
    "general.text52": "վճարում",
    "general.text53": "երկու քայլ",
    "general.text54": "Ձեր",
    "general.text55": "xx",
    "general.text56": "օգտվելու կանոններ",
    "general.text57": "Գաղտնիության քաղաքականություն",
    "": "",
    "toppage.text1": "Ավանդ xlm ձեր Հաշվի ID կրեդիտ քարտով",
    "toppage.text2": "(Եթե դուք չեք ունենա հաշիվ ID- ն, դուք կարող եք առաջացնում Հաշվի ID- օգտագործելով App ժամը StellarSpace)",
    "toppage.text3": "Լեզու",
    "toppage.text4": "փոփոխություն",
    "__1": "",
    "depositForm.text1": "ավանդ հաջողվել",
    "depositForm.text2": "ավանդ ձախողվեց",
    "depositForm.text3": "Վերամշակման ավանդ ...",
    "depositForm.text4": "Ձեր ավանդն ավարտվել հաջողությամբ հետ գործարքի վերաբերյալ ID:",
    "depositForm.text5": "Ձեր ավանդը չի ավարտել հաջողությամբ: Եթե ​​դուք արդեն մեղադրանք, Խնդրում ենք կապնվել աջակցություն է",
    "depositForm.text6": "Ձեր ավանդը մշակվում, եւ մենք փորձում ենք ուղարկել Ձեր դրամական միջոցները:",
    "depositForm.text7": "փակել",
    "depositForm.text8": "ավանդ Քանակը",
    "depositForm.text9": "Հաշվի ID-",
    "depositForm.text10": "Վճարել կրեդիտ քարտով",
    "depositForm.text11": "Ավանդները գանձվում են կրեդիտ քարտի վճար `3.6%, իսկ նվազագույնը 5 ԱՄՆ դոլար ($ 5) գումարը (ավելի քան $ 10 խորհուրդ է տրվում):",
    "depositForm.text12": "Մենք պատասխանատվություն չենք կրում սխալ Հաշվի վկայականների:",
    "depositForm.text13": "Մոտավոր xlm գումարը դուք կունենաք",
    "depositForm.text14": "հիման վրա xlm գնի",
    "depositForm.text15": "գին",
    "depositForm.text16": "թարմացնել",
    "depositForm.text17": "անվավեր Հաշվի ID-",
    "__2": "",
    "stripeCardPay.text1": "փորձեք կրկին",
    "stripeCardPay.text2": "Անցնել քարտային վճարման",
    "stripeCardPay.text3": "Անունը կրեդիտ քարտով",
    "stripeCardPay.text4": "Զրոյացնել միջոցները"
  },
  {
    "LANGUAGE": "Indonesian",
    "CODE": "id",
    "general.text1": "Halo",
    "general.text2": "Terima kasih",
    "general.text3": "Selamat datang",
    "general.text4": "Terima kasih",
    "general.text5": "Bursa Efek",
    "general.text6": "bertukar",
    "general.text7": "dompet",
    "general.text8": "Kirim",
    "general.text9": "mengirim uang",
    "general.text10": "menerima",
    "general.text11": "menerima uang",
    "general.text12": "perdagangan",
    "general.text13": "perdagangan saham",
    "general.text14": "smartphone",
    "general.text15": "di tanganmu",
    "general.text16": "deskripsi",
    "general.text17": "tanggal",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "pasar",
    "general.text21": "informasi",
    "general.text22": "menjual",
    "general.text23": "membeli",
    "general.text24": "transaksi",
    "general.text25": "Tipe",
    "general.text26": "mengelola",
    "general.text27": "kontrol",
    "general.text28": "Cari",
    "general.text29": "xx",
    "general.text30": "saldo",
    "general.text31": "menerima",
    "general.text32": "sejarah",
    "general.text33": "catatan transaksi",
    "general.text34": "xx",
    "general.text35": "tujuan",
    "general.text36": "Pilih",
    "general.text37": "set",
    "general.text38": "aset",
    "general.text39": "memasukkan",
    "general.text40": "memasukkan",
    "general.text41": "jumlah",
    "general.text42": "menemukan jalan",
    "general.text43": "estimasi biaya",
    "general.text44": "biaya",
    "general.text45": "Tidak termasuk",
    "general.text46": "direkomendasikan",
    "general.text47": "menunjukkan saldo Anda",
    "general.text48": "input konfirmasi",
    "general.text49": "input yang jelas",
    "general.text50": "Akun",
    "general.text51": "akun bank",
    "general.text52": "pembayaran",
    "general.text53": "dua langkah",
    "general.text54": "anda",
    "general.text55": "xx",
    "general.text56": "syarat Penggunaan",
    "general.text57": "rahasia pribadi",
    "": "",
    "toppage.text1": "Deposit XLM ke Akun ID Anda dengan kartu kredit",
    "toppage.text2": "(Jika Anda tidak memiliki Akun ID, Anda dapat menghasilkan Akun ID menggunakan App di StellarSpace)",
    "toppage.text3": "Bahasa",
    "toppage.text4": "Perubahan",
    "__1": "",
    "depositForm.text1": "Deposit berhasil",
    "depositForm.text2": "Deposit gagal",
    "depositForm.text3": "Pengolahan Deposit ...",
    "depositForm.text4": "deposit Anda telah berhasil diselesaikan dengan ID transaksi:",
    "depositForm.text5": "deposit Anda tidak selesai dengan sukses. Jika Anda telah didakwa, mohon hubungi dukungan di",
    "depositForm.text6": "deposit Anda sedang diproses dan kami sedang berusaha untuk mengirim dana Anda.",
    "depositForm.text7": "Menutup",
    "depositForm.text8": "Deposit Kuantitas",
    "depositForm.text9": "ID Akun",
    "depositForm.text10": "Bayar dengan Kartu Kredit",
    "depositForm.text11": "Deposito dikenakan biaya kartu kredit 3,6% dan minimum adalah 5 USD ($ 5) jumlah (lebih dari $ 10 dianjurkan).",
    "depositForm.text12": "Kami tidak bertanggung jawab untuk ID account tidak benar.",
    "depositForm.text13": "Perkiraan jumlah XLM Anda akan memiliki",
    "depositForm.text14": "berdasarkan harga XLM",
    "depositForm.text15": "harga",
    "depositForm.text16": "memperbarui",
    "depositForm.text17": "ID Akun valid",
    "__2": "",
    "stripeCardPay.text1": "Coba lagi",
    "stripeCardPay.text2": "Lanjutkan ke pembayaran kartu",
    "stripeCardPay.text3": "nama pada kartu kredit",
    "stripeCardPay.text4": "input ulang"
  },
  {
    "LANGUAGE": "Icelandic",
    "CODE": "is",
    "general.text1": "Halló",
    "general.text2": "Þakka þér fyrir",
    "general.text3": "Velkominn",
    "general.text4": "takk",
    "general.text5": "Kauphöll",
    "general.text6": "skipti",
    "general.text7": "veski",
    "general.text8": "Senda",
    "general.text9": "senda peninga",
    "general.text10": "fá",
    "general.text11": "fá peninga",
    "general.text12": "skipta",
    "general.text13": "Stock Trading",
    "general.text14": "snjallsíminn",
    "general.text15": "í hendi þinni",
    "general.text16": "Lýsing",
    "general.text17": "dagsetning",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "markaðurinn",
    "general.text21": "upplýsingar",
    "general.text22": "selja",
    "general.text23": "kaupa",
    "general.text24": "viðskiptin",
    "general.text25": "gerð",
    "general.text26": "stjórna",
    "general.text27": "stjórn",
    "general.text28": "leita",
    "general.text29": "xx",
    "general.text30": "jafnvægi",
    "general.text31": "taka",
    "general.text32": "Saga",
    "general.text33": "skrá viðskipti",
    "general.text34": "xx",
    "general.text35": "áfangastað",
    "general.text36": "veldu",
    "general.text37": "sett",
    "general.text38": "eign",
    "general.text39": "inntak",
    "general.text40": "koma inn",
    "general.text41": "magn",
    "general.text42": "finna leið",
    "general.text43": "áætlaður kostnaður",
    "general.text44": "gjald",
    "general.text45": "Að frátöldum",
    "general.text46": "mælt",
    "general.text47": "sýna jafnvægi",
    "general.text48": "Staðfesta inntak",
    "general.text49": "skýr inntak",
    "general.text50": "reikningur",
    "general.text51": "bankareikning",
    "general.text52": "greiðsla",
    "general.text53": "tveggja skrefa",
    "general.text54": "þinn",
    "general.text55": "xx",
    "general.text56": "notenda Skilmálar",
    "general.text57": "friðhelgisstefna",
    "": "",
    "toppage.text1": "Innborgun XLM á reikningnum þínum með greiðslukorti",
    "toppage.text2": "(Ef þú ert ekki Account ID, getur þú búið til reikning ID nota App á StellarSpace)",
    "toppage.text3": "Tungumál",
    "toppage.text4": "Breyta",
    "__1": "",
    "depositForm.text1": "innborgun tekist",
    "depositForm.text2": "innborgun mistókst",
    "depositForm.text3": "Vinnslu inná ...",
    "depositForm.text4": "innborgun hefur lokið við viðskipti ID:",
    "depositForm.text5": "Innborgun þinn ekki lokið með góðum árangri. Ef þú hefur verið innheimt, vinsamlegast hafðu samband í",
    "depositForm.text6": "Innborgun Verið er að vinna og við erum að reyna að senda fé þitt.",
    "depositForm.text7": "nærri",
    "depositForm.text8": "innborgun Magn",
    "depositForm.text9": "Reikningur ID",
    "depositForm.text10": "Greiðir með greiðslukorti",
    "depositForm.text11": "Staðfestingargjöld eru innheimt kreditkortaupplýsingarnar gjald um 3,6% og lágmarks er 5 USD ($ 5) upphæð (meira en $ 10 er mælt með).",
    "depositForm.text12": "Við erum ekki ábyrg fyrir rangar auðkenni Reikningur.",
    "depositForm.text13": "Áætlað XLM upphæð sem þú verður að hafa",
    "depositForm.text14": "miðað XLM verð",
    "depositForm.text15": "verð",
    "depositForm.text16": "uppfærsla",
    "depositForm.text17": "Ógilt auðkenni Reikningur",
    "__2": "",
    "stripeCardPay.text1": "Reyndu aftur",
    "stripeCardPay.text2": "Halda áfram að borgun",
    "stripeCardPay.text3": "Nafnið á kreditkortinu",
    "stripeCardPay.text4": "Endurstilla inntak"
  },
  {
    "LANGUAGE": "Italian",
    "CODE": "it",
    "general.text1": "Ciao",
    "general.text2": "Grazie",
    "general.text3": "benvenuto",
    "general.text4": "Grazie",
    "general.text5": "Borsa valori",
    "general.text6": "scambio",
    "general.text7": "portafoglio",
    "general.text8": "Spedire",
    "general.text9": "inviare soldi",
    "general.text10": "ricevere",
    "general.text11": "ricevere denaro",
    "general.text12": "commercio",
    "general.text13": "commercio di azioni",
    "general.text14": "smartphone",
    "general.text15": "nella tua mano",
    "general.text16": "descrizione",
    "general.text17": "Data",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "mercato",
    "general.text21": "informazione",
    "general.text22": "vendere",
    "general.text23": "acquistare",
    "general.text24": "transazione",
    "general.text25": "genere",
    "general.text26": "gestire",
    "general.text27": "controllo",
    "general.text28": "Ricerca",
    "general.text29": "xx",
    "general.text30": "saldi",
    "general.text31": "accettare",
    "general.text32": "storia",
    "general.text33": "record della transazione",
    "general.text34": "xx",
    "general.text35": "destinazione",
    "general.text36": "Selezionare",
    "general.text37": "impostato",
    "general.text38": "risorsa",
    "general.text39": "ingresso",
    "general.text40": "accedere",
    "general.text41": "quantità",
    "general.text42": "trovare il percorso",
    "general.text43": "costo stimato",
    "general.text44": "tassa",
    "general.text45": "esclusa",
    "general.text46": "consigliato",
    "general.text47": "mostrare il proprio equilibrio",
    "general.text48": "Confermare gli input",
    "general.text49": "contribuire chiaramente",
    "general.text50": "account",
    "general.text51": "conto bancario",
    "general.text52": "pagamento",
    "general.text53": "due passi",
    "general.text54": "il tuo",
    "general.text55": "xx",
    "general.text56": "Condizioni d'uso",
    "general.text57": "politica sulla riservatezza",
    "": "",
    "toppage.text1": "Deposito XLM al tuo ID account con carta di credito",
    "toppage.text2": "(Se non si dispone di account ID, è possibile generare account ID utilizzando App a StellarSpace)",
    "toppage.text3": "linguaggio",
    "toppage.text4": "Modificare",
    "__1": "",
    "depositForm.text1": "deposito riuscito",
    "depositForm.text2": "Versamento non riuscito",
    "depositForm.text3": "Elaborazione di deposito ...",
    "depositForm.text4": "Il deposito ha completato con successo con ID di transazione:",
    "depositForm.text5": "Il deposito non è stata completata. Se v'è stato addebitato, per favore contattare l'assistenza all'indirizzo",
    "depositForm.text6": "Il deposito è in fase di elaborazione e stiamo tentando di inviare i fondi.",
    "depositForm.text7": "vicino",
    "depositForm.text8": "deposito Quantità",
    "depositForm.text9": "Account ID",
    "depositForm.text10": "Pagare con la carta di credito",
    "depositForm.text11": "I depositi pagano la tassa di carta di credito del 3,6% e il minimo è di 5 dollari ($ 5) quantità (più di $ 10 è raccomandato).",
    "depositForm.text12": "Noi non siamo responsabili per gli ID di account non corretto.",
    "depositForm.text13": "importo stimato XLM si avrà",
    "depositForm.text14": "in base al prezzo XLM",
    "depositForm.text15": "prezzo",
    "depositForm.text16": "aggiornare",
    "depositForm.text17": "ID account non valido",
    "__2": "",
    "stripeCardPay.text1": "Riprova",
    "stripeCardPay.text2": "Procedere al pagamento con carta",
    "stripeCardPay.text3": "nome sulla carta di credito",
    "stripeCardPay.text4": "ingressi di reset"
  },
  {
    "LANGUAGE": "Japanese",
    "CODE": "ja",
    "general.text1": "こんにちは",
    "general.text2": "ありがとうございました",
    "general.text3": "ようこそ",
    "general.text4": "感謝",
    "general.text5": "証券取引所",
    "general.text6": "両替",
    "general.text7": "財布",
    "general.text8": "送信",
    "general.text9": "送金する",
    "general.text10": "受け取ります",
    "general.text11": "お金を受け取る",
    "general.text12": "取引",
    "general.text13": "株取引",
    "general.text14": "スマートフォン",
    "general.text15": "君の手に",
    "general.text16": "説明",
    "general.text17": "日付",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "市場",
    "general.text21": "情報",
    "general.text22": "売る",
    "general.text23": "購入",
    "general.text24": "トランザクション",
    "general.text25": "タイプ",
    "general.text26": "管理します",
    "general.text27": "コントロール",
    "general.text28": "探す",
    "general.text29": "XX",
    "general.text30": "残高",
    "general.text31": "受け入れます",
    "general.text32": "歴史",
    "general.text33": "取引記録",
    "general.text34": "XX",
    "general.text35": "先",
    "general.text36": "選択する",
    "general.text37": "セットする",
    "general.text38": "資産",
    "general.text39": "入力",
    "general.text40": "入る",
    "general.text41": "量",
    "general.text42": "パスを見つけます",
    "general.text43": "見積もり金額",
    "general.text44": "費用",
    "general.text45": "除きます",
    "general.text46": "推奨",
    "general.text47": "残高を表示",
    "general.text48": "確認入力",
    "general.text49": "クリア入力",
    "general.text50": "アカウント",
    "general.text51": "銀行口座",
    "general.text52": "支払い",
    "general.text53": "2ステップ",
    "general.text54": "きみの",
    "general.text55": "XX",
    "general.text56": "利用規約",
    "general.text57": "個人情報保護方針",
    "": "",
    "toppage.text1": "クレジットカードでのアカウントIDに入金XLM",
    "toppage.text2": "（あなたがアカウントIDをお持ちでない場合は、StellarSpaceでAppを使用してアカウントIDを生成することができます）",
    "toppage.text3": "言語",
    "toppage.text4": "変化する",
    "__1": "",
    "depositForm.text1": "デポジットは成功しました",
    "depositForm.text2": "デポジットが失敗しました",
    "depositForm.text3": "入金の処理...",
    "depositForm.text4": "あなたの預金は、トランザクションIDで正常に完了しました：",
    "depositForm.text5": "あなたの預金は正常に完了しませんでした。あなたが充電されている場合は、接触サポートでを喜ば",
    "depositForm.text6": "あなたの預金が処理されていると、私たちはあなたの資金を送信しようとしています。",
    "depositForm.text7": "閉じる",
    "depositForm.text8": "預金数量",
    "depositForm.text9": "アカウントID",
    "depositForm.text10": "クレジットカードでのお支払い",
    "depositForm.text11": "預金は3.6％のクレジットカードの手数料を請求され、最小値は（推奨される以上$ 10）と5ドル（$ 5）量です。",
    "depositForm.text12": "私たちは、間違ったアカウントIDの責任を負いません。",
    "depositForm.text13": "あなたが持っているだろうと推定XLM量",
    "depositForm.text14": "XLM価格に基づいて",
    "depositForm.text15": "価格",
    "depositForm.text16": "更新",
    "depositForm.text17": "無効なアカウントID",
    "__2": "",
    "stripeCardPay.text1": "再試行",
    "stripeCardPay.text2": "カード決済に進みます",
    "stripeCardPay.text3": "クレジットカードの名義",
    "stripeCardPay.text4": "リセット入力"
  },
  {
    "LANGUAGE": "Georgian",
    "CODE": "ka",
    "general.text1": "გაუმარჯოს",
    "general.text2": "Გმადლობთ",
    "general.text3": "კეთილი",
    "general.text4": "მადლობა",
    "general.text5": "საფონდო ბირჟაზე",
    "general.text6": "გაცვლა",
    "general.text7": "საფულე",
    "general.text8": "send",
    "general.text9": "ფულის გაგზავნა",
    "general.text10": "მიიღეთ",
    "general.text11": "მიიღოს ფული",
    "general.text12": "სავაჭრო",
    "general.text13": "სავაჭრო",
    "general.text14": "სმარტფონი",
    "general.text15": "თქვენს ხელში",
    "general.text16": "აღწერა",
    "general.text17": "თარიღი",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "ბაზარზე",
    "general.text21": "ინფორმაცია",
    "general.text22": "გაყიდვა",
    "general.text23": "ყიდვა",
    "general.text24": "გარიგების",
    "general.text25": "ტიპი",
    "general.text26": "მართვა",
    "general.text27": "კონტროლის",
    "general.text28": "ძებნა",
    "general.text29": "xx",
    "general.text30": "ნაშთები",
    "general.text31": "მიიღოს",
    "general.text32": "ისტორია",
    "general.text33": "გარიგების ჩანაწერი",
    "general.text34": "xx",
    "general.text35": "დანიშნულების",
    "general.text36": "აირჩიეთ",
    "general.text37": "კომპლექტი",
    "general.text38": "აქტივი",
    "general.text39": "input",
    "general.text40": "შესვლის",
    "general.text41": "თანხა",
    "general.text42": "იპოვოს გზა,",
    "general.text43": "სავარაუდო ფასი",
    "general.text44": "საფასური",
    "general.text45": "გარდა",
    "general.text46": "რეკომენდირებული",
    "general.text47": "ნახოთ თქვენი ბალანსი",
    "general.text48": "ადასტურებენ საშუალებებით",
    "general.text49": "ნათელი საშუალებებით",
    "general.text50": "ანგარიშის",
    "general.text51": "საბანკო ანგარიში",
    "general.text52": "გადახდის",
    "general.text53": "ორი ნაბიჯი",
    "general.text54": "თქვენი",
    "general.text55": "xx",
    "general.text56": "მოხმარების პირობები",
    "general.text57": "კონფიდენციალურობის პოლიტიკა",
    "": "",
    "toppage.text1": "ანაბარი xlm თქვენი ანგარიში ID საკრედიტო ბარათი",
    "toppage.text2": "(თუ არ გაქვთ ანგარიში ID, შეგიძლიათ გენერირება ანგარიში ID აპლიკაციის გამოყენებით at StellarSpace)",
    "toppage.text3": "Ენა",
    "toppage.text4": "შეცვლა",
    "__1": "",
    "depositForm.text1": "ანაბარი შეძლო",
    "depositForm.text2": "ანაბარი ვერ",
    "depositForm.text3": "დამუშავების სადეპოზიტო ...",
    "depositForm.text4": "თქვენი ანაბარი წარმატებით დასრულდა გარიგების ID:",
    "depositForm.text5": "თქვენი ანაბარი არ დაასრულებს წარმატებით. თუ თქვენ უკვე ბრალი, გთხოვთ, დაგვიკავშირდეთ მხარდაჭერა",
    "depositForm.text6": "თქვენი ანაბარი მიმდინარეობს და ჩვენ ცდილობს თქვენი თანხები.",
    "depositForm.text7": "ახლოს",
    "depositForm.text8": "ანაბარი რაოდენობა",
    "depositForm.text9": "ანგარიშის ID",
    "depositForm.text10": "საკრედიტო ბარათი",
    "depositForm.text11": "დეპოზიტების ბრალი საკრედიტო ბარათის საფასური 3,6% და მინიმუმ 5 აშშ დოლარს შეადგენს (5 $) თანხის (მეტი $ 10 რეკომენდირებულია).",
    "depositForm.text12": "ჩვენ არ ვართ პასუხისმგებელი არასწორი ანგარიშის პირადობის მოწმობა.",
    "depositForm.text13": "სავარაუდო xlm თანხა თქვენ უნდა",
    "depositForm.text14": "საფუძველზე xlm ფასი",
    "depositForm.text15": "ფასი",
    "depositForm.text16": "განახლება",
    "depositForm.text17": "ანგარიშის არასწორი ID",
    "__2": "",
    "stripeCardPay.text1": "Კიდევ სცადე",
    "stripeCardPay.text2": "გაგრძელება ბარათით",
    "stripeCardPay.text3": "სახელი საკრედიტო ბარათი",
    "stripeCardPay.text4": "გადატვირთვის საშუალებებით"
  },
  {
    "LANGUAGE": "Kazakh",
    "CODE": "kk",
    "general.text1": "Сәлеметсіз бе",
    "general.text2": "Рақмет сізге",
    "general.text3": "Қош келдіңіздер",
    "general.text4": "рахмет",
    "general.text5": "қор биржасы",
    "general.text6": "айырбастау",
    "general.text7": "әмиян",
    "general.text8": "жіберу",
    "general.text9": "жіберу ақша",
    "general.text10": "алу",
    "general.text11": "ақша алуға",
    "general.text12": "сауда-саттық",
    "general.text13": "қор сауда",
    "general.text14": "смартфон",
    "general.text15": "Сіздің қолыңызда",
    "general.text16": "сипаттамасы",
    "general.text17": "дата",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "базар",
    "general.text21": "ақпарат",
    "general.text22": "сату",
    "general.text23": "сатып",
    "general.text24": "мәміле",
    "general.text25": "түрі",
    "general.text26": "басқару",
    "general.text27": "бақылау",
    "general.text28": "іздеу",
    "general.text29": "XX",
    "general.text30": "қалдықтар",
    "general.text31": "қабылдау",
    "general.text32": "Тарих",
    "general.text33": "мәміле рекордтық",
    "general.text34": "XX",
    "general.text35": "тағайындалған орын",
    "general.text36": "таңдау",
    "general.text37": "орнату",
    "general.text38": "актив",
    "general.text39": "енгізу",
    "general.text40": "кіру",
    "general.text41": "сомасы",
    "general.text42": "жолын таба",
    "general.text43": "сметалық құны",
    "general.text44": "төлем",
    "general.text45": "қоспағанда",
    "general.text46": "ұсынылған",
    "general.text47": "Егер тепе-теңдік көрсету",
    "general.text48": "растау кірістері",
    "general.text49": "анық кірістері",
    "general.text50": "шот",
    "general.text51": "банк шоты",
    "general.text52": "төлем",
    "general.text53": "екі қадам",
    "general.text54": "сіздің",
    "general.text55": "XX",
    "general.text56": "қолдану ережелері",
    "general.text57": "Құпиялылық саясаты",
    "": "",
    "toppage.text1": "несие картасымен Сіздің шот идентификаторына депозиттік XLM",
    "toppage.text2": "(Егер сіз Тіркелгі ID жоқ болса, сіз StellarSpace кезінде бағдарламасын қолданып шот ID жасай аласыз)",
    "toppage.text3": "тіл",
    "toppage.text4": "өзгеріс",
    "__1": "",
    "depositForm.text1": "Салым сәтті",
    "depositForm.text2": "Салым сәтсіз",
    "depositForm.text3": "Өңдеу депозиттік ...",
    "depositForm.text4": "Сіздің депозиттік операция идентификаторымен сәтті аяқтады:",
    "depositForm.text5": "Сіздің депозиттік сәтті аяқтау жоқ. Сіз, өтінемін байланыс қолдау зарядталған болса",
    "depositForm.text6": "Сіздің депозиттік өңделеді және біз сіздің қаражат жіберуге тырысады.",
    "depositForm.text7": "жабық",
    "depositForm.text8": "Салым саны",
    "depositForm.text9": "Тіркелгі ID",
    "depositForm.text10": "Несие картасының көмегімен төлеуге",
    "depositForm.text11": "Депозиттер (астам $ 10 ұсынылады) 3,6% кредиттік карточка жарнасын және ең төменгі болып табылады 5 USD ($ 5) сомасын алынады.",
    "depositForm.text12": "Біз дұрыс Тіркелгі идентификаторы үшін жауапты емес.",
    "depositForm.text13": "Сіз болады Болжамды XLM сома",
    "depositForm.text14": "XLM бағасы негізінде",
    "depositForm.text15": "баға",
    "depositForm.text16": "жаңарту",
    "depositForm.text17": "Тіркелгі ID жарамсыз",
    "__2": "",
    "stripeCardPay.text1": "Қайтадан байқап көріңіз",
    "stripeCardPay.text2": "карта төлеу өтіңіз",
    "stripeCardPay.text3": "кредиттік карточка бойынша аты",
    "stripeCardPay.text4": "қалпына келтіру кірістері"
  },
  {
    "LANGUAGE": "Kannada",
    "CODE": "kn",
    "general.text1": "ಹಲೋ",
    "general.text2": "ಧನ್ಯವಾದ",
    "general.text3": "ಸ್ವಾಗತ",
    "general.text4": "ಧನ್ಯವಾದಗಳು",
    "general.text5": "ಸ್ಟಾಕ್ ವಿನಿಮಯ",
    "general.text6": "ವಿನಿಮಯ",
    "general.text7": "ಕೈಚೀಲ",
    "general.text8": "ಕಳುಹಿಸು",
    "general.text9": "ಹಣ ಕಳುಹಿಸು",
    "general.text10": "ಸ್ವೀಕರಿಸಲು",
    "general.text11": "ಹಣ ಸ್ವೀಕರಿಸಲು",
    "general.text12": "ವ್ಯಾಪಾರ",
    "general.text13": "ಷೇರು ವ್ಯವಹಾರ",
    "general.text14": "ಸ್ಮಾರ್ಟ್ಫೋನ್",
    "general.text15": "ನಿಮ್ಮ ಕೈಯಲ್ಲಿ",
    "general.text16": "ವಿವರಣೆ",
    "general.text17": "ದಿನಾಂಕ",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "ಮಾರುಕಟ್ಟೆ",
    "general.text21": "ಮಾಹಿತಿ",
    "general.text22": "ಮಾರಾಟ",
    "general.text23": "ಖರೀದಿ",
    "general.text24": "ವ್ಯವಹಾರ",
    "general.text25": "ಮಾದರಿ",
    "general.text26": "ನಿರ್ವಹಿಸು",
    "general.text27": "ನಿಯಂತ್ರಣ",
    "general.text28": "ಹುಡುಕಿ Kannada",
    "general.text29": "XX",
    "general.text30": "ಬ್ಯಾಲೆನ್ಸ್",
    "general.text31": "ಒಪ್ಪಿಕೊಳ್ಳಿ",
    "general.text32": "ಇತಿಹಾಸ",
    "general.text33": "ವ್ಯವಹಾರ ದಾಖಲೆಯನ್ನು",
    "general.text34": "XX",
    "general.text35": "ತಲುಪುವ ದಾರಿ",
    "general.text36": "ಆಯ್ಕೆ ಮಾಡಿ",
    "general.text37": "ಸೆಟ್",
    "general.text38": "ಆಸ್ತಿ",
    "general.text39": "ಇನ್ಪುಟ್",
    "general.text40": "ನಮೂದಿಸಿ",
    "general.text41": "ಪ್ರಮಾಣದ",
    "general.text42": "ಮಾರ್ಗವನ್ನು ಹೇಗೆ",
    "general.text43": "ಅಂದಾಜು ಬೆಲೆ",
    "general.text44": "ಶುಲ್ಕ",
    "general.text45": "ಹೊರತುಪಡಿಸಿ",
    "general.text46": "ಶಿಫಾರಸು",
    "general.text47": "ನಿಮ್ಮ ಬಾಕಿ ತೋರಿಸಲು",
    "general.text48": "ಖಚಿತಪಡಿಸಿ ಒಳಹರಿವು",
    "general.text49": "ಸ್ಪಷ್ಟ ಒಳಹರಿವು",
    "general.text50": "ಖಾತೆ",
    "general.text51": "ಬ್ಯಾಂಕ್ ಖಾತೆ",
    "general.text52": "ಪಾವತಿ",
    "general.text53": "ಎರಡು ಹಂತದ",
    "general.text54": "ನಿಮ್ಮ",
    "general.text55": "XX",
    "general.text56": "ಬಳಕೆಯ ನಿಯಮಗಳು",
    "general.text57": "ಗೌಪ್ಯತಾ ನೀತಿ",
    "": "",
    "toppage.text1": "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಜೊತೆಗೆ ನಿಮ್ಮ ಖಾತೆ ID ಗೆ ಠೇವಣಿ XLM",
    "toppage.text2": "(ನೀವು ಹೊಂದಿಲ್ಲದಿದ್ದರೆ ಖಾತೆ ID, ನೀವು StellarSpace ನಲ್ಲಿ ಅಪ್ಲಿಕೇಶನ್ ಬಳಸಿಕೊಂಡು ಖಾತೆ ID ಉತ್ಪಾದಿಸುತ್ತದೆ)",
    "toppage.text3": "ಭಾಷಾ",
    "toppage.text4": "ಚೇಂಜ್",
    "__1": "",
    "depositForm.text1": "ಠೇವಣಿ ಯಶಸ್ವಿಯಾದರು",
    "depositForm.text2": "ಠೇವಣಿ ವಿಫಲವಾಗಿದೆ",
    "depositForm.text3": "ಠೇವಣಿ ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲಾಗುತ್ತಿದೆ ...",
    "depositForm.text4": "ನಿಮ್ಮ ಠೇವಣಿ ವ್ಯವಹಾರ ID ಯೊಂದಿಗೆ ಯಶಸ್ವಿಯಾಗಿ ಮುಕ್ತಾಯಗೊಂಡ:",
    "depositForm.text5": "ನಿಮ್ಮ ಠೇವಣಿ ಯಶಸ್ವಿಯಾಗಿ ಪೂರ್ಣಗೊಳಿಸಲಿಲ್ಲ. ನಿಮಗೆ ಶುಲ್ಕ ಮಾಡಲಾಗಿದೆ, ಬೆಂಬಲವನ್ನು ಸಂಪರ್ಕಿಸಿ ದಯವಿಟ್ಟು",
    "depositForm.text6": "ನಿಮ್ಮ ಠೇವಣಿ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿದೆ ಮತ್ತು ನಿಮ್ಮ ನಿಧಿಗಳನ್ನು ಕಳುಹಿಸಬಹುದು ಪ್ರಯತ್ನಿಸುತ್ತಿವೆ.",
    "depositForm.text7": "ನಿಕಟ",
    "depositForm.text8": "ಠೇವಣಿ ಪ್ರಮಾಣ",
    "depositForm.text9": "ಖಾತೆ ID",
    "depositForm.text10": "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಪೇ",
    "depositForm.text11": "ಠೇವಣಿಗಳು 3.6% ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಶುಲ್ಕ ವಿಧಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ಕನಿಷ್ಠ 5 ಡಾಲರ್ ($ 5) ಪ್ರಮಾಣದ (ಹೆಚ್ಚು $ 10 ಸೂಚಿಸಲಾಗುತ್ತದೆ) ಆಗಿದೆ.",
    "depositForm.text12": "ನಾವು ತಪ್ಪಾದ ಖಾತೆ ID ಗಳು ಹೊಣೆಯಲ್ಲ.",
    "depositForm.text13": "ಅಂದಾಜು XLM ಪ್ರಮಾಣವನ್ನು ಹೊಂದಿರುತ್ತದೆ",
    "depositForm.text14": "ಆಧಾರಿತ XLM ಬೆಲೆ",
    "depositForm.text15": "ಬೆಲೆ",
    "depositForm.text16": "ಅಪ್ಡೇಟ್",
    "depositForm.text17": "ಅಮಾನ್ಯ ಖಾತೆ ID",
    "__2": "",
    "stripeCardPay.text1": "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು",
    "stripeCardPay.text2": "ಕಾರ್ಡ್ ಪಾವತಿಯ ಮುಂದುವರೆಯಿರಿ",
    "stripeCardPay.text3": "ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಮೇಲೆ ಹೆಸರು",
    "stripeCardPay.text4": "ರೀಸೆಟ್ ಒಳಹರಿವು"
  },
  {
    "LANGUAGE": "Korean",
    "CODE": "ko",
    "general.text1": "여보세요",
    "general.text2": "감사합니다",
    "general.text3": "어서 오십시오",
    "general.text4": "감사",
    "general.text5": "증권 거래소",
    "general.text6": "교환",
    "general.text7": "지갑",
    "general.text8": "보내다",
    "general.text9": "돈을 보내다",
    "general.text10": "받다",
    "general.text11": "금액 수령",
    "general.text12": "거래",
    "general.text13": "주식 거래",
    "general.text14": "스마트 폰",
    "general.text15": "손에",
    "general.text16": "기술",
    "general.text17": "데이트",
    "general.text18": "배반",
    "general.text19": "배반",
    "general.text20": "시장",
    "general.text21": "정보",
    "general.text22": "팔다",
    "general.text23": "구입",
    "general.text24": "트랜잭션",
    "general.text25": "유형",
    "general.text26": "꾸리다",
    "general.text27": "제어",
    "general.text28": "검색",
    "general.text29": "배반",
    "general.text30": "균형",
    "general.text31": "동의하기",
    "general.text32": "역사",
    "general.text33": "거래 기록",
    "general.text34": "배반",
    "general.text35": "목적지",
    "general.text36": "고르다",
    "general.text37": "세트",
    "general.text38": "유산",
    "general.text39": "입력",
    "general.text40": "시작하다",
    "general.text41": "양",
    "general.text42": "경로를 찾을 수",
    "general.text43": "예상 비용",
    "general.text44": "회비",
    "general.text45": "...을 제외한",
    "general.text46": "추천",
    "general.text47": "균형을 보여",
    "general.text48": "확인 입력",
    "general.text49": "명확 입력",
    "general.text50": "계정",
    "general.text51": "은행 계좌",
    "general.text52": "지불",
    "general.text53": "두 걸음",
    "general.text54": "너의",
    "general.text55": "배반",
    "general.text56": "이용 약관",
    "general.text57": "개인 정보 정책",
    "": "",
    "toppage.text1": "신용 카드로 계정 ID에 입금 XLM",
    "toppage.text2": "(당신이 계정 ID가없는 경우 StellarSpace에서 응용 프로그램을 사용하여 계정 ID를 생성 할 수 있습니다)",
    "toppage.text3": "언어",
    "toppage.text4": "변화",
    "__1": "",
    "depositForm.text1": "예금 성공",
    "depositForm.text2": "입금 실패",
    "depositForm.text3": "보증금을 처리 ...",
    "depositForm.text4": "귀하의 보증금은 트랜잭션 ID와 함께 성공적으로 완료 :",
    "depositForm.text5": "귀하의 보증금은 성공적으로 완료되지 않았습니다. 당신이 청구 된 경우, 접촉 지원에서하시기 바랍니다",
    "depositForm.text6": "귀하의 보증금으로 처리되고 있으며, 우리는 당신의 자금을 전송하려고합니다.",
    "depositForm.text7": "닫기",
    "depositForm.text8": "예금 수량",
    "depositForm.text9": "계정 ID",
    "depositForm.text10": "신용 카드로 지불",
    "depositForm.text11": "예금은 3.6 %의 신용 카드 수수료가 부과되며, 최소 (권장 이상의 $ (10)) 5 달러 ($ 5) 양이다.",
    "depositForm.text12": "우리는 잘못된 계정 ID에 대한 책임을지지 않습니다.",
    "depositForm.text13": "예상 XLM 금액 당신은 할 것",
    "depositForm.text14": "XLM 가격을 기준으로",
    "depositForm.text15": "가격",
    "depositForm.text16": "최신 정보",
    "depositForm.text17": "잘못된 계정 ID",
    "__2": "",
    "stripeCardPay.text1": "다시 시도하십시오",
    "stripeCardPay.text2": "카드 결제로 진행",
    "stripeCardPay.text3": "신용 카드에 이름",
    "stripeCardPay.text4": "리셋 입력"
  },
  {
    "LANGUAGE": "Konkani",
    "CODE": "kok",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "general.text24": "#VALUE!",
    "general.text25": "#VALUE!",
    "general.text26": "#VALUE!",
    "general.text27": "#VALUE!",
    "general.text28": "#VALUE!",
    "general.text29": "#VALUE!",
    "general.text30": "#VALUE!",
    "general.text31": "#VALUE!",
    "general.text32": "#VALUE!",
    "general.text33": "#VALUE!",
    "general.text34": "#VALUE!",
    "general.text35": "#VALUE!",
    "general.text36": "#VALUE!",
    "general.text37": "#VALUE!",
    "general.text38": "#VALUE!",
    "general.text39": "#VALUE!",
    "general.text40": "#VALUE!",
    "general.text41": "#VALUE!",
    "general.text42": "#VALUE!",
    "general.text43": "#VALUE!",
    "general.text44": "#VALUE!",
    "general.text45": "#VALUE!",
    "general.text46": "#VALUE!",
    "general.text47": "#VALUE!",
    "general.text48": "#VALUE!",
    "general.text49": "#VALUE!",
    "general.text50": "#VALUE!",
    "general.text51": "#VALUE!",
    "general.text52": "#VALUE!",
    "general.text53": "#VALUE!",
    "general.text54": "#VALUE!",
    "general.text55": "#VALUE!",
    "general.text56": "#VALUE!",
    "general.text57": "#VALUE!",
    "": "",
    "toppage.text1": "#VALUE!",
    "toppage.text2": "#VALUE!",
    "toppage.text3": "#VALUE!",
    "toppage.text4": "#VALUE!",
    "__1": "",
    "depositForm.text1": "#VALUE!",
    "depositForm.text2": "#VALUE!",
    "depositForm.text3": "#VALUE!",
    "depositForm.text4": "#VALUE!",
    "depositForm.text5": "#VALUE!",
    "depositForm.text6": "#VALUE!",
    "depositForm.text7": "#VALUE!",
    "depositForm.text8": "#VALUE!",
    "depositForm.text9": "#VALUE!",
    "depositForm.text10": "#VALUE!",
    "depositForm.text11": "#VALUE!",
    "depositForm.text12": "#VALUE!",
    "depositForm.text13": "#VALUE!",
    "depositForm.text14": "#VALUE!",
    "depositForm.text15": "#VALUE!",
    "depositForm.text16": "#VALUE!",
    "depositForm.text17": "#VALUE!",
    "__2": "",
    "stripeCardPay.text1": "#VALUE!",
    "stripeCardPay.text2": "#VALUE!",
    "stripeCardPay.text3": "#VALUE!",
    "stripeCardPay.text4": "#VALUE!"
  },
  {
    "LANGUAGE": "Kyrgyz",
    "CODE": "ky",
    "general.text1": "Салам",
    "general.text2": "Рахмат сага",
    "general.text3": "Кош келдиңиз",
    "general.text4": "Рахмат",
    "general.text5": "биржа",
    "general.text6": "алмашуу",
    "general.text7": "акча капчыгы",
    "general.text8": "жөнөтүү",
    "general.text9": "Send акча",
    "general.text10": "кабыл алуу",
    "general.text11": "акча алуу",
    "general.text12": "соода",
    "general.text13": "биржа соода",
    "general.text14": "ташкил",
    "general.text15": "сенин колунда",
    "general.text16": "баяндоо",
    "general.text17": "дата",
    "general.text18": "Сөздүк",
    "general.text19": "Сөздүк",
    "general.text20": "базар",
    "general.text21": "маалымат",
    "general.text22": "сатуу",
    "general.text23": "сатып алуу",
    "general.text24": "бүтүм",
    "general.text25": "түрү",
    "general.text26": "башкаруу",
    "general.text27": "башкаруу",
    "general.text28": "издөө",
    "general.text29": "Сөздүк",
    "general.text30": "калдыктары",
    "general.text31": "кабыл алуу",
    "general.text32": "тарых",
    "general.text33": "бүтүм жазуу",
    "general.text34": "Сөздүк",
    "general.text35": "көздөлгөн",
    "general.text36": "тандоо",
    "general.text37": "белгиленген",
    "general.text38": "мүлк",
    "general.text39": "кирүү",
    "general.text40": "кирүү",
    "general.text41": "суммасы",
    "general.text42": "жолду табышы",
    "general.text43": "баасы",
    "general.text44": "акы",
    "general.text45": "кошпогондо",
    "general.text46": "сунуш",
    "general.text47": "Балансты көрсөтүү",
    "general.text48": "ырастоо салымдар",
    "general.text49": "так салымдар",
    "general.text50": "каттоо эсеби",
    "general.text51": "банк эсеби",
    "general.text52": "төлөө",
    "general.text53": "эки кадам",
    "general.text54": "сенин",
    "general.text55": "Сөздүк",
    "general.text56": "пайдалануу шарттары",
    "general.text57": "купуялуулук саясаты",
    "": "",
    "toppage.text1": "кредиттик карта менен Account ID Депозиттерди XLM",
    "toppage.text2": "(Эгер Каттоо ID жок болсо, анда StellarSpace боюнча колдонмону колдонуу Account ID пайда болот)",
    "toppage.text3": "тил",
    "toppage.text4": "өзгөртүү",
    "__1": "",
    "depositForm.text1": "депозиттик болду",
    "depositForm.text2": "депозит ишке ашкан жок",
    "depositForm.text3": "кенин иштетүү ...",
    "depositForm.text4": "Сиздин аманаттын бүтүм ID менен ийгиликтүү аяктады:",
    "depositForm.text5": "Сиздин кени ийгиликтүү бүтүргөн эмес. Сиз акы алган болсо, байланыш колдоо жагуу",
    "depositForm.text6": "Сиздин аманаттын иштетилүүдө жана биз каражаттарын жөнөтүүгө аракет кылып жатышат.",
    "depositForm.text7": "жакын",
    "depositForm.text8": "депозиттик саны",
    "depositForm.text9": "каттоо номуру",
    "depositForm.text10": "Кредит карта менен төлөө",
    "depositForm.text11": "Депозиттер Кредит карта болуп 3,6% жана минималдуу боюнча төлөм 5 USD ($ 5) суммасы (сунушталат 10 $ ашык) алынат.",
    "depositForm.text12": "Биз туура эмес эсеп ИД үчүн жооптуу эмес.",
    "depositForm.text13": "Болжолдуу XLM суммасы сизде болот",
    "depositForm.text14": "XLM баанын негизинде",
    "depositForm.text15": "баа",
    "depositForm.text16": "өзгөртүү",
    "depositForm.text17": "Каттоо эсеби жараксыз ID",
    "__2": "",
    "stripeCardPay.text1": "Кайра аракет кыл",
    "stripeCardPay.text2": "карта төлөм улантуу",
    "stripeCardPay.text3": "Кредит карта аты",
    "stripeCardPay.text4": "баштапкы абалга салымдар"
  },
  {
    "LANGUAGE": "Lithuanian",
    "CODE": "lt",
    "general.text1": "Sveiki",
    "general.text2": "Ačiū",
    "general.text3": "Sveiki",
    "general.text4": "Dėkoju",
    "general.text5": "biržos",
    "general.text6": "mainai",
    "general.text7": "piniginė",
    "general.text8": "Siųsti",
    "general.text9": "Siųsti pinigus",
    "general.text10": "gauti",
    "general.text11": "gauti pinigus",
    "general.text12": "prekybos",
    "general.text13": "Stock Trading",
    "general.text14": "smartfon",
    "general.text15": "rankoje",
    "general.text16": "apibūdinimas",
    "general.text17": "data",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "turgus",
    "general.text21": "informacija",
    "general.text22": "parduoti",
    "general.text23": "Pirkti",
    "general.text24": "sandoris",
    "general.text25": "tipas",
    "general.text26": "valdyti",
    "general.text27": "kontrolė",
    "general.text28": "Paieška",
    "general.text29": "XX",
    "general.text30": "likučiai",
    "general.text31": "priimti",
    "general.text32": "istorija",
    "general.text33": "sandoris įrašas",
    "general.text34": "XX",
    "general.text35": "Kelionės tikslas",
    "general.text36": "pasirinkti",
    "general.text37": "rinkinys",
    "general.text38": "turtas",
    "general.text39": "sąnaudos",
    "general.text40": "įeiti",
    "general.text41": "suma",
    "general.text42": "rasti kelią",
    "general.text43": "numatoma kaina",
    "general.text44": "rinkliava",
    "general.text45": "išskiriant",
    "general.text46": "Rekomenduojamas",
    "general.text47": "parodyti savo balansą",
    "general.text48": "Patvirtinti įėjimai",
    "general.text49": "aiškūs įėjimai",
    "general.text50": "sąskaita",
    "general.text51": "banko sąskaita",
    "general.text52": "mokėjimas",
    "general.text53": "dviejų etapų",
    "general.text54": "tavo",
    "general.text55": "XX",
    "general.text56": "Naudojimo sąlygos",
    "general.text57": "Privatumo politika",
    "": "",
    "toppage.text1": "Užstatas XLM savo paskyros ID su kreditine kortele",
    "toppage.text2": "(Jei neturite sąskaitos ID, galite sukurti paskyros ID naudojant App ne StellarSpace)",
    "toppage.text3": "kalba",
    "toppage.text4": "pokytis",
    "__1": "",
    "depositForm.text1": "užstatas pavyko",
    "depositForm.text2": "užstatas nepavyko",
    "depositForm.text3": "Perdirbimo užstatą ...",
    "depositForm.text4": "Jūsų indėlis sėkmingai baigė su sandoriu ID:",
    "depositForm.text5": "Jūsų indėlis nebuvo baigti sėkmingai. Jei buvo kaltinamas, kreipkitės į paramą",
    "depositForm.text6": "Jūsų indėlis yra tvarkomi, ir mes bando siųsti Jūsų lėšas.",
    "depositForm.text7": "Uždaryti",
    "depositForm.text8": "užstatas Kiekis",
    "depositForm.text9": "Sąskaitos identifikavimo kodas",
    "depositForm.text10": "Mokėti kreditine kortele",
    "depositForm.text11": "Užstatai mokami kredito kortelės mokestis 3,6% ir mažiausia yra 5 USD ($ 5) suma (daugiau nei 10 $ yra rekomenduojama).",
    "depositForm.text12": "Mes nesame atsakingi už neteisingais ID.",
    "depositForm.text13": "Numatomas XLM suma, kurią turės",
    "depositForm.text14": "remiantis XLM kainą",
    "depositForm.text15": "kaina",
    "depositForm.text16": "atnaujinimas",
    "depositForm.text17": "neteisingas Sąskaitos identifikavimo kodas",
    "__2": "",
    "stripeCardPay.text1": "Bandyk iš naujo",
    "stripeCardPay.text2": "Pereikite prie mokėjimo kortele",
    "stripeCardPay.text3": "vardas ant kreditinės kortelės",
    "stripeCardPay.text4": "reset įėjimai"
  },
  {
    "LANGUAGE": "Latvian",
    "CODE": "lv",
    "general.text1": "Sveiki",
    "general.text2": "Paldies",
    "general.text3": "gaidīts",
    "general.text4": "Paldies",
    "general.text5": "birža",
    "general.text6": "maiņa",
    "general.text7": "maks",
    "general.text8": "sūtīt",
    "general.text9": "sūtīt naudu",
    "general.text10": "saņemt",
    "general.text11": "saņemt naudu",
    "general.text12": "tirdzniecība",
    "general.text13": "akciju tirdzniecību",
    "general.text14": "viedtālrunis",
    "general.text15": "rokā",
    "general.text16": "apraksts",
    "general.text17": "datums",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "tirgus",
    "general.text21": "informācija",
    "general.text22": "pārdot",
    "general.text23": "nopirkt",
    "general.text24": "darījums",
    "general.text25": "tips",
    "general.text26": "pārvaldīt",
    "general.text27": "kontrole",
    "general.text28": "Meklēt",
    "general.text29": "xx",
    "general.text30": "atlikumi",
    "general.text31": "akceptēt",
    "general.text32": "vēsture",
    "general.text33": "darījums ieraksts",
    "general.text34": "xx",
    "general.text35": "galamērķis",
    "general.text36": "atlasīt",
    "general.text37": "komplekts",
    "general.text38": "aktīvs",
    "general.text39": "ievade",
    "general.text40": "ieiet",
    "general.text41": "summa",
    "general.text42": "atrast ceļu",
    "general.text43": "paredzamās izmaksas",
    "general.text44": "maksa",
    "general.text45": "Bez",
    "general.text46": "ieteicams",
    "general.text47": "parādīt savu bilanci",
    "general.text48": "Apstiprināt ievadi",
    "general.text49": "skaidri izejvielas",
    "general.text50": "konts",
    "general.text51": "bankas konts",
    "general.text52": "maksājums",
    "general.text53": "divi solis",
    "general.text54": "jūsu",
    "general.text55": "xx",
    "general.text56": "lietošanas noteikumi",
    "general.text57": "Privātuma politika",
    "": "",
    "toppage.text1": "Noguldījumu XLM jūsu konta ID ar kredītkarti",
    "toppage.text2": "(Ja jums nav konta ID, jūs varat radīt konta ID, izmantojot App at StellarSpace)",
    "toppage.text3": "valoda",
    "toppage.text4": "maiņa",
    "__1": "",
    "depositForm.text1": "depozīts izdevās",
    "depositForm.text2": "depozīts neizdevās",
    "depositForm.text3": "Apstrādes depozītu ...",
    "depositForm.text4": "Jūsu depozīts ir veiksmīgi pabeigts ar darījuma ID:",
    "depositForm.text5": "Jūsu depozīta netika pabeigta veiksmīgi. Ja jums ir bijis jāmaksā, lūdzu, sazinieties ar klientu atbalstu",
    "depositForm.text6": "Jūsu noguldījums tiek apstrādāti un mēs cenšamies, lai nosūtītu savus līdzekļus.",
    "depositForm.text7": "aizvērt",
    "depositForm.text8": "noguldījumu apjoms",
    "depositForm.text9": "Konta ID",
    "depositForm.text10": "Maksāt ar kredītkarti",
    "depositForm.text11": "Noguldījumi ir jāmaksā kredītkartes maksa par 3.6%, un minimālais ir 5 ASV dolāru ($ 5) summu (vairāk nekā $ 10, ir ieteicams).",
    "depositForm.text12": "Mēs neesam atbildīgi par nepareizu konta ID.",
    "depositForm.text13": "Aprēķinātā XLM summa, kas jums būs",
    "depositForm.text14": "pamatojoties uz XLM cenu",
    "depositForm.text15": "cena",
    "depositForm.text16": "Atjaunināt",
    "depositForm.text17": "nederīga konta ID",
    "__2": "",
    "stripeCardPay.text1": "Mēģini vēlreiz",
    "stripeCardPay.text2": "Pāriet uz apmaksu karti",
    "stripeCardPay.text3": "vārds uz kredītkartes",
    "stripeCardPay.text4": "atiestatīt izejvielas"
  },
  {
    "LANGUAGE": "Maori",
    "CODE": "mi",
    "general.text1": "Hello",
    "general.text2": "Mauruuru koe",
    "general.text3": "Nau mai",
    "general.text4": "Ngā mihi",
    "general.text5": "utu kararehe",
    "general.text6": "whakawhiti",
    "general.text7": "putea",
    "general.text8": "tuku",
    "general.text9": "moni tuku",
    "general.text10": "riro",
    "general.text11": "riro moni",
    "general.text12": "tauhokohoko",
    "general.text13": "hokohoko kararehe",
    "general.text14": "atamai",
    "general.text15": "i roto i tou ringa",
    "general.text16": "whakaahuatanga",
    "general.text17": "rā",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "mākete",
    "general.text21": "mōhiohio",
    "general.text22": "hokona",
    "general.text23": "utu",
    "general.text24": "tauwhitinga",
    "general.text25": "momo",
    "general.text26": "whakahaere",
    "general.text27": "mana",
    "general.text28": "rapu",
    "general.text29": "xx",
    "general.text30": "pauna",
    "general.text31": "manako",
    "general.text32": "hītori",
    "general.text33": "record tauwhitinga",
    "general.text34": "xx",
    "general.text35": "ūnga",
    "general.text36": "tīpako",
    "general.text37": "huinga",
    "general.text38": "rawa",
    "general.text39": "tāuru",
    "general.text40": "tomo",
    "general.text41": "nui",
    "general.text42": "kitea ara",
    "general.text43": "utu tata",
    "general.text44": "utu",
    "general.text45": "hāunga",
    "general.text46": "tūtohu",
    "general.text47": "whakaatu i tō toenga",
    "general.text48": "kōkuhunga Whakaū",
    "general.text49": "kōkuhunga mārama",
    "general.text50": "pūkete",
    "general.text51": "pūkete pēke",
    "general.text52": "utu",
    "general.text53": "e rua taahiraa",
    "general.text54": "koutou",
    "general.text55": "xx",
    "general.text56": "ngā o whakamahi",
    "general.text57": "Kaupapahere tūmataiti",
    "": "",
    "toppage.text1": "Whakatakoto XLM ki to koutou ID Pūkete ki te kāri nama",
    "toppage.text2": "(Ki te kore koe e whai Pūkete ID, ka taea e koe te whakaputa Pūkete ID whakamahi App i StellarSpace)",
    "toppage.text3": "reo",
    "toppage.text4": "Huri",
    "__1": "",
    "depositForm.text1": "muri whakatakoto",
    "depositForm.text2": "rahua whakatakoto",
    "depositForm.text3": "Processing tāpui ...",
    "depositForm.text4": "Kua oti tō moni tāpui pai ki ID tauwhitinga:",
    "depositForm.text5": "kihai i tutuki pai tō moni tāpui. Ki te mea kua oti whakatupato koe, pai tautoko whakapā i",
    "depositForm.text6": "Kei te tukatuka tō moni tāpui me e ngana matou ki te unga o koutou moni.",
    "depositForm.text7": "tata",
    "depositForm.text8": "whakatakoto Rahi",
    "depositForm.text9": "ID pūkete",
    "depositForm.text10": "Utu ki Kāri Nama",
    "depositForm.text11": "E whakatupato putunga te utu kāri nama o 3.6%, me te iti rawa, ko te 5 USD ($ 5) nui (neke atu i te $ 10 e tūtohu).",
    "depositForm.text12": "E kore e matou kawenga mō TT Pūkete hē.",
    "depositForm.text13": "nui XLM tata e whai koe",
    "depositForm.text14": "e hāngai ana i runga i XLM utu",
    "depositForm.text15": "utu",
    "depositForm.text16": "whakahou",
    "depositForm.text17": "ID Pūkete muhu",
    "__2": "",
    "stripeCardPay.text1": "Whakamātauria anō",
    "stripeCardPay.text2": "Puta ki te utu kāri",
    "stripeCardPay.text3": "Ingoa i runga kāri nama",
    "stripeCardPay.text4": "kōkuhunga tautuhi"
  },
  {
    "LANGUAGE": "FYRO Macedonian",
    "CODE": "mk",
    "general.text1": "Здраво",
    "general.text2": "Ви благодарам",
    "general.text3": "Добредојдовте",
    "general.text4": "благодарение",
    "general.text5": "берза",
    "general.text6": "размена",
    "general.text7": "паричник",
    "general.text8": "испрати",
    "general.text9": "Прати пари",
    "general.text10": "добиваат",
    "general.text11": "добијат пари",
    "general.text12": "тргување",
    "general.text13": "тргување",
    "general.text14": "паметен телефон",
    "general.text15": "во вашата рака",
    "general.text16": "опис",
    "general.text17": "датум",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "пазарот",
    "general.text21": "информации",
    "general.text22": "продажба",
    "general.text23": "купување",
    "general.text24": "трансакција",
    "general.text25": "тип",
    "general.text26": "управување со",
    "general.text27": "контрола",
    "general.text28": "Барај",
    "general.text29": "xx",
    "general.text30": "салда",
    "general.text31": "прифати",
    "general.text32": "историја",
    "general.text33": "рекорд трансакција",
    "general.text34": "xx",
    "general.text35": "дестинација",
    "general.text36": "изберете",
    "general.text37": "сет",
    "general.text38": "средства",
    "general.text39": "влез",
    "general.text40": "внесете",
    "general.text41": "износ",
    "general.text42": "најде патот",
    "general.text43": "проценетите трошоци",
    "general.text44": "надомест",
    "general.text45": "Со исклучок на",
    "general.text46": "препорачува",
    "general.text47": "покаже својата рамнотежа",
    "general.text48": "потврди влезови",
    "general.text49": "јасно влезови",
    "general.text50": "сметка",
    "general.text51": "банкарска сметка",
    "general.text52": "плаќање",
    "general.text53": "два чекора",
    "general.text54": "вашиот",
    "general.text55": "xx",
    "general.text56": "Услови на употреба",
    "general.text57": "политика за приватност",
    "": "",
    "toppage.text1": "XLM депозит на вашата сметка проект со кредитна картичка",
    "toppage.text2": "(Ако немате сметка проект, може да генерира сметка проект со помош на апликацијата на StellarSpace)",
    "toppage.text3": "Јазик",
    "toppage.text4": "промена",
    "__1": "",
    "depositForm.text1": "депозит успеа",
    "depositForm.text2": "депозит не успеа",
    "depositForm.text3": "Обработка на депозит ...",
    "depositForm.text4": "Вашиот депозит заврши успешно со трансакција проект:",
    "depositForm.text5": "Вашиот депозит не заврши успешно. Ако сте биле обвинети, ве молиме контактирајте со поддршка на",
    "depositForm.text6": "Вашиот депозит се обработува и се обидува да ги испрати Вашите средства.",
    "depositForm.text7": "во близина",
    "depositForm.text8": "депозит Кол",
    "depositForm.text9": "сметка проект",
    "depositForm.text10": "Плати со кредитна картичка",
    "depositForm.text11": "Депозитите се наплаќа надомест за кредитна картичка од 3,6%, а минимумот е 5 долари ($ 5) износ (повеќе од $ 10 се препорачува).",
    "depositForm.text12": "Ние не сме одговорни за лични карти неточни профил.",
    "depositForm.text13": "Проценето XLM износот што ќе треба",
    "depositForm.text14": "врз основа на XLM цена",
    "depositForm.text15": "цена",
    "depositForm.text16": "ажурирање",
    "depositForm.text17": "Погрешен ID на сметка",
    "__2": "",
    "stripeCardPay.text1": "Обиди се повторно",
    "stripeCardPay.text2": "Продолжете со платежна картичка",
    "stripeCardPay.text3": "Име на кредитна картичка",
    "stripeCardPay.text4": "ресетирање влезови"
  },
  {
    "LANGUAGE": "Mongolian",
    "CODE": "mn",
    "general.text1": "Сайн уу",
    "general.text2": "Баярлалаа",
    "general.text3": "тавтай морилно уу",
    "general.text4": "баярлалаа",
    "general.text5": "хөрөнгийн бирж",
    "general.text6": "валютын",
    "general.text7": "түрийвч",
    "general.text8": "илгээх",
    "general.text9": "илгээх мөнгө",
    "general.text10": "хүлээн авах",
    "general.text11": "мөнгө хүлээн авах",
    "general.text12": "арилжаа",
    "general.text13": "үнэт цаасны арилжаа",
    "general.text14": "ухаалаг гар утас",
    "general.text15": "таны гарт",
    "general.text16": "тодорхойлолт",
    "general.text17": "он сар өдөр",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "зах зээлийн",
    "general.text21": "мэдээлэл",
    "general.text22": "худалдах",
    "general.text23": "худалдан авах",
    "general.text24": "ажил гүйлгээ",
    "general.text25": "төрөл",
    "general.text26": "удирдах",
    "general.text27": "хяналтын",
    "general.text28": "хайх",
    "general.text29": "XX",
    "general.text30": "үлдэгдэл",
    "general.text31": "хүлээн авах",
    "general.text32": "түүх",
    "general.text33": "гүйлгээний тайлан",
    "general.text34": "XX",
    "general.text35": "очих газар",
    "general.text36": "сонгох",
    "general.text37": "багц",
    "general.text38": "хөрөнгө",
    "general.text39": "оруулах",
    "general.text40": "оруулах",
    "general.text41": "хэмжээ",
    "general.text42": "замыг олох",
    "general.text43": "тооцоолсон зардал",
    "general.text44": "хураамж",
    "general.text45": "бусад",
    "general.text46": "зөвлөж байна",
    "general.text47": "Таны тэнцвэрт байдлыг харуулах",
    "general.text48": "баталгаа орц",
    "general.text49": "тодорхой орц",
    "general.text50": "данс",
    "general.text51": "банкны данс",
    "general.text52": "төлбөрийн",
    "general.text53": "хоёр алхам",
    "general.text54": "таны",
    "general.text55": "XX",
    "general.text56": "ашиглах нөхцөл",
    "general.text57": "Хувийн мэдээллийн талаарх Баримтлал",
    "": "",
    "toppage.text1": "Зээлийн карт нь таны Дансны ID тулд Орд XLM",
    "toppage.text2": "(Хэрэв та Дансны ID байхгүй бол, та StellarSpace үед програмыг ашиглан Дансны ID үүсгэж болно)",
    "toppage.text3": "хэл",
    "toppage.text4": "өөрчлөлт",
    "__1": "",
    "depositForm.text1": "Хадгаламжийн амжилттай",
    "depositForm.text2": "Хадгаламжийн чадсангүй",
    "depositForm.text3": "орд боловсруулж байна ...",
    "depositForm.text4": "Таны хадгаламжийн гүйлгээ ID амжилттай дууссан байна:",
    "depositForm.text5": "Таны орд амжилттай дуусгаж чадаагүй. Та цэнэглэж байгаа бол, холбоо барих дэмжлэг уу",
    "depositForm.text6": "Таны орд боловсруулж, бид таны мөнгийг илгээх оролдож байна байна.",
    "depositForm.text7": "нууц",
    "depositForm.text8": "Хадгаламжийн хэмжээ",
    "depositForm.text9": "Дансны ID",
    "depositForm.text10": "Кредит карт нь Pay",
    "depositForm.text11": "Хадгаламж 3.6% нь зээлийн картын хураамж бөгөөд хамгийн бага нь 5 доллар ($ 5) хэмжээ (-аас дээш 10 $ санал болгож байна) юм.",
    "depositForm.text12": "Бид буруу Дансны ID-хариуцлага хүлээхгүй болно.",
    "depositForm.text13": "Тооцоолсон XLM хэмжээ та байх болно",
    "depositForm.text14": "XLM үнэ дээр суурилсан",
    "depositForm.text15": "Үнэ",
    "depositForm.text16": "шинэчлэх",
    "depositForm.text17": "хүчин төгөлдөр бус Дансны ID",
    "__2": "",
    "stripeCardPay.text1": "Дахин оролд",
    "stripeCardPay.text2": "карт төлбөрийн үргэлжлүүлнэ",
    "stripeCardPay.text3": "кредит картан дээр нэр",
    "stripeCardPay.text4": "дахин орц"
  },
  {
    "LANGUAGE": "Marathi",
    "CODE": "mr",
    "general.text1": "नमस्कार",
    "general.text2": "धन्यवाद",
    "general.text3": "आपले स्वागत आहे",
    "general.text4": "धन्यवाद",
    "general.text5": "स्टॉक एक्स्चेंज",
    "general.text6": "विनिमय",
    "general.text7": "पाकीट",
    "general.text8": "पाठवा",
    "general.text9": "पैसे पाठवा",
    "general.text10": "प्राप्त",
    "general.text11": "पैसे प्राप्त",
    "general.text12": "ट्रेडिंग",
    "general.text13": "स्टॉक ट्रेडिंग",
    "general.text14": "स्मार्टफोन",
    "general.text15": "आपल्या हातात",
    "general.text16": "वर्णन",
    "general.text17": "तारीख",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "बाजार",
    "general.text21": "माहिती",
    "general.text22": "विक्री",
    "general.text23": "खरेदी",
    "general.text24": "व्यवहार",
    "general.text25": "प्रकार",
    "general.text26": "व्यवस्थापित",
    "general.text27": "नियंत्रण",
    "general.text28": "शोधा",
    "general.text29": "xx",
    "general.text30": "शिल्लक",
    "general.text31": "स्वीकार",
    "general.text32": "इतिहास",
    "general.text33": "व्यवहार रेकॉर्ड",
    "general.text34": "xx",
    "general.text35": "गंतव्य",
    "general.text36": "निवडा",
    "general.text37": "संच",
    "general.text38": "मालमत्ता",
    "general.text39": "इनपुट",
    "general.text40": "प्रविष्ट",
    "general.text41": "रक्कम",
    "general.text42": "पथ शोधणे",
    "general.text43": "अंदाजे किंमत",
    "general.text44": "शुल्क",
    "general.text45": "वगळल्यामुळे",
    "general.text46": "शिफारस",
    "general.text47": "तुमची शिल्लक दाखवा",
    "general.text48": "पुष्टी इनपुट",
    "general.text49": "स्पष्ट इनपुट",
    "general.text50": "खाते",
    "general.text51": "बँक खाते",
    "general.text52": "पैसे",
    "general.text53": "दोन पाऊल",
    "general.text54": "आपल्या",
    "general.text55": "xx",
    "general.text56": "वापरण्याच्या अटी",
    "general.text57": "गोपनीयता धोरण",
    "": "",
    "toppage.text1": "ठेव XLM आपले खाते ID वर क्रेडिट कार्ड",
    "toppage.text2": "(आपण खाते आयडी नसेल तर, आपण StellarSpace अॅप वापरून खाते आयडी निर्माण करू शकता)",
    "toppage.text3": "इंग्रजी",
    "toppage.text4": "बदला",
    "__1": "",
    "depositForm.text1": "ठेव यशस्वी",
    "depositForm.text2": "ठेव अयशस्वी",
    "depositForm.text3": "ठेव प्रक्रिया करीत आहे ...",
    "depositForm.text4": "आपले ठेव व्यवहार ID सह यशस्वीरित्या पूर्ण:",
    "depositForm.text5": "आपले ठेव यशस्वीरित्या पूर्ण नाही. आपण आरोप ठेवण्यात आले आहेत तर, संपर्क समर्थन संतुष्ट",
    "depositForm.text6": "आपले ठेव प्रक्रिया केली जात आहे आणि आम्ही आपल्या निधी पाठविण्याचा प्रयत्न करीत आहेत.",
    "depositForm.text7": "बंद",
    "depositForm.text8": "ठेव प्रमाण",
    "depositForm.text9": "खाते आयडी",
    "depositForm.text10": "क्रेडिट कार्ड द्या",
    "depositForm.text11": "ठेवी 3.6% क्रेडिट कार्ड फी आकारली जाते आणि कमीत कमी 5 डॉलर्स ($ 5) रक्कम (पेक्षा अधिक $ 10 शिफारसीय आहे) आहे.",
    "depositForm.text12": "आम्ही चुकीच्या खाते आयडी जबाबदार नाहीत.",
    "depositForm.text13": "अंदाजे XLM रक्कम आपण लागेल",
    "depositForm.text14": "XLM किमतीवर आधारित",
    "depositForm.text15": "किंमत",
    "depositForm.text16": "सुधारणा",
    "depositForm.text17": "अवैध खाते ID",
    "__2": "",
    "stripeCardPay.text1": "पुन्हा प्रयत्न करा",
    "stripeCardPay.text2": "कार्ड पेमेंट करण्यासाठी पुढे जा",
    "stripeCardPay.text3": "क्रेडीट कार्डावरील नाव",
    "stripeCardPay.text4": "रीसेट इनपुट"
  },
  {
    "LANGUAGE": "Malay",
    "CODE": "ms",
    "general.text1": "Hello",
    "general.text2": "Terima kasih",
    "general.text3": "Selamat datang",
    "general.text4": "terima kasih",
    "general.text5": "pertukaran stok",
    "general.text6": "pertukaran",
    "general.text7": "wallet",
    "general.text8": "send",
    "general.text9": "hantar duit",
    "general.text10": "menerima",
    "general.text11": "menerima wang",
    "general.text12": "perdagangan",
    "general.text13": "perdagangan saham",
    "general.text14": "telefon pintar",
    "general.text15": "di tangan anda",
    "general.text16": "Huraian",
    "general.text17": "Tarikh",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "pasaran",
    "general.text21": "maklumat",
    "general.text22": "jual",
    "general.text23": "beli",
    "general.text24": "transaksi",
    "general.text25": "jenis",
    "general.text26": "mengurus",
    "general.text27": "kawalan",
    "general.text28": "cari",
    "general.text29": "xx",
    "general.text30": "baki",
    "general.text31": "menerima",
    "general.text32": "sejarah",
    "general.text33": "catatan urus niaga",
    "general.text34": "xx",
    "general.text35": "destinasi",
    "general.text36": "pilih",
    "general.text37": "set",
    "general.text38": "aset",
    "general.text39": "input",
    "general.text40": "masukkan",
    "general.text41": "jumlah",
    "general.text42": "mencari jalan",
    "general.text43": "anggaran kos",
    "general.text44": "Bayaran",
    "general.text45": "Tidak termasuk",
    "general.text46": "disyorkan",
    "general.text47": "menunjukkan baki anda",
    "general.text48": "input confirm",
    "general.text49": "input jelas",
    "general.text50": "akaun",
    "general.text51": "akaun bank",
    "general.text52": "pembayaran",
    "general.text53": "dua langkah",
    "general.text54": "anda",
    "general.text55": "xx",
    "general.text56": "syarat-syarat penggunaan",
    "general.text57": "dasar privasi",
    "": "",
    "toppage.text1": "Deposit XLM untuk ID Akaun anda dengan kad kredit",
    "toppage.text2": "(Jika anda tidak mempunyai Akaun ID, anda boleh menjana Akaun ID menggunakan App di StellarSpace)",
    "toppage.text3": "bahasa",
    "toppage.text4": "Ubah",
    "__1": "",
    "depositForm.text1": "deposit berjaya",
    "depositForm.text2": "deposit gagal",
    "depositForm.text3": "Memproses deposit ...",
    "depositForm.text4": "deposit anda selesai dengan jayanya dengan ID transaksi:",
    "depositForm.text5": "deposit anda tidak berjaya diselesaikan. Jika anda telah didakwa, memudahkan hubungi sokongan di",
    "depositForm.text6": "deposit anda sedang diproses dan kami cuba untuk menghantar dana anda.",
    "depositForm.text7": "berhampiran",
    "depositForm.text8": "deposit Kuantiti",
    "depositForm.text9": "ID Akaun",
    "depositForm.text10": "Bayar dengan Kad Kredit",
    "depositForm.text11": "Deposit yang dikenakan kad kredit sebanyak 3.6% dan minimum adalah 5 USD ($ 5) jumlah (lebih daripada $ 10 adalah disyorkan).",
    "depositForm.text12": "Kami tidak bertanggungjawab untuk ID account tidak benar.",
    "depositForm.text13": "jumlah anggaran XLM anda akan mempunyai",
    "depositForm.text14": "berdasarkan harga XLM",
    "depositForm.text15": "harga",
    "depositForm.text16": "maklumat",
    "depositForm.text17": "ID Akaun yang tidak sah",
    "__2": "",
    "stripeCardPay.text1": "Cuba lagi",
    "stripeCardPay.text2": "Meneruskan pembayaran kad",
    "stripeCardPay.text3": "nama pada kad kredit",
    "stripeCardPay.text4": "input semula"
  },
  {
    "LANGUAGE": "Maltese",
    "CODE": "mt",
    "general.text1": "Bongu",
    "general.text2": "Grazzi",
    "general.text3": "Merħba",
    "general.text4": "grazzi",
    "general.text5": "borża",
    "general.text6": "skambju",
    "general.text7": "kartiera",
    "general.text8": "Ibgħat",
    "general.text9": "flus jibgħat",
    "general.text10": "jirċievu",
    "general.text11": "jirċievu l-flus",
    "general.text12": "kummerċ",
    "general.text13": "stock għall-kummerċ",
    "general.text14": "smartphone",
    "general.text15": "f'idejk",
    "general.text16": "deskrizzjoni",
    "general.text17": "data",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "suq",
    "general.text21": "informazzjoni",
    "general.text22": "jbiegħu",
    "general.text23": "jixtru",
    "general.text24": "transazzjoni",
    "general.text25": "tip",
    "general.text26": "jamministraw",
    "general.text27": "kontroll",
    "general.text28": "Fittex",
    "general.text29": "xx",
    "general.text30": "bilanċi",
    "general.text31": "jaċċettaw",
    "general.text32": "istorja",
    "general.text33": "rekord transazzjoni",
    "general.text34": "xx",
    "general.text35": "destinazzjoni",
    "general.text36": "tagħżel",
    "general.text37": "sett",
    "general.text38": "assi",
    "general.text39": "input",
    "general.text40": "jidħol",
    "general.text41": "ammont",
    "general.text42": "isibu triq",
    "general.text43": "ispiża stmata",
    "general.text44": "miżata",
    "general.text45": "Esklużi",
    "general.text46": "irrakkomandat",
    "general.text47": "juru bilanċ tiegħek",
    "general.text48": "inputs jikkonfermaw",
    "general.text49": "inputs ċari",
    "general.text50": "kont",
    "general.text51": "kont bankarju",
    "general.text52": "ħlas",
    "general.text53": "żewġ stadji",
    "general.text54": "tiegħek",
    "general.text55": "xx",
    "general.text56": "termini ta 'Użu",
    "general.text57": "regoli tal-privatezza",
    "": "",
    "toppage.text1": "Depożitu XLM Kont ID tiegħek ma 'karta ta' kreditu",
    "toppage.text2": "(Jekk inti ma għandekx kont identità, inti tista 'tiġġenera Kont ID jużaw App fuq StellarSpace)",
    "toppage.text3": "lingwa",
    "toppage.text4": "bidla",
    "__1": "",
    "depositForm.text1": "depożitu irnexxielu",
    "depositForm.text2": "depożitu naqset",
    "depositForm.text3": "Ipproċessar depożitu ...",
    "depositForm.text4": "depożitu tiegħek temm b'suċċess ma ID transazzjoni:",
    "depositForm.text5": "depożitu tiegħek ma lestewx b'suċċess. Jekk ġejt mitluba, jekk jogħġbok appoġġ kuntatt fil",
    "depositForm.text6": "depożitu tiegħek tkun qed tiġi pproċessata u aħna qed jippruvaw jibagħtu fondi tiegħek.",
    "depositForm.text7": "qrib",
    "depositForm.text8": "depożitu Kwantità",
    "depositForm.text9": "kont ID",
    "depositForm.text10": "Pay bil Credit Card",
    "depositForm.text11": "Depożiti huma mitluba l-ħlas b'karta ta 'kreditu ta' 3.6% u l-minimu huwa 5 USD ($ 5) ammont (aktar minn $ 10 huwa rakkomandat).",
    "depositForm.text12": "Aħna mhux responsabbli għall IDs Kont żbaljata.",
    "depositForm.text13": "Ammont XLM stmat li inti ser ikollok",
    "depositForm.text14": "ibbażat fuq il-prezz XLM",
    "depositForm.text15": "prezz",
    "depositForm.text16": "aġġornament",
    "depositForm.text17": "invalidu Kont ID",
    "__2": "",
    "stripeCardPay.text1": "Erġa 'pprova",
    "stripeCardPay.text2": "Ipproċedi għal ħlas bil-kard",
    "stripeCardPay.text3": "Isem fuq karta ta 'kreditu",
    "stripeCardPay.text4": "inputs reset"
  },
  {
    "LANGUAGE": "Norwegian (Bokm?l)",
    "CODE": "nb",
    "general.text1": "Hallo",
    "general.text2": "Takk skal du ha",
    "general.text3": "Velkommen",
    "general.text4": "Takk",
    "general.text5": "Børs",
    "general.text6": "Utveksling",
    "general.text7": "lommebok",
    "general.text8": "sende",
    "general.text9": "Send penger",
    "general.text10": "motta",
    "general.text11": "få penger",
    "general.text12": "trading",
    "general.text13": "aksjehandel",
    "general.text14": "smarttelefon",
    "general.text15": "i hånden",
    "general.text16": "beskrivelse",
    "general.text17": "Dato",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "marked",
    "general.text21": "informasjon",
    "general.text22": "selge",
    "general.text23": "kjøpe",
    "general.text24": "transaksjon",
    "general.text25": "type",
    "general.text26": "få til",
    "general.text27": "styre",
    "general.text28": "Søk",
    "general.text29": "xx",
    "general.text30": "balanserer",
    "general.text31": "aksepterer",
    "general.text32": "historie",
    "general.text33": "transaksjonsoversikten",
    "general.text34": "xx",
    "general.text35": "mål",
    "general.text36": "å velge",
    "general.text37": "sett",
    "general.text38": "ressurs",
    "general.text39": "inngang",
    "general.text40": "Tast inn",
    "general.text41": "beløp",
    "general.text42": "finne veien",
    "general.text43": "estimert kostnad",
    "general.text44": "avgift",
    "general.text45": "Eksklusiv",
    "general.text46": "anbefales",
    "general.text47": "viser saldoen",
    "general.text48": "Bekreft innganger",
    "general.text49": "tydelige innganger",
    "general.text50": "regnskap",
    "general.text51": "bankkonto",
    "general.text52": "innbetaling",
    "general.text53": "to steg",
    "general.text54": "din",
    "general.text55": "xx",
    "general.text56": "vilkår for bruk",
    "general.text57": "retningslinjer for personvern",
    "": "",
    "toppage.text1": "Innskudd XLM til din konto-ID med et kredittkort",
    "toppage.text2": "(Hvis du ikke har konto-ID, kan du generere Account ID med App på StellarSpace)",
    "toppage.text3": "Språk",
    "toppage.text4": "Endring",
    "__1": "",
    "depositForm.text1": "innskudd lyktes",
    "depositForm.text2": "innskudd mislyktes",
    "depositForm.text3": "Behandler innskudd ...",
    "depositForm.text4": "Innskuddet er fullført med transaksjons-ID:",
    "depositForm.text5": "Ditt innskudd ble ikke fullført. Hvis du har blitt belastet, vennligst kontakt støtte på",
    "depositForm.text6": "Ditt innskudd blir behandlet, og vi forsøker å sende dine midler.",
    "depositForm.text7": "Lukk",
    "depositForm.text8": "innskudd Antall",
    "depositForm.text9": "konto-ID",
    "depositForm.text10": "Betal med Kredittkort",
    "depositForm.text11": "Innskudd er belastet kredittkort gebyr på 3,6% og minimum er fem dollar ($ 5) beløp (mer enn $ 10 er anbefalt).",
    "depositForm.text12": "Vi er ikke ansvarlige for Konto-ID-feil.",
    "depositForm.text13": "Estimert XLM beløpet du har",
    "depositForm.text14": "basert på XLM pris",
    "depositForm.text15": "pris",
    "depositForm.text16": "Oppdater",
    "depositForm.text17": "ugyldig konto-ID",
    "__2": "",
    "stripeCardPay.text1": "Prøv igjen",
    "stripeCardPay.text2": "Fortsett til betaling",
    "stripeCardPay.text3": "navn på kredittkort",
    "stripeCardPay.text4": "reset-innganger"
  },
  {
    "LANGUAGE": "Dutch",
    "CODE": "nl",
    "general.text1": "Hallo",
    "general.text2": "Dank u",
    "general.text3": "Welkom",
    "general.text4": "Bedankt",
    "general.text5": "effectenbeurs",
    "general.text6": "uitwisseling",
    "general.text7": "portemonnee",
    "general.text8": "sturen",
    "general.text9": "stuur geld",
    "general.text10": "te ontvangen",
    "general.text11": "geld krijgen",
    "general.text12": "handel",
    "general.text13": "aandelenhandel",
    "general.text14": "smartphone",
    "general.text15": "in je hand",
    "general.text16": "Omschrijving",
    "general.text17": "datum",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "markt",
    "general.text21": "informatie",
    "general.text22": "verkopen",
    "general.text23": "kopen",
    "general.text24": "transactie",
    "general.text25": "type",
    "general.text26": "beheren",
    "general.text27": "controle",
    "general.text28": "Zoeken",
    "general.text29": "xx",
    "general.text30": "saldi",
    "general.text31": "aanvaarden",
    "general.text32": "geschiedenis",
    "general.text33": "transactierecord",
    "general.text34": "xx",
    "general.text35": "bestemming",
    "general.text36": "kiezen",
    "general.text37": "reeks",
    "general.text38": "Bedrijfsmiddel",
    "general.text39": "invoer",
    "general.text40": "invoeren",
    "general.text41": "bedrag",
    "general.text42": "vind pad",
    "general.text43": "geschatte kosten",
    "general.text44": "vergoeding",
    "general.text45": "Exclusief",
    "general.text46": "aanbevolen",
    "general.text47": "toon uw saldo",
    "general.text48": "invoer bevestigen",
    "general.text49": "clear input",
    "general.text50": "account",
    "general.text51": "bankrekening",
    "general.text52": "betaling",
    "general.text53": "twee stappen",
    "general.text54": "uw",
    "general.text55": "xx",
    "general.text56": "gebruiksvoorwaarden",
    "general.text57": "privacybeleid",
    "": "",
    "toppage.text1": "Deposit XLM om uw account-ID met een creditcard",
    "toppage.text2": "(Als u niet beschikt over Account ID, kunt u het genereren van account-ID met behulp van App op StellarSpace)",
    "toppage.text3": "Taal",
    "toppage.text4": "Verandering",
    "__1": "",
    "depositForm.text1": "borg geslaagd",
    "depositForm.text2": "Betaling mislukt",
    "depositForm.text3": "Verwerking storting ...",
    "depositForm.text4": "Uw storting is succesvol afgerond met de transactie ID:",
    "depositForm.text5": "Uw storting is niet voltooid. Als u in rekening zijn gebracht, neem dan contact op ondersteuning op",
    "depositForm.text6": "Uw storting wordt verwerkt en we proberen om uw geld te sturen.",
    "depositForm.text7": "dichtbij",
    "depositForm.text8": "borg Hoeveelheid",
    "depositForm.text9": "Account ID",
    "depositForm.text10": "Betaal met credit card",
    "depositForm.text11": "Deposito's worden gebracht van de credit card fee van 3,6% en het minimum is 5 USD ($ 5) bedrag (meer dan $ 10 wordt aanbevolen).",
    "depositForm.text12": "Wij zijn niet verantwoordelijk voor onjuiste Account-ID's.",
    "depositForm.text13": "Geschat XLM bedrag dat je hebt",
    "depositForm.text14": "gebaseerd op XLM prijs",
    "depositForm.text15": "prijs",
    "depositForm.text16": "bijwerken",
    "depositForm.text17": "ongeldig Account ID",
    "__2": "",
    "stripeCardPay.text1": "Probeer het opnieuw",
    "stripeCardPay.text2": "Ga verder met card betaling",
    "stripeCardPay.text3": "naam op creditcard",
    "stripeCardPay.text4": "resetingangen"
  },
  {
    "LANGUAGE": "Northern Sotho",
    "CODE": "ns",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "general.text24": "#VALUE!",
    "general.text25": "#VALUE!",
    "general.text26": "#VALUE!",
    "general.text27": "#VALUE!",
    "general.text28": "#VALUE!",
    "general.text29": "#VALUE!",
    "general.text30": "#VALUE!",
    "general.text31": "#VALUE!",
    "general.text32": "#VALUE!",
    "general.text33": "#VALUE!",
    "general.text34": "#VALUE!",
    "general.text35": "#VALUE!",
    "general.text36": "#VALUE!",
    "general.text37": "#VALUE!",
    "general.text38": "#VALUE!",
    "general.text39": "#VALUE!",
    "general.text40": "#VALUE!",
    "general.text41": "#VALUE!",
    "general.text42": "#VALUE!",
    "general.text43": "#VALUE!",
    "general.text44": "#VALUE!",
    "general.text45": "#VALUE!",
    "general.text46": "#VALUE!",
    "general.text47": "#VALUE!",
    "general.text48": "#VALUE!",
    "general.text49": "#VALUE!",
    "general.text50": "#VALUE!",
    "general.text51": "#VALUE!",
    "general.text52": "#VALUE!",
    "general.text53": "#VALUE!",
    "general.text54": "#VALUE!",
    "general.text55": "#VALUE!",
    "general.text56": "#VALUE!",
    "general.text57": "#VALUE!",
    "": "",
    "toppage.text1": "#VALUE!",
    "toppage.text2": "#VALUE!",
    "toppage.text3": "#VALUE!",
    "toppage.text4": "#VALUE!",
    "__1": "",
    "depositForm.text1": "#VALUE!",
    "depositForm.text2": "#VALUE!",
    "depositForm.text3": "#VALUE!",
    "depositForm.text4": "#VALUE!",
    "depositForm.text5": "#VALUE!",
    "depositForm.text6": "#VALUE!",
    "depositForm.text7": "#VALUE!",
    "depositForm.text8": "#VALUE!",
    "depositForm.text9": "#VALUE!",
    "depositForm.text10": "#VALUE!",
    "depositForm.text11": "#VALUE!",
    "depositForm.text12": "#VALUE!",
    "depositForm.text13": "#VALUE!",
    "depositForm.text14": "#VALUE!",
    "depositForm.text15": "#VALUE!",
    "depositForm.text16": "#VALUE!",
    "depositForm.text17": "#VALUE!",
    "__2": "",
    "stripeCardPay.text1": "#VALUE!",
    "stripeCardPay.text2": "#VALUE!",
    "stripeCardPay.text3": "#VALUE!",
    "stripeCardPay.text4": "#VALUE!"
  },
  {
    "LANGUAGE": "Punjabi",
    "CODE": "pa",
    "general.text1": "ਸਤ ਸ੍ਰੀ ਅਕਾਲ",
    "general.text2": "ਤੁਹਾਡਾ ਧੰਨਵਾਦ",
    "general.text3": "ਸੁਆਗਤ ਹੈ",
    "general.text4": "ਧੰਨਵਾਦ",
    "general.text5": "ਸਟਾਕ ਐਕਸਚੇਜ਼",
    "general.text6": "ਮੁਦਰਾ",
    "general.text7": "ਬਟੂਆ",
    "general.text8": "ਭੇਜਣ",
    "general.text9": "ਭੇਜਣ ਦੇ ਪੈਸੇ",
    "general.text10": "ਪ੍ਰਾਪਤ",
    "general.text11": "ਪੈਸੇ ਨੂੰ ਪ੍ਰਾਪਤ",
    "general.text12": "ਵਪਾਰ",
    "general.text13": "ਸਟਾਕ ਵਪਾਰ",
    "general.text14": "ਸਮਾਰਟਫੋਨ",
    "general.text15": "ਆਪਣੇ ਹੱਥ ਵਿੱਚ",
    "general.text16": "ਦਾ ਵੇਰਵਾ",
    "general.text17": "ਤਾਰੀਖ਼",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "ਮਾਰਕੀਟ ਨੂੰ",
    "general.text21": "ਜਾਣਕਾਰੀ",
    "general.text22": "ਵੇਚ",
    "general.text23": "ਖਰੀਦ",
    "general.text24": "ਸੰਚਾਰ",
    "general.text25": "ਦੀ ਕਿਸਮ",
    "general.text26": "ਪ੍ਰਬੰਧ ਕਰਨਾ, ਕਾਬੂ ਕਰਨਾ",
    "general.text27": "ਕੰਟਰੋਲ",
    "general.text28": "ਖੋਜ",
    "general.text29": "xx",
    "general.text30": "ਬਕਾਏ",
    "general.text31": "ਨੂੰ ਸਵੀਕਾਰ",
    "general.text32": "ਇਤਿਹਾਸ ਨੂੰ",
    "general.text33": "ਸੰਚਾਰ ਦਾ ਰਿਕਾਰਡ",
    "general.text34": "xx",
    "general.text35": "ਮੰਜ਼ਿਲ",
    "general.text36": "ਦੀ ਚੋਣ ਕਰੋ",
    "general.text37": "ਸੈੱਟ '",
    "general.text38": "ਸੰਪਤੀ",
    "general.text39": "ਇੰਪੁੱਟ",
    "general.text40": "ਦਿਓ,",
    "general.text41": "ਦੀ ਰਕਮ",
    "general.text42": "ਮਾਰਗ ਦਾ ਪਤਾ",
    "general.text43": "ਅੰਦਾਜ਼ਨ ਲਾਗਤ",
    "general.text44": "ਫੀਸ",
    "general.text45": "ਨੂੰ ਛੱਡ ਕੇ",
    "general.text46": "ਸਿਫਾਰਸ਼ ਕੀਤੀ",
    "general.text47": "ਆਪਣੇ ਸੰਤੁਲਨ ਨੂੰ ਦਿਖਾਉਣ",
    "general.text48": "ਪੁਸ਼ਟੀ ਨਿਵੇਸ਼",
    "general.text49": "ਸਾਫ ਨਿਵੇਸ਼",
    "general.text50": "ਖਾਤੇ",
    "general.text51": "ਬੈੰਕ ਖਾਤਾ",
    "general.text52": "ਭੁਗਤਾਨ ਦੀ",
    "general.text53": "ਦੋ ਕਦਮ ਹੈ",
    "general.text54": "ਆਪਣੇ",
    "general.text55": "xx",
    "general.text56": "ਵਰਤੋ ਦੀਆਂ ਸ਼ਰਤਾਂ",
    "general.text57": "ਪਰਾਈਵੇਟ ਨੀਤੀ",
    "": "",
    "toppage.text1": "ਇੱਕ ਕਰੈਡਿਟ ਕਾਰਡ ਦੇ ਨਾਲ ਆਪਣੇ ਖਾਤੇ ID ਲਈ ਪੇਸ਼ਗੀ XLM",
    "toppage.text2": "(ਤੁਹਾਨੂੰ ਖਾਤਾ ID ਹੈ, ਨਾ ਹੈ, ਜੇ, ਤੁਹਾਨੂੰ StellarSpace 'ਤੇ ਐਪ ਵਰਤ ਖਾਤਾ ID ਤਿਆਰ ਕਰ ਸਕਦੇ ਹਨ)",
    "toppage.text3": "ਭਾਸ਼ਾ",
    "toppage.text4": "ਬਦਲੋ",
    "__1": "",
    "depositForm.text1": "ਡਿਪਾਜ਼ਿਟ ਸਫ਼ਲ",
    "depositForm.text2": "ਡਿਪਾਜ਼ਿਟ ਲਈ ਫੇਲ੍ਹ ਹੈ",
    "depositForm.text3": "ਡਿਪਾਜ਼ਿਟ ਕਾਰਵਾਈ ...",
    "depositForm.text4": "ਤੁਹਾਡਾ ਡਿਪਾਜ਼ਿਟ ਸੰਚਾਰ ID ਨਾਲ ਮੁਕੰਮਲ:",
    "depositForm.text5": "ਤੁਹਾਡਾ ਡਿਪਾਜ਼ਿਟ ਸਫਲਤਾਪੂਰਕ ਪੂਰਾ ਨਾ ਕੀਤਾ. ਜੇਕਰ ਤੁਹਾਨੂੰ ਦਾ ਦੋਸ਼ ਗਿਆ ਹੈ, ਜੇ, 'ਤੇ ਸਹਿਯੋਗ ਨੂੰ ਸੰਪਰਕ ਕਰੋ",
    "depositForm.text6": "ਤੁਹਾਡਾ ਡਿਪਾਜ਼ਿਟ ਕਾਰਵਾਈ ਕੀਤੀ ਜਾ ਰਹੀ ਹੈ ਅਤੇ ਸਾਨੂੰ ਆਪਣੇ ਫੰਡ ਭੇਜਣ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰ ਰਹੇ ਹਨ.",
    "depositForm.text7": "ਨੇੜੇ",
    "depositForm.text8": "ਡਿਪਾਜ਼ਿਟ ਮਾਤਰਾ",
    "depositForm.text9": "ਖਾਤਾ ID",
    "depositForm.text10": "ਕ੍ਰੈਡਿਟ ਕਾਰਡ ਨਾਲ ਭੁਗਤਾਨ",
    "depositForm.text11": "ਪੇਸ਼ਗੀ 3.6% ਦੀ ਕ੍ਰੈਡਿਟ ਕਾਰਡ ਫੀਸ ਦਾ ਦੋਸ਼ ਰਹੇ ਹਨ ਅਤੇ ਘੱਟੋ-ਘੱਟ 5 ਡਾਲਰ ($ 5) ਦੀ ਰਕਮ (ਵੱਧ $ 10 ਦੀ ਸਿਫਾਰਸ਼ ਕੀਤੀ ਹੈ) ਹੁੰਦਾ ਹੈ.",
    "depositForm.text12": "ਸਾਨੂੰ ਗਲਤ ਖਾਤਾ ID ਲਈ ਜ਼ਿੰਮੇਵਾਰ ਨਹੀ ਹਨ.",
    "depositForm.text13": "ਅਨੁਮਾਨਿਤ XLM ਦੀ ਰਕਮ ਤੁਹਾਡੇ ਕੋਲ ਹੈ ਜਾਵੇਗਾ",
    "depositForm.text14": "XLM ਕੀਮਤ ਦੇ ਆਧਾਰ 'ਤੇ",
    "depositForm.text15": "ਕੀਮਤ",
    "depositForm.text16": "ਅੱਪਡੇਟ",
    "depositForm.text17": "ਗਲਤ ਖਾਤਾ ID",
    "__2": "",
    "stripeCardPay.text1": "ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    "stripeCardPay.text2": "ਕਾਰਡ ਦਾ ਭੁਗਤਾਨ ਕਰਨ ਲਈ ਜਾਰੀ",
    "stripeCardPay.text3": "ਕ੍ਰੈਡਿਟ ਕਾਰਡ 'ਤੇ ਨਾਮ ਦਾ",
    "stripeCardPay.text4": "ਰੀਸੈਟ ਜਾਣਕਾਰੀ"
  },
  {
    "LANGUAGE": "Polish",
    "CODE": "pl",
    "general.text1": "dzień dobry",
    "general.text2": "Dziękuję Ci",
    "general.text3": "Witamy",
    "general.text4": "Dzięki",
    "general.text5": "Giełda Papierów Wartościowych",
    "general.text6": "Wymieniać się",
    "general.text7": "portfel",
    "general.text8": "wysłać",
    "general.text9": "Wyślij pieniądze",
    "general.text10": "otrzymać",
    "general.text11": "otrzymać pieniądze",
    "general.text12": "handlowy",
    "general.text13": "giełdowych",
    "general.text14": "smartphone",
    "general.text15": "w Twojej ręce",
    "general.text16": "opis",
    "general.text17": "data",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "rynek",
    "general.text21": "Informacja",
    "general.text22": "Sprzedać",
    "general.text23": "Kup",
    "general.text24": "transakcja",
    "general.text25": "rodzaj",
    "general.text26": "zarządzać",
    "general.text27": "kontrola",
    "general.text28": "Szukaj",
    "general.text29": "xx",
    "general.text30": "Wagi",
    "general.text31": "zaakceptować",
    "general.text32": "historia",
    "general.text33": "zapis transakcjii",
    "general.text34": "xx",
    "general.text35": "Miejsce docelowe",
    "general.text36": "Wybierz",
    "general.text37": "zestaw",
    "general.text38": "kapitał",
    "general.text39": "Wejście",
    "general.text40": "wchodzić",
    "general.text41": "ilość",
    "general.text42": "znaleźć drogę",
    "general.text43": "Szacowany koszt",
    "general.text44": "opłata",
    "general.text45": "z pominięciem",
    "general.text46": "Zalecana",
    "general.text47": "pokazać swoją równowagę",
    "general.text48": "wejścia confirm",
    "general.text49": "jasne wejścia",
    "general.text50": "konto",
    "general.text51": "konto bankowe",
    "general.text52": "Zapłata",
    "general.text53": "dwuetapowym",
    "general.text54": "Twój",
    "general.text55": "xx",
    "general.text56": "Warunki korzystania",
    "general.text57": "Polityka prywatności",
    "": "",
    "toppage.text1": "Depozyt XLM do swojego konta ID z karty kredytowej",
    "toppage.text2": "(Jeśli nie masz konta identyfikator, można wygenerować identyfikator konta za pomocą aplikacji na StellarSpace)",
    "toppage.text3": "Język",
    "toppage.text4": "Zmiana",
    "__1": "",
    "depositForm.text1": "depozyt udało",
    "depositForm.text2": "Wpłata nieudana",
    "depositForm.text3": "Przetwarzanie depozytu ...",
    "depositForm.text4": "Twój depozyt został zakończony pomyślnie z identyfikatorem transakcji:",
    "depositForm.text5": "Wpłata nie została zakończona pomyślnie. Jeśli zostały naładowane, prosimy o wsparcie kontaktów na",
    "depositForm.text6": "Twój depozyt jest przetwarzane i staramy się wysłać swoje fundusze.",
    "depositForm.text7": "blisko",
    "depositForm.text8": "Ilość depozytu",
    "depositForm.text9": "ID konta",
    "depositForm.text10": "Zapłać kartą kredytową",
    "depositForm.text11": "Depozyty są uiścić opłatę za kartę kredytową o 3,6%, a minimalna to 5 USD ($ 5) kwota (ponad $ 10 jest zalecana).",
    "depositForm.text12": "Nie ponosimy odpowiedzialności za błędne identyfikatory kont.",
    "depositForm.text13": "Szacunkowa kwota XLM trzeba będzie",
    "depositForm.text14": "na podstawie XLM cenie",
    "depositForm.text15": "Cena £",
    "depositForm.text16": "aktualizacja",
    "depositForm.text17": "nieprawidłowy identyfikator konta",
    "__2": "",
    "stripeCardPay.text1": "Spróbuj ponownie",
    "stripeCardPay.text2": "Przejdź do płatności kartą",
    "stripeCardPay.text3": "Nazwa na karcie kredytowej",
    "stripeCardPay.text4": "wejścia resetowania"
  },
  {
    "LANGUAGE": "Pashto",
    "CODE": "ps",
    "general.text1": "سلام",
    "general.text2": "مننه",
    "general.text3": "ښه راغلاست",
    "general.text4": "مننه",
    "general.text5": "د اسهامو او اسعارو د تبادلې ځای",
    "general.text6": "په بدل کې",
    "general.text7": "بټوې",
    "general.text8": "Send",
    "general.text9": "Send د پیسو",
    "general.text10": "تر لاسه",
    "general.text11": "د پيسو تر لاسه",
    "general.text12": "سوداګري",
    "general.text13": "سټاک سوداګري",
    "general.text14": "سمارټ",
    "general.text15": "ستاسو په لاس کې",
    "general.text16": "Description",
    "general.text17": "نېټه",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "د بازار د",
    "general.text21": "معلومات",
    "general.text22": "پلورلو",
    "general.text23": "اخلي",
    "general.text24": "د راکړې ورکړې",
    "general.text25": "ډول",
    "general.text26": "اداره",
    "general.text27": "کنترول",
    "general.text28": "پلټنه",
    "general.text29": "xx",
    "general.text30": "توازن",
    "general.text31": "ومني",
    "general.text32": "تاریخ",
    "general.text33": "د راکړې ورکړې ریکارډ",
    "general.text34": "xx",
    "general.text35": "مقصد",
    "general.text36": "غوره",
    "general.text37": "ټولګه",
    "general.text38": "د شتمنیو",
    "general.text39": "آخذه",
    "general.text40": "داخل",
    "general.text41": "اندازه",
    "general.text42": "لاره پیدا",
    "general.text43": "اټکل شوی لګښت",
    "general.text44": "فیس",
    "general.text45": "پرته له",
    "general.text46": "سپارښتنه",
    "general.text47": "ستاسو د انډول ښيي",
    "general.text48": "تأیید نظريات",
    "general.text49": "روښانه نظريات",
    "general.text50": "ګڼون",
    "general.text51": "د بانک ګڼوڼ",
    "general.text52": "د تادیاتو",
    "general.text53": "دوه ګام",
    "general.text54": "ستاسو د",
    "general.text55": "xx",
    "general.text56": "د کارولو شرایط",
    "general.text57": "د پټتیا تګلاره",
    "": "",
    "toppage.text1": "ستاسو د حساب تذکرو کان XLM په کريډټ کارډ",
    "toppage.text2": "(که تاسو حساب تذکرې نه لري، چې تاسو کولای شي د تولید حساب تذکرو ددفتروسایل د کارولو له StellarSpace)",
    "toppage.text3": "ژبه",
    "toppage.text4": "د بدلون",
    "__1": "",
    "depositForm.text1": "کان بريالي",
    "depositForm.text2": "کان ناکام",
    "depositForm.text3": "د پروسس د امانت ...",
    "depositForm.text4": "ستاسو د امانت سره د راکړې ورکړې تذکرو په برياليتوب سره بشپړې کړي دي:",
    "depositForm.text5": "ستاسو د امانت نه په بریالیتوب سره بشپړ نه. که تاسو تورن شوي دي، په تماس ملاتړ خوښ",
    "depositForm.text6": "ستاسو د امانت دی پروسس او مونږ ستاسو د مرستې واستوي هڅه.",
    "depositForm.text7": "نژدې",
    "depositForm.text8": "کان مقدار",
    "depositForm.text9": "د حساب شمیره",
    "depositForm.text10": "سره د پورونو د کارت د معاشونو",
    "depositForm.text11": "سپما د 3.6٪ د کریدت کارت فیس په تور او د لږ تر لږه ده 5 ډالر ($ 5) اندازه (زیات 10 $ سپارښتنه).",
    "depositForm.text12": "موږ د ناسم حساب پېژندپاڼې په غاړه نه دي.",
    "depositForm.text13": "اټکل XLM اندازه به تاسو ته لري",
    "depositForm.text14": "پر بنسټ XLM بيه",
    "depositForm.text15": "قیمت",
    "depositForm.text16": "تازه",
    "depositForm.text17": "ناسم حساب ID",
    "__2": "",
    "stripeCardPay.text1": "بیا هڅه وکړه",
    "stripeCardPay.text2": "کارت پیسو ته لاړ",
    "stripeCardPay.text3": "نوم د کریدت کارت",
    "stripeCardPay.text4": "د بیرته نظريات"
  },
  {
    "LANGUAGE": "Portuguese",
    "CODE": "pt",
    "general.text1": "Olá",
    "general.text2": "Obrigado",
    "general.text3": "Bem-vinda",
    "general.text4": "obrigado",
    "general.text5": "Bolsa de Valores",
    "general.text6": "troca",
    "general.text7": "carteira",
    "general.text8": "enviar",
    "general.text9": "enviar dinheiro",
    "general.text10": "receber",
    "general.text11": "receber dinheiro",
    "general.text12": "comércio",
    "general.text13": "negociação de ações",
    "general.text14": "Smartphone",
    "general.text15": "na sua mão",
    "general.text16": "descrição",
    "general.text17": "encontro",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "mercado",
    "general.text21": "em formação",
    "general.text22": "vender",
    "general.text23": "Comprar",
    "general.text24": "transação",
    "general.text25": "tipo",
    "general.text26": "gerir",
    "general.text27": "ao controle",
    "general.text28": "Procurar",
    "general.text29": "xx",
    "general.text30": "saldos",
    "general.text31": "aceitar",
    "general.text32": "história",
    "general.text33": "registro de transação",
    "general.text34": "xx",
    "general.text35": "destino",
    "general.text36": "selecionar",
    "general.text37": "conjunto",
    "general.text38": "de ativos",
    "general.text39": "entrada",
    "general.text40": "entrar",
    "general.text41": "montante",
    "general.text42": "encontrar o caminho",
    "general.text43": "custo estimado",
    "general.text44": "taxa",
    "general.text45": "excluindo",
    "general.text46": "recomendado",
    "general.text47": "mostrar o seu equilíbrio",
    "general.text48": "entradas confirmam",
    "general.text49": "contribuições claras",
    "general.text50": "conta",
    "general.text51": "conta bancária",
    "general.text52": "Forma de pagamento",
    "general.text53": "dois passos",
    "general.text54": "seu",
    "general.text55": "xx",
    "general.text56": "termos de uso",
    "general.text57": "política de Privacidade",
    "": "",
    "toppage.text1": "Depósito XLM ao seu ID da conta com um cartão de crédito",
    "toppage.text2": "(Se você não tem conta ID, você pode gerar Conta ID usando App no ​​StellarSpace)",
    "toppage.text3": "Língua",
    "toppage.text4": "Mudar",
    "__1": "",
    "depositForm.text1": "depósito sucedido",
    "depositForm.text2": "depósito falhou",
    "depositForm.text3": "Processamento de depósito ...",
    "depositForm.text4": "O seu depósito foi concluída com êxito com ID de transação:",
    "depositForm.text5": "O seu depósito não foi concluída com êxito. Se você foi acusado, por favor, contate o suporte em",
    "depositForm.text6": "O depósito está sendo processada e estamos tentando enviar seus fundos.",
    "depositForm.text7": "fechar",
    "depositForm.text8": "depósito Quantidade",
    "depositForm.text9": "ID da conta",
    "depositForm.text10": "Pague com cartão de crédito",
    "depositForm.text11": "Depósitos são cobrados a taxa de cartão de crédito de 3,6% eo mínimo é de 5 USD ($ 5) quantidade (mais de US $ 10 é recomendado).",
    "depositForm.text12": "Nós não somos responsáveis ​​por IDs de conta incorrecto.",
    "depositForm.text13": "quantidade XLM estimado você terá",
    "depositForm.text14": "com base no preço XLM",
    "depositForm.text15": "preço",
    "depositForm.text16": "atualizar",
    "depositForm.text17": "inválido ID da conta",
    "__2": "",
    "stripeCardPay.text1": "Tente novamente",
    "stripeCardPay.text2": "Avance para o pagamento com cartão",
    "stripeCardPay.text3": "Nome no cartão de crédito",
    "stripeCardPay.text4": "entradas de reset"
  },
  {
    "LANGUAGE": "Quechua",
    "CODE": "qu",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "general.text24": "#VALUE!",
    "general.text25": "#VALUE!",
    "general.text26": "#VALUE!",
    "general.text27": "#VALUE!",
    "general.text28": "#VALUE!",
    "general.text29": "#VALUE!",
    "general.text30": "#VALUE!",
    "general.text31": "#VALUE!",
    "general.text32": "#VALUE!",
    "general.text33": "#VALUE!",
    "general.text34": "#VALUE!",
    "general.text35": "#VALUE!",
    "general.text36": "#VALUE!",
    "general.text37": "#VALUE!",
    "general.text38": "#VALUE!",
    "general.text39": "#VALUE!",
    "general.text40": "#VALUE!",
    "general.text41": "#VALUE!",
    "general.text42": "#VALUE!",
    "general.text43": "#VALUE!",
    "general.text44": "#VALUE!",
    "general.text45": "#VALUE!",
    "general.text46": "#VALUE!",
    "general.text47": "#VALUE!",
    "general.text48": "#VALUE!",
    "general.text49": "#VALUE!",
    "general.text50": "#VALUE!",
    "general.text51": "#VALUE!",
    "general.text52": "#VALUE!",
    "general.text53": "#VALUE!",
    "general.text54": "#VALUE!",
    "general.text55": "#VALUE!",
    "general.text56": "#VALUE!",
    "general.text57": "#VALUE!",
    "": "",
    "toppage.text1": "#VALUE!",
    "toppage.text2": "#VALUE!",
    "toppage.text3": "#VALUE!",
    "toppage.text4": "#VALUE!",
    "__1": "",
    "depositForm.text1": "#VALUE!",
    "depositForm.text2": "#VALUE!",
    "depositForm.text3": "#VALUE!",
    "depositForm.text4": "#VALUE!",
    "depositForm.text5": "#VALUE!",
    "depositForm.text6": "#VALUE!",
    "depositForm.text7": "#VALUE!",
    "depositForm.text8": "#VALUE!",
    "depositForm.text9": "#VALUE!",
    "depositForm.text10": "#VALUE!",
    "depositForm.text11": "#VALUE!",
    "depositForm.text12": "#VALUE!",
    "depositForm.text13": "#VALUE!",
    "depositForm.text14": "#VALUE!",
    "depositForm.text15": "#VALUE!",
    "depositForm.text16": "#VALUE!",
    "depositForm.text17": "#VALUE!",
    "__2": "",
    "stripeCardPay.text1": "#VALUE!",
    "stripeCardPay.text2": "#VALUE!",
    "stripeCardPay.text3": "#VALUE!",
    "stripeCardPay.text4": "#VALUE!"
  },
  {
    "LANGUAGE": "Romanian",
    "CODE": "ro",
    "general.text1": "Salut",
    "general.text2": "Mulțumesc",
    "general.text3": "Bine ati venit",
    "general.text4": "Mulțumiri",
    "general.text5": "bursă de valori",
    "general.text6": "schimb valutar",
    "general.text7": "portofel",
    "general.text8": "trimite",
    "general.text9": "Trimite bani",
    "general.text10": "a primi",
    "general.text11": "primeste bani",
    "general.text12": "comercial",
    "general.text13": "stoc comercial",
    "general.text14": "smartphone",
    "general.text15": "în mâinile tale",
    "general.text16": "Descriere",
    "general.text17": "Data",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "piaţă",
    "general.text21": "informație",
    "general.text22": "vinde",
    "general.text23": "Cumpără",
    "general.text24": "tranzacţie",
    "general.text25": "tip",
    "general.text26": "administra",
    "general.text27": "Control",
    "general.text28": "Căutare",
    "general.text29": "xx",
    "general.text30": "soldurile",
    "general.text31": "Accept",
    "general.text32": "istorie",
    "general.text33": "Rezultatele tranzacției",
    "general.text34": "xx",
    "general.text35": "destinaţie",
    "general.text36": "Selectați",
    "general.text37": "a stabilit",
    "general.text38": "activ",
    "general.text39": "intrare",
    "general.text40": "introduce",
    "general.text41": "Cantitate",
    "general.text42": "găsi calea",
    "general.text43": "cost estimat",
    "general.text44": "taxă",
    "general.text45": "F? r?",
    "general.text46": "recomandat",
    "general.text47": "arată soldul",
    "general.text48": "intrări confirmă",
    "general.text49": "intrări clare",
    "general.text50": "cont",
    "general.text51": "cont bancar",
    "general.text52": "plată",
    "general.text53": "doi pasi",
    "general.text54": "ta",
    "general.text55": "xx",
    "general.text56": "termeni de utilizare",
    "general.text57": "Politica de Confidențialitate",
    "": "",
    "toppage.text1": "Depozit XLM la ID-ul contului dvs. cu un card de credit",
    "toppage.text2": "(Dacă nu aveți un cont ID-ul, puteți genera utilizând ID-ul de cont App la StellarSpace)",
    "toppage.text3": "Limba",
    "toppage.text4": "Schimbare",
    "__1": "",
    "depositForm.text1": "Depozitul a reușit",
    "depositForm.text2": "Depunerea nu a reușit",
    "depositForm.text3": "Procesul de depunere ...",
    "depositForm.text4": "Depozitul dvs. a finalizat cu succes cu ID-ul de tranzacție:",
    "depositForm.text5": "Depozitul dvs. nu a fost finalizată cu succes. Dacă ați fost taxat, vă rugăm să contactați asistența la",
    "depositForm.text6": "Depozitul dvs. este în curs de procesare și încercăm să trimită fondurile.",
    "depositForm.text7": "închide",
    "depositForm.text8": "Depozit Cantitate",
    "depositForm.text9": "Cont ID",
    "depositForm.text10": "Plată cu card de credit",
    "depositForm.text11": "Depozitele plătesc taxa de card de credit de 3,6%, iar minimul este de 5 USD ($ 5) Suma (mai mult de 10 $ este recomandat).",
    "depositForm.text12": "Noi nu suntem responsabili pentru ID-urile de cont incorecte.",
    "depositForm.text13": "Suma estimată XLM va avea",
    "depositForm.text14": "pe baza prețului XLM",
    "depositForm.text15": "Preț",
    "depositForm.text16": "Actualizați",
    "depositForm.text17": "ID-ul de cont nevalid",
    "__2": "",
    "stripeCardPay.text1": "Încearcă din nou",
    "stripeCardPay.text2": "Mai departe spre plata prin card",
    "stripeCardPay.text3": "Numele de pe cartea de credit",
    "stripeCardPay.text4": "intrări de resetare"
  },
  {
    "LANGUAGE": "Russian",
    "CODE": "ru",
    "general.text1": "Привет",
    "general.text2": "Спасибо",
    "general.text3": "желанный",
    "general.text4": "Спасибо",
    "general.text5": "фондовая биржа",
    "general.text6": "обмен",
    "general.text7": "бумажник",
    "general.text8": "Отправить",
    "general.text9": "посылать деньги",
    "general.text10": "получить",
    "general.text11": "получать деньги",
    "general.text12": "торговый",
    "general.text13": "торговля акциями",
    "general.text14": "смартфон",
    "general.text15": "в твоей руке",
    "general.text16": "описание",
    "general.text17": "Дата",
    "general.text18": "хх",
    "general.text19": "хх",
    "general.text20": "рынок",
    "general.text21": "Информация",
    "general.text22": "продам",
    "general.text23": "купить",
    "general.text24": "сделка",
    "general.text25": "тип",
    "general.text26": "управлять",
    "general.text27": "контроль",
    "general.text28": "Поиск",
    "general.text29": "хх",
    "general.text30": "противовесов",
    "general.text31": "принимать",
    "general.text32": "история",
    "general.text33": "запись транзакции",
    "general.text34": "хх",
    "general.text35": "место назначения",
    "general.text36": "Выбрать",
    "general.text37": "устанавливать",
    "general.text38": "актив",
    "general.text39": "вход",
    "general.text40": "войти",
    "general.text41": "количество",
    "general.text42": "найти путь",
    "general.text43": "Ориентировочная стоимость",
    "general.text44": "плата",
    "general.text45": "без учета",
    "general.text46": "рекомендуемые",
    "general.text47": "показать свой баланс",
    "general.text48": "Подтверждение ввода",
    "general.text49": "четкие входы",
    "general.text50": "учетная запись",
    "general.text51": "банковский счет",
    "general.text52": "оплата",
    "general.text53": "два шага",
    "general.text54": "ваш",
    "general.text55": "хх",
    "general.text56": "Условия эксплуатации",
    "general.text57": "политика конфиденциальности",
    "": "",
    "toppage.text1": "Депозит XLM на свой ID счета с помощью кредитной карты",
    "toppage.text2": "(Если у вас нет учетной записи ID, вы можете создать идентификатор аккаунта с помощью приложения на StellarSpace)",
    "toppage.text3": "Язык",
    "toppage.text4": "Изменять",
    "__1": "",
    "depositForm.text1": "Депозит удалось",
    "depositForm.text2": "Платеж не прошел",
    "depositForm.text3": "Обработка депозит ...",
    "depositForm.text4": "Ваш депозит успешно завершен с идентификатором транзакции:",
    "depositForm.text5": "Ваш депозит не был успешно завершен. Если вы были заряжены, пожалуйста, обратитесь в службу поддержки на",
    "depositForm.text6": "Ваш депозит обрабатывается, и мы пытаемся направить свои средства.",
    "depositForm.text7": "Закрыть",
    "depositForm.text8": "Депозит Количество",
    "depositForm.text9": "Идентификатор аккаунта",
    "depositForm.text10": "Оплата с кредитной картой",
    "depositForm.text11": "Депозиты взимается сбор кредитной карты 3,6% и минимум 5 долларов США ($ 5) количество (более $ 10 рекомендуется).",
    "depositForm.text12": "Мы не несем ответственности за неправильные идентификаторы счетов.",
    "depositForm.text13": "Расчетное количество XLM вы будете иметь",
    "depositForm.text14": "на основе XLM цены",
    "depositForm.text15": "цена",
    "depositForm.text16": "Обновить",
    "depositForm.text17": "недопустимый идентификатор аккаунта",
    "__2": "",
    "stripeCardPay.text1": "Попробуй еще раз",
    "stripeCardPay.text2": "Приступить к оплате карты",
    "stripeCardPay.text3": "Имя на кредитной карте",
    "stripeCardPay.text4": "сброса входов"
  },
  {
    "LANGUAGE": "Sanskrit",
    "CODE": "sa",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "general.text24": "#VALUE!",
    "general.text25": "#VALUE!",
    "general.text26": "#VALUE!",
    "general.text27": "#VALUE!",
    "general.text28": "#VALUE!",
    "general.text29": "#VALUE!",
    "general.text30": "#VALUE!",
    "general.text31": "#VALUE!",
    "general.text32": "#VALUE!",
    "general.text33": "#VALUE!",
    "general.text34": "#VALUE!",
    "general.text35": "#VALUE!",
    "general.text36": "#VALUE!",
    "general.text37": "#VALUE!",
    "general.text38": "#VALUE!",
    "general.text39": "#VALUE!",
    "general.text40": "#VALUE!",
    "general.text41": "#VALUE!",
    "general.text42": "#VALUE!",
    "general.text43": "#VALUE!",
    "general.text44": "#VALUE!",
    "general.text45": "#VALUE!",
    "general.text46": "#VALUE!",
    "general.text47": "#VALUE!",
    "general.text48": "#VALUE!",
    "general.text49": "#VALUE!",
    "general.text50": "#VALUE!",
    "general.text51": "#VALUE!",
    "general.text52": "#VALUE!",
    "general.text53": "#VALUE!",
    "general.text54": "#VALUE!",
    "general.text55": "#VALUE!",
    "general.text56": "#VALUE!",
    "general.text57": "#VALUE!",
    "": "",
    "toppage.text1": "#VALUE!",
    "toppage.text2": "#VALUE!",
    "toppage.text3": "#VALUE!",
    "toppage.text4": "#VALUE!",
    "__1": "",
    "depositForm.text1": "#VALUE!",
    "depositForm.text2": "#VALUE!",
    "depositForm.text3": "#VALUE!",
    "depositForm.text4": "#VALUE!",
    "depositForm.text5": "#VALUE!",
    "depositForm.text6": "#VALUE!",
    "depositForm.text7": "#VALUE!",
    "depositForm.text8": "#VALUE!",
    "depositForm.text9": "#VALUE!",
    "depositForm.text10": "#VALUE!",
    "depositForm.text11": "#VALUE!",
    "depositForm.text12": "#VALUE!",
    "depositForm.text13": "#VALUE!",
    "depositForm.text14": "#VALUE!",
    "depositForm.text15": "#VALUE!",
    "depositForm.text16": "#VALUE!",
    "depositForm.text17": "#VALUE!",
    "__2": "",
    "stripeCardPay.text1": "#VALUE!",
    "stripeCardPay.text2": "#VALUE!",
    "stripeCardPay.text3": "#VALUE!",
    "stripeCardPay.text4": "#VALUE!"
  },
  {
    "LANGUAGE": "Sami (Northern)",
    "CODE": "se",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "general.text24": "#VALUE!",
    "general.text25": "#VALUE!",
    "general.text26": "#VALUE!",
    "general.text27": "#VALUE!",
    "general.text28": "#VALUE!",
    "general.text29": "#VALUE!",
    "general.text30": "#VALUE!",
    "general.text31": "#VALUE!",
    "general.text32": "#VALUE!",
    "general.text33": "#VALUE!",
    "general.text34": "#VALUE!",
    "general.text35": "#VALUE!",
    "general.text36": "#VALUE!",
    "general.text37": "#VALUE!",
    "general.text38": "#VALUE!",
    "general.text39": "#VALUE!",
    "general.text40": "#VALUE!",
    "general.text41": "#VALUE!",
    "general.text42": "#VALUE!",
    "general.text43": "#VALUE!",
    "general.text44": "#VALUE!",
    "general.text45": "#VALUE!",
    "general.text46": "#VALUE!",
    "general.text47": "#VALUE!",
    "general.text48": "#VALUE!",
    "general.text49": "#VALUE!",
    "general.text50": "#VALUE!",
    "general.text51": "#VALUE!",
    "general.text52": "#VALUE!",
    "general.text53": "#VALUE!",
    "general.text54": "#VALUE!",
    "general.text55": "#VALUE!",
    "general.text56": "#VALUE!",
    "general.text57": "#VALUE!",
    "": "",
    "toppage.text1": "#VALUE!",
    "toppage.text2": "#VALUE!",
    "toppage.text3": "#VALUE!",
    "toppage.text4": "#VALUE!",
    "__1": "",
    "depositForm.text1": "#VALUE!",
    "depositForm.text2": "#VALUE!",
    "depositForm.text3": "#VALUE!",
    "depositForm.text4": "#VALUE!",
    "depositForm.text5": "#VALUE!",
    "depositForm.text6": "#VALUE!",
    "depositForm.text7": "#VALUE!",
    "depositForm.text8": "#VALUE!",
    "depositForm.text9": "#VALUE!",
    "depositForm.text10": "#VALUE!",
    "depositForm.text11": "#VALUE!",
    "depositForm.text12": "#VALUE!",
    "depositForm.text13": "#VALUE!",
    "depositForm.text14": "#VALUE!",
    "depositForm.text15": "#VALUE!",
    "depositForm.text16": "#VALUE!",
    "depositForm.text17": "#VALUE!",
    "__2": "",
    "stripeCardPay.text1": "#VALUE!",
    "stripeCardPay.text2": "#VALUE!",
    "stripeCardPay.text3": "#VALUE!",
    "stripeCardPay.text4": "#VALUE!"
  },
  {
    "LANGUAGE": "Slovak",
    "CODE": "sk",
    "general.text1": "Ahoj",
    "general.text2": "Ďakujem",
    "general.text3": "vitajte",
    "general.text4": "Vďaka",
    "general.text5": "burza",
    "general.text6": "výmena",
    "general.text7": "peňaženka",
    "general.text8": "send",
    "general.text9": "Pošli peniaze",
    "general.text10": "obdržať",
    "general.text11": "prijímať peniaze",
    "general.text12": "obchodovanie",
    "general.text13": "obchodovanie na burze",
    "general.text14": "smartphone",
    "general.text15": "v ruke",
    "general.text16": "popis",
    "general.text17": "dátum",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "trhové",
    "general.text21": "informácie",
    "general.text22": "sell",
    "general.text23": "kúpiť",
    "general.text24": "transakcie",
    "general.text25": "typ",
    "general.text26": "spravovať",
    "general.text27": "ovládanie",
    "general.text28": "Vyhľadávanie",
    "general.text29": "xx",
    "general.text30": "zostatky",
    "general.text31": "súhlasiť",
    "general.text32": "histórie",
    "general.text33": "zaznamenávať údaje o transakciách",
    "general.text34": "xx",
    "general.text35": "destinácia",
    "general.text36": "vybrať",
    "general.text37": "sada",
    "general.text38": "aktívum",
    "general.text39": "vstup",
    "general.text40": "vstúpiť",
    "general.text41": "čiastka",
    "general.text42": "nájsť cestu",
    "general.text43": "odhadované náklady",
    "general.text44": "poplatok",
    "general.text45": "nepočítajúc",
    "general.text46": "odporúča",
    "general.text47": "uvádzali zostatok",
    "general.text48": "potvrdzujú vstupy",
    "general.text49": "jasné vstupy",
    "general.text50": "účet",
    "general.text51": "bankový účet",
    "general.text52": "platba",
    "general.text53": "dvojstupňový",
    "general.text54": "tvoj",
    "general.text55": "xx",
    "general.text56": "podmienky používania",
    "general.text57": "zásady ochrany osobných údajov",
    "": "",
    "toppage.text1": "Záloha XLM do svojho ID účtu kreditnou kartou",
    "toppage.text2": "(Ak nemáte ID účtu, môžete vygenerovať ID účtu pomocou App v StellarSpace)",
    "toppage.text3": "Jazyk",
    "toppage.text4": "zmena",
    "__1": "",
    "depositForm.text1": "záloha uspel",
    "depositForm.text2": "vklad zlyhal",
    "depositForm.text3": "Spracovanie vkladu ...",
    "depositForm.text4": "Váš vklad bol úspešne dokončený s ID transakcie:",
    "depositForm.text5": "Váš vklad nebol úspešne dokončený. Ak ste boli obvinení, obráťte sa na podporu na",
    "depositForm.text6": "Váš vklad sa spracováva a my sa snažia posielať svoje finančné prostriedky.",
    "depositForm.text7": "Zavrieť",
    "depositForm.text8": "záloha Množstvo",
    "depositForm.text9": "Číslo účtu",
    "depositForm.text10": "Platiť kreditnou kartou",
    "depositForm.text11": "Vklady sa vzťahuje poplatok za kreditnej karty vo výške 3,6% a minimum je 5 USD ($ 5), množstvo (viac ako 10 $ je odporúčané).",
    "depositForm.text12": "Nie sme zodpovední za chybné ID účtu.",
    "depositForm.text13": "Odhadovaná suma XLM, ktorý bude mať",
    "depositForm.text14": "založený na XLM cenu",
    "depositForm.text15": "cena",
    "depositForm.text16": "aktualizovať",
    "depositForm.text17": "neplatné číslo účtu",
    "__2": "",
    "stripeCardPay.text1": "skúste to znova",
    "stripeCardPay.text2": "Pristúpiť k platbe kartou",
    "stripeCardPay.text3": "meno na kreditnej karte",
    "stripeCardPay.text4": "resetu vstupy"
  },
  {
    "LANGUAGE": "Slovenian",
    "CODE": "sl",
    "general.text1": "zdravo",
    "general.text2": "Hvala vam",
    "general.text3": "Dobrodošli",
    "general.text4": "hvala",
    "general.text5": "borza",
    "general.text6": "izmenjava",
    "general.text7": "denarnica",
    "general.text8": "pošlji",
    "general.text9": "Pošlji denar",
    "general.text10": "prejeti",
    "general.text11": "prejemanje denarja",
    "general.text12": "trgovanje",
    "general.text13": "stock trading",
    "general.text14": "pametni telefon",
    "general.text15": "v roki",
    "general.text16": "opis",
    "general.text17": "datum",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "trg",
    "general.text21": "informacije",
    "general.text22": "prodaja",
    "general.text23": "nakup",
    "general.text24": "transakcija",
    "general.text25": "tip",
    "general.text26": "upravljanje",
    "general.text27": "nadzor",
    "general.text28": "Iskanje",
    "general.text29": "xx",
    "general.text30": "tehtnice",
    "general.text31": "sprejemajo",
    "general.text32": "zgodovina",
    "general.text33": "zapis transakcij",
    "general.text34": "xx",
    "general.text35": "destinacija",
    "general.text36": "izberite",
    "general.text37": "niz",
    "general.text38": "sredstvo",
    "general.text39": "vhod",
    "general.text40": "vpišite",
    "general.text41": "znesek",
    "general.text42": "najti pot",
    "general.text43": "ocenjeni stroški",
    "general.text44": "pristojbina",
    "general.text45": "Brez",
    "general.text46": "priporoča",
    "general.text47": "stanje prikazovali",
    "general.text48": "Potrdite vhodi",
    "general.text49": "jasne vhodi",
    "general.text50": "račun",
    "general.text51": "bančni račun",
    "general.text52": "plačilo",
    "general.text53": "dvostopenjski",
    "general.text54": "vaš",
    "general.text55": "xx",
    "general.text56": "pogoji uporabe",
    "general.text57": "pravilnik o zasebnosti",
    "": "",
    "toppage.text1": "Deposit XLM na vaš ID računa s kreditno kartico",
    "toppage.text2": "(Če nimate ID računa, lahko ustvarite ID računa s pomočjo aplikacije na StellarSpace)",
    "toppage.text3": "Jezik",
    "toppage.text4": "Spremeni",
    "__1": "",
    "depositForm.text1": "depozit je uspelo",
    "depositForm.text2": "Nakazilo ni uspelo",
    "depositForm.text3": "Obdelava depozit ...",
    "depositForm.text4": "Vaš depozit je uspešno zaključila s transakcijsko ID:",
    "depositForm.text5": "Vaš depozit ni uspešno dokončala. Če ste bili polni, se obrnite na podporo na",
    "depositForm.text6": "Vaš depozit, ki se obdelujejo, in smo se poskuša poslati vaše sredstev.",
    "depositForm.text7": "Zapri",
    "depositForm.text8": "Deposit Količina",
    "depositForm.text9": "ID računa",
    "depositForm.text10": "Plačajte s kreditno kartico",
    "depositForm.text11": "Depoziti se zaračuna provizijo za kreditne kartice za 3,6% in minimalno 5 USD ($ 5) znesek (več kot 10 $ je priporočljivo).",
    "depositForm.text12": "Smo ni odgovoren za nepravilno ID račun.",
    "depositForm.text13": "Ocenjena XLM znesek, ki ga bo imel",
    "depositForm.text14": "temelji na XLM ceni",
    "depositForm.text15": "cena",
    "depositForm.text16": "nadgradnja",
    "depositForm.text17": "neveljaven ID računa",
    "__2": "",
    "stripeCardPay.text1": "Poskusi ponovno",
    "stripeCardPay.text2": "Nadaljujemo do plačila kartice",
    "stripeCardPay.text3": "Ime na kreditno kartico",
    "stripeCardPay.text4": "ponastavitev vhodi"
  },
  {
    "LANGUAGE": "Albanian",
    "CODE": "sq",
    "general.text1": "Përshëndetje",
    "general.text2": "Faleminderit",
    "general.text3": "i mirëpritur",
    "general.text4": "Faleminderit",
    "general.text5": "bursë",
    "general.text6": "shkëmbim",
    "general.text7": "portofol",
    "general.text8": "dërgoni",
    "general.text9": "Dergo para",
    "general.text10": "marr",
    "general.text11": "marrin para",
    "general.text12": "tregti",
    "general.text13": "Stock Trading",
    "general.text14": "smartphone",
    "general.text15": "në dorën tuaj",
    "general.text16": "përshkrim",
    "general.text17": "data",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "treg",
    "general.text21": "informacion",
    "general.text22": "shes",
    "general.text23": "Blej",
    "general.text24": "transaksion",
    "general.text25": "lloj",
    "general.text26": "menaxhuar",
    "general.text27": "kontroll",
    "general.text28": "Kërko",
    "general.text29": "xx",
    "general.text30": "bilancet",
    "general.text31": "pranoj",
    "general.text32": "histori",
    "general.text33": "rekord transaksion",
    "general.text34": "xx",
    "general.text35": "destinacion",
    "general.text36": "zgjedh",
    "general.text37": "i vendosur",
    "general.text38": "pasuri",
    "general.text39": "të dhëna",
    "general.text40": "hyj",
    "general.text41": "sasi",
    "general.text42": "gjeni rrugën",
    "general.text43": "kostoja e llogaritur",
    "general.text44": "tarifë",
    "general.text45": "me përjashtim të",
    "general.text46": "rekomanduar",
    "general.text47": "tregojnë bilancin tuaj",
    "general.text48": "inputet Konfirmoni",
    "general.text49": "inputeve të qarta",
    "general.text50": "llogari",
    "general.text51": "Llogari bankare",
    "general.text52": "pagesë",
    "general.text53": "dy hap",
    "general.text54": "juaj",
    "general.text55": "xx",
    "general.text56": "Kushtet e përdorimit",
    "general.text57": "Politika e privatësisë",
    "": "",
    "toppage.text1": "Depozitave XLM të ID tuaj Account me një kartë krediti",
    "toppage.text2": "(Nëse ju nuk keni llogari ID, ju mund të gjenerojnë të llogarisë ID përdorur app në StellarSpace)",
    "toppage.text3": "Gjuhe",
    "toppage.text4": "ndryshim",
    "__1": "",
    "depositForm.text1": "Depozita sukses",
    "depositForm.text2": "Depozita dështuar",
    "depositForm.text3": "Përpunimin depozitë ...",
    "depositForm.text4": "depozitave juaj ka përfunduar me sukses me ID e transaksionit:",
    "depositForm.text5": "depozitë juaj nuk ka përfunduar me sukses. Nëse ju keni qenë të ngarkuar, ju lutem kontaktoni mbështetjen në",
    "depositForm.text6": "depozitave juaj është duke u përpunuar dhe ne jemi duke u përpjekur për të dërguar fondet tuaja.",
    "depositForm.text7": "afër",
    "depositForm.text8": "Depozita Sasia",
    "depositForm.text9": "Llogaria ID",
    "depositForm.text10": "Paguhet me karte krediti",
    "depositForm.text11": "Depozitat janë të ngarkuar tarifë kartë krediti prej 3.6% dhe minimale është 5 USD ($ 5) Shuma (më shumë se $ 10 është e rekomanduar).",
    "depositForm.text12": "Ne nuk jemi përgjegjës për kartat e identitetit të pasakta llogarisë.",
    "depositForm.text13": "shuma e vlerësuar XLM ju do të keni",
    "depositForm.text14": "në bazë të çmimit XLM",
    "depositForm.text15": "çmim",
    "depositForm.text16": "Përditësimi",
    "depositForm.text17": "pavlefshme ID Llogaria",
    "__2": "",
    "stripeCardPay.text1": "Provo përsëri",
    "stripeCardPay.text2": "Të vazhdojë për të një kartë",
    "stripeCardPay.text3": "Emri në kartën e kreditit",
    "stripeCardPay.text4": "inputet Reset"
  },
  {
    "LANGUAGE": "Swedish",
    "CODE": "sv",
    "general.text1": "Hallå",
    "general.text2": "Tack",
    "general.text3": "Välkommen",
    "general.text4": "Tack",
    "general.text5": "börsen",
    "general.text6": "utbyta",
    "general.text7": "plånbok",
    "general.text8": "skicka",
    "general.text9": "skicka pengar",
    "general.text10": "motta",
    "general.text11": "ta emot pengar",
    "general.text12": "handel",
    "general.text13": "aktiehandel",
    "general.text14": "smartphone",
    "general.text15": "i din hand",
    "general.text16": "beskrivning",
    "general.text17": "datum",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "marknadsföra",
    "general.text21": "information",
    "general.text22": "sälja",
    "general.text23": "köpa",
    "general.text24": "transaktion",
    "general.text25": "typ",
    "general.text26": "hantera",
    "general.text27": "kontrollera",
    "general.text28": "Sök",
    "general.text29": "xx",
    "general.text30": "saldon",
    "general.text31": "acceptera",
    "general.text32": "historia",
    "general.text33": "transaktionsregister",
    "general.text34": "xx",
    "general.text35": "destination",
    "general.text36": "Välj",
    "general.text37": "uppsättning",
    "general.text38": "tillgång",
    "general.text39": "inmatning",
    "general.text40": "stiga på",
    "general.text41": "belopp",
    "general.text42": "hitta vägen",
    "general.text43": "beräknad kostnad",
    "general.text44": "avgift",
    "general.text45": "exklusive",
    "general.text46": "rekommenderad",
    "general.text47": "visa ditt saldo",
    "general.text48": "bekräftar ingångar",
    "general.text49": "tydliga ingångar",
    "general.text50": "konto",
    "general.text51": "bankkonto",
    "general.text52": "betalning",
    "general.text53": "två steg",
    "general.text54": "din",
    "general.text55": "xx",
    "general.text56": "villkor",
    "general.text57": "integritetspolicy",
    "": "",
    "toppage.text1": "Deposit XLM på ditt konto-ID med ett kreditkort",
    "toppage.text2": "(Om du inte har konto ID kan du skapa konto-ID med hjälp av App på StellarSpace)",
    "toppage.text3": "Språk",
    "toppage.text4": "Förändra",
    "__1": "",
    "depositForm.text1": "insättning lyckades",
    "depositForm.text2": "insättning misslyckades",
    "depositForm.text3": "Bearbetning insättning ...",
    "depositForm.text4": "Din insättning har slutförts med transaktions-ID:",
    "depositForm.text5": "Din insättning slutfördes inte. Om du har åtalats, kontakta support på",
    "depositForm.text6": "Din insättning bearbetas och vi försöker skicka dina pengar.",
    "depositForm.text7": "stänga",
    "depositForm.text8": "deposit Kvantitet",
    "depositForm.text9": "konto-id",
    "depositForm.text10": "Betala med kreditkort",
    "depositForm.text11": "Depositioner debiteras kreditkort avgift på 3,6% och den minsta är 5 USD ($ 5) mängd (mer än $ 10 rekommenderas).",
    "depositForm.text12": "Vi är inte ansvariga för felaktiga Konto ID.",
    "depositForm.text13": "Beräknad XLM belopp du kommer att ha",
    "depositForm.text14": "baserat på XLM pris",
    "depositForm.text15": "pris",
    "depositForm.text16": "uppdatering",
    "depositForm.text17": "ogiltigt konto-ID",
    "__2": "",
    "stripeCardPay.text1": "Försök igen",
    "stripeCardPay.text2": "Fortsätt till kortbetalning",
    "stripeCardPay.text3": "Namn på kreditkortet",
    "stripeCardPay.text4": "Återställ ingångar"
  },
  {
    "LANGUAGE": "Swahili",
    "CODE": "sw",
    "general.text1": "Habari",
    "general.text2": "Asante",
    "general.text3": "Karibu",
    "general.text4": "shukrani",
    "general.text5": "Soko la hisa",
    "general.text6": "kubadilishana",
    "general.text7": "mkoba",
    "general.text8": "kutuma",
    "general.text9": "kutuma fedha",
    "general.text10": "kupokea",
    "general.text11": "kupokea fedha",
    "general.text12": "Biashara",
    "general.text13": "biashara ya hisa",
    "general.text14": "smartphone",
    "general.text15": "katika mkono wako",
    "general.text16": "maelezo",
    "general.text17": "tarehe",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "soko",
    "general.text21": "habari",
    "general.text22": "kuuza",
    "general.text23": "kununua",
    "general.text24": "shughuli",
    "general.text25": "aina",
    "general.text26": "kusimamia",
    "general.text27": "kudhibiti",
    "general.text28": "Tafuta",
    "general.text29": "xx",
    "general.text30": "mizani",
    "general.text31": "kukubali",
    "general.text32": "historia",
    "general.text33": "shughuli rekodi",
    "general.text34": "xx",
    "general.text35": "marudio",
    "general.text36": "kuchagua",
    "general.text37": "kuweka",
    "general.text38": "mali",
    "general.text39": "pembejeo",
    "general.text40": "kuingia",
    "general.text41": "kiasi",
    "general.text42": "kupata njia",
    "general.text43": "makadirio ya gharama",
    "general.text44": "ada",
    "general.text45": "Bila",
    "general.text46": "ilipendekeza",
    "general.text47": "kuonyesha mizani yako",
    "general.text48": "pembejeo kuthibitisha",
    "general.text49": "pembejeo wazi",
    "general.text50": "akaunti",
    "general.text51": "akaunti ya benki",
    "general.text52": "malipo",
    "general.text53": "hatua mbili",
    "general.text54": "yako",
    "general.text55": "xx",
    "general.text56": "masharti ya matumizi",
    "general.text57": "sera ya faragha",
    "": "",
    "toppage.text1": "Amana XLM kwa ID Akaunti yako na kadi ya mikopo",
    "toppage.text2": "(Kama huna akaunti ya ID, unaweza kuzalisha Akaunti ID kwa kutumia App katika StellarSpace)",
    "toppage.text3": "lugha",
    "toppage.text4": "Badilisha",
    "__1": "",
    "depositForm.text1": "amana wamefanikiwa",
    "depositForm.text2": "amana wameshindwa",
    "depositForm.text3": "Usindikaji amana ...",
    "depositForm.text4": "amana yako imekamilisha mafanikio na shughuli ID:",
    "depositForm.text5": "amana yako hayakukamilika kwa mafanikio. Kama umekuwa kushtakiwa, tafadhali msaada mawasiliano zilizo",
    "depositForm.text6": "amana lako linashughulikiwa na sisi ni kujaribu kutuma fedha yako.",
    "depositForm.text7": "karibu",
    "depositForm.text8": "amana Kiasi",
    "depositForm.text9": "kitambulisho cha akaunti",
    "depositForm.text10": "Pay na Kadi ya mikopo",
    "depositForm.text11": "Amana ni kushtakiwa ada ya kadi ya 3.6% na kiwango cha chini ni 5 USD ($ 5) kiasi (zaidi ya $ 10 inapendekezwa).",
    "depositForm.text12": "Sisi si kuwajibika kwa vitambulisho Akaunti sahihi.",
    "depositForm.text13": "Idadi ya XLM kiasi utakuwa na",
    "depositForm.text14": "kulingana na XLM bei",
    "depositForm.text15": "bei",
    "depositForm.text16": "update",
    "depositForm.text17": "Utambulisho wa Akaunti batili",
    "__2": "",
    "stripeCardPay.text1": "Jaribu tena",
    "stripeCardPay.text2": "Kuendelea na kadi ya malipo",
    "stripeCardPay.text3": "Jina kwenye kadi ya mikopo",
    "stripeCardPay.text4": "pembejeo upya"
  },
  {
    "LANGUAGE": "Syriac",
    "CODE": "syr",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "general.text24": "#VALUE!",
    "general.text25": "#VALUE!",
    "general.text26": "#VALUE!",
    "general.text27": "#VALUE!",
    "general.text28": "#VALUE!",
    "general.text29": "#VALUE!",
    "general.text30": "#VALUE!",
    "general.text31": "#VALUE!",
    "general.text32": "#VALUE!",
    "general.text33": "#VALUE!",
    "general.text34": "#VALUE!",
    "general.text35": "#VALUE!",
    "general.text36": "#VALUE!",
    "general.text37": "#VALUE!",
    "general.text38": "#VALUE!",
    "general.text39": "#VALUE!",
    "general.text40": "#VALUE!",
    "general.text41": "#VALUE!",
    "general.text42": "#VALUE!",
    "general.text43": "#VALUE!",
    "general.text44": "#VALUE!",
    "general.text45": "#VALUE!",
    "general.text46": "#VALUE!",
    "general.text47": "#VALUE!",
    "general.text48": "#VALUE!",
    "general.text49": "#VALUE!",
    "general.text50": "#VALUE!",
    "general.text51": "#VALUE!",
    "general.text52": "#VALUE!",
    "general.text53": "#VALUE!",
    "general.text54": "#VALUE!",
    "general.text55": "#VALUE!",
    "general.text56": "#VALUE!",
    "general.text57": "#VALUE!",
    "": "",
    "toppage.text1": "#VALUE!",
    "toppage.text2": "#VALUE!",
    "toppage.text3": "#VALUE!",
    "toppage.text4": "#VALUE!",
    "__1": "",
    "depositForm.text1": "#VALUE!",
    "depositForm.text2": "#VALUE!",
    "depositForm.text3": "#VALUE!",
    "depositForm.text4": "#VALUE!",
    "depositForm.text5": "#VALUE!",
    "depositForm.text6": "#VALUE!",
    "depositForm.text7": "#VALUE!",
    "depositForm.text8": "#VALUE!",
    "depositForm.text9": "#VALUE!",
    "depositForm.text10": "#VALUE!",
    "depositForm.text11": "#VALUE!",
    "depositForm.text12": "#VALUE!",
    "depositForm.text13": "#VALUE!",
    "depositForm.text14": "#VALUE!",
    "depositForm.text15": "#VALUE!",
    "depositForm.text16": "#VALUE!",
    "depositForm.text17": "#VALUE!",
    "__2": "",
    "stripeCardPay.text1": "#VALUE!",
    "stripeCardPay.text2": "#VALUE!",
    "stripeCardPay.text3": "#VALUE!",
    "stripeCardPay.text4": "#VALUE!"
  },
  {
    "LANGUAGE": "Tamil",
    "CODE": "ta",
    "general.text1": "வணக்கம்",
    "general.text2": "நன்றி",
    "general.text3": "வரவேற்பு",
    "general.text4": "நன்றி",
    "general.text5": "பங்குச் சந்தை",
    "general.text6": "பரிமாற்றம்",
    "general.text7": "பணப்பை",
    "general.text8": "அனுப்புக",
    "general.text9": "பணம் அனுப்பு",
    "general.text10": "பெறும்",
    "general.text11": "பணம் பெறும்",
    "general.text12": "வர்த்தக",
    "general.text13": "பங்கு வர்த்தகம்",
    "general.text14": "திறன்பேசி",
    "general.text15": "உங்கள் கையில்",
    "general.text16": "விளக்கம்",
    "general.text17": "தேதி",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "சந்தை",
    "general.text21": "தகவல்",
    "general.text22": "விற்க",
    "general.text23": "வாங்க",
    "general.text24": "பரிவர்த்தனை",
    "general.text25": "வகை",
    "general.text26": "நிர்வகிக்க",
    "general.text27": "கட்டுப்பாடு",
    "general.text28": "தேடல்",
    "general.text29": "XX",
    "general.text30": "நிலுவைகளை",
    "general.text31": "ஏற்க",
    "general.text32": "வரலாறு",
    "general.text33": "பரிவர்த்தனை சாதனை",
    "general.text34": "XX",
    "general.text35": "இலக்கு",
    "general.text36": "தேர்வு",
    "general.text37": "தொகுப்பு",
    "general.text38": "சொத்து",
    "general.text39": "உள்ளீடு",
    "general.text40": "நுழைய",
    "general.text41": "அளவு",
    "general.text42": "பாதையை கண்டறிய",
    "general.text43": "மதிப்பிடப்பட்டுள்ளது கட்டண",
    "general.text44": "கட்டணம்",
    "general.text45": "தவிர",
    "general.text46": "பரிந்துரைக்கப்படுகிறது",
    "general.text47": "உங்கள் இருப்பு காட்ட",
    "general.text48": "உறுதிசெய் உள்ளீடுகள்",
    "general.text49": "தெளிவான உள்ளீடுகள்",
    "general.text50": "கணக்கு",
    "general.text51": "வங்கி கணக்கு",
    "general.text52": "கட்டணம்",
    "general.text53": "இரண்டு படி",
    "general.text54": "உங்கள்",
    "general.text55": "XX",
    "general.text56": "பயன்பாட்டு விதிமுறைகளை",
    "general.text57": "தனியுரிமை கொள்கை",
    "": "",
    "toppage.text1": "வைப்புத்தொகை XLM உங்கள் கணக்கு ஐடி ஒரு கடன் அட்டை",
    "toppage.text2": "(நீங்கள் கணக்கு ஐடி இல்லை என்றால், நீங்கள் StellarSpace தற்போது பயன்பாடு பயன்படுத்தி கணக்கு ஐடி உருவாக்க முடியும்)",
    "toppage.text3": "மொழி",
    "toppage.text4": "மாற்றம்",
    "__1": "",
    "depositForm.text1": "வைப்புத்தொகை வெற்றி",
    "depositForm.text2": "வைப்பு தோல்வி",
    "depositForm.text3": "வைப்பு செயல்படுத்தப்படுகிறது ...",
    "depositForm.text4": "உங்கள் வைப்பு பரிவர்த்தனை ஐடி வெற்றிகரமாக நிறைவு செய்திருக்கிறார்:",
    "depositForm.text5": "உங்கள் வைப்பு வெற்றிகரமாக முடிக்க முடியவில்லை. நீங்கள் குற்றஞ்சாட்டப்பட்டுள்ளனர் என்றால், ஆதரவை தொடர்பு கொள்க தயவு",
    "depositForm.text6": "உங்கள் வைப்பு செயலாக்கத்தில் இருப்பதால், நாங்கள் உங்கள் பணத்தை அனுப்பலாம் முயற்சிக்கின்றன.",
    "depositForm.text7": "நெருக்கமான",
    "depositForm.text8": "வைப்புத்தொகை அளவு",
    "depositForm.text9": "கணக்கு ஐடி",
    "depositForm.text10": "கடன் அட்டை மூலம் செலுத்து",
    "depositForm.text11": "வைப்பு 3.6% கிரெடிட்கார்டு கட்டணம் வசூலிக்கப்படுகிறது மற்றும் குறைந்தபட்ச 5 டாலர் ($ 5) அளவு (மேலும் $ 10 க்கும் பரிந்துரைக்கப்படுகிறது) ஆகும்.",
    "depositForm.text12": "நாம் தவறான கணக்கு ID களுக்கு பொறுப்பாகாது.",
    "depositForm.text13": "கணக்கிடப்பட்ட XLM அளவு நீங்கள் வேண்டும்",
    "depositForm.text14": "XLM விலையின் அடிப்படையில்",
    "depositForm.text15": "விலை",
    "depositForm.text16": "மேம்படுத்தல்",
    "depositForm.text17": "தவறான கணக்கு ஐடி",
    "__2": "",
    "stripeCardPay.text1": "மீண்டும் முயற்சி செய்",
    "stripeCardPay.text2": "அட்டை கட்டணம் தொடர",
    "stripeCardPay.text3": "கிரெடிட் கார்டில் பெயர்",
    "stripeCardPay.text4": "ரீசெட் உள்ளீடுகள்"
  },
  {
    "LANGUAGE": "Telugu",
    "CODE": "te",
    "general.text1": "హలో",
    "general.text2": "ధన్యవాదాలు",
    "general.text3": "స్వాగతం",
    "general.text4": "ధన్యవాదాలు",
    "general.text5": "స్టాక్ మార్పిడి",
    "general.text6": "మార్పిడి",
    "general.text7": "జేబు",
    "general.text8": "పంపు",
    "general.text9": "పంపు డబ్బు",
    "general.text10": "అందుకుంటారు",
    "general.text11": "డబ్బు అందుకుంటారు",
    "general.text12": "ట్రేడింగ్",
    "general.text13": "స్టాక్ ట్రేడింగ్",
    "general.text14": "స్మార్ట్ఫోన్",
    "general.text15": "మీ చేతిలో",
    "general.text16": "వివరణ",
    "general.text17": "తేదీ",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "సంత",
    "general.text21": "సమాచారం",
    "general.text22": "అమ్మకపు",
    "general.text23": "కొనుగోలు",
    "general.text24": "లావాదేవీ",
    "general.text25": "రకం",
    "general.text26": "నిర్వహించడానికి",
    "general.text27": "నియంత్రణ",
    "general.text28": "వెతకండి",
    "general.text29": "xx",
    "general.text30": "బకాయిలను",
    "general.text31": "అంగీకరించాలి",
    "general.text32": "చరిత్ర",
    "general.text33": "లావాదేవీ రికార్డు",
    "general.text34": "xx",
    "general.text35": "గమ్యం",
    "general.text36": "ఎంచుకోండి",
    "general.text37": "సెట్",
    "general.text38": "ఆస్తి",
    "general.text39": "ఇన్పుట్",
    "general.text40": "నమోదు",
    "general.text41": "మొత్తం",
    "general.text42": "మార్గం కనుగొనేందుకు",
    "general.text43": "అంచనా వ్యయం",
    "general.text44": "ఫీజు",
    "general.text45": "మినహాయించి",
    "general.text46": "సిఫార్సు",
    "general.text47": "మీ సంతులనం చూపించు",
    "general.text48": "కన్ఫర్మ్ ఇన్పుట్లను",
    "general.text49": "స్పష్టమైన ఇన్పుట్లను",
    "general.text50": "ఖాతా",
    "general.text51": "బ్యాంకు ఖాతా",
    "general.text52": "చెల్లింపు",
    "general.text53": "రెండు దశల",
    "general.text54": "మీ",
    "general.text55": "xx",
    "general.text56": "ఉపయోగ నిబంధనలు",
    "general.text57": "గోప్యతా విధానం",
    "": "",
    "toppage.text1": "ఒక క్రెడిట్ కార్డ్ తో మీ ఖాతా ID డిపాజిట్ XLM",
    "toppage.text2": "(మీరు లేకపోతే ఖాతా ID, మీరు StellarSpace వద్ద App ఉపయోగించి ఖాతా ID రూపొందించవచ్చు)",
    "toppage.text3": "భాషా",
    "toppage.text4": "మార్చు",
    "__1": "",
    "depositForm.text1": "డిపాజిట్ విజయం",
    "depositForm.text2": "డిపాజిట్ విఫలమైంది",
    "depositForm.text3": "డిపాజిట్ ప్రాసెస్ చేస్తోంది ...",
    "depositForm.text4": "మీ డిపాజిట్ లావాదేవీ ID తో విజయవంతంగా పూర్తయ్యిన:",
    "depositForm.text5": "మీ డిపాజిట్ విజయవంతంగా పూర్తి కాలేదు. మీరు ఛార్జ్ ఉన్నట్లయితే, దయచేసి మద్దతును సంప్రదించండి",
    "depositForm.text6": "మీ డిపాజిట్ ప్రాసెస్ అవుతోంది మరియు మేము మీ నిధులను పంపడానికి ప్రయత్నిస్తోంది ఉంటాయి.",
    "depositForm.text7": "దగ్గరగా",
    "depositForm.text8": "డిపాజిట్ పరిమాణం",
    "depositForm.text9": "ఖాతా గుర్తింపు",
    "depositForm.text10": "క్రెడిట్ కార్డ్ తో పే",
    "depositForm.text11": "డిపాజిట్లు 3.6% క్రెడిట్ కార్డు రుసుము వసూలు చేస్తారు మరియు కనిష్ట 5 డాలర్ల ($ 5) మొత్తం (కంటే ఎక్కువ $ 10 మద్దతిస్తుంది) ఉంది.",
    "depositForm.text12": "మేము తప్పు ఖాతా ID లు కోసం బాధ్యత కాదు.",
    "depositForm.text13": "అంచనా XLM మొత్తం మీరు ఉంటుంది",
    "depositForm.text14": "XLM ధర ఆధారంగా",
    "depositForm.text15": "ధర",
    "depositForm.text16": "నవీకరణ",
    "depositForm.text17": "చెల్లని ఖాతా ID",
    "__2": "",
    "stripeCardPay.text1": "మళ్ళీ ప్రయత్నించండి",
    "stripeCardPay.text2": "కార్డు చెల్లింపు వెళ్లండి",
    "stripeCardPay.text3": "క్రెడిట్ కార్డు మీద పేరు",
    "stripeCardPay.text4": "రీసెట్ ఇన్పుట్లను"
  },
  {
    "LANGUAGE": "Thai",
    "CODE": "th",
    "general.text1": "สวัสดี",
    "general.text2": "ขอบคุณ",
    "general.text3": "ยินดีต้อนรับ",
    "general.text4": "ขอบคุณ",
    "general.text5": "ตลาดหลักทรัพย์",
    "general.text6": "แลกเปลี่ยน",
    "general.text7": "กระเป๋าสตางค์",
    "general.text8": "ส่ง",
    "general.text9": "ส่งเงิน",
    "general.text10": "รับ",
    "general.text11": "รับเงิน",
    "general.text12": "การค้าขาย",
    "general.text13": "การซื้อขายหุ้น",
    "general.text14": "มาร์ทโฟน",
    "general.text15": "ในมือของคุณ",
    "general.text16": "ลักษณะ",
    "general.text17": "วันที่",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "ตลาด",
    "general.text21": "ข้อมูล",
    "general.text22": "ขาย",
    "general.text23": "ซื้อ",
    "general.text24": "การซื้อขาย",
    "general.text25": "ชนิด",
    "general.text26": "จัดการ",
    "general.text27": "ควบคุม",
    "general.text28": "ค้นหา",
    "general.text29": "xx",
    "general.text30": "ยอดคงเหลือ",
    "general.text31": "ยอมรับ",
    "general.text32": "ประวัติศาสตร์",
    "general.text33": "บันทึกรายการ",
    "general.text34": "xx",
    "general.text35": "ปลายทาง",
    "general.text36": "เลือก",
    "general.text37": "ชุด",
    "general.text38": "สินทรัพย์",
    "general.text39": "อินพุต",
    "general.text40": "เข้าสู่",
    "general.text41": "จำนวน",
    "general.text42": "หาเส้นทาง",
    "general.text43": "ค่าใช้จ่ายประมาณ",
    "general.text44": "ค่าธรรมเนียม",
    "general.text45": "ไม่รวม",
    "general.text46": "แนะนำ",
    "general.text47": "แสดงยอดเงินของคุณ",
    "general.text48": "ปัจจัยการผลิตยืนยัน",
    "general.text49": "ปัจจัยการผลิตที่ชัดเจน",
    "general.text50": "บัญชีผู้ใช้",
    "general.text51": "บัญชีธนาคาร",
    "general.text52": "การชำระเงิน",
    "general.text53": "ขั้นตอนที่สอง",
    "general.text54": "ของคุณ",
    "general.text55": "xx",
    "general.text56": "ข้อตกลงในการใช้งาน",
    "general.text57": "นโยบายความเป็นส่วนตัว",
    "": "",
    "toppage.text1": "เงินฝาก XLM กับหมายเลขบัญชีของคุณด้วยบัตรเครดิต",
    "toppage.text2": "(ถ้าคุณไม่ได้มีรหัสบัญชีคุณสามารถสร้างรหัสบัญชีโดยใช้ App ที่ StellarSpace)",
    "toppage.text3": "ภาษา",
    "toppage.text4": "เปลี่ยนแปลง",
    "__1": "",
    "depositForm.text1": "เงินฝากประสบความสำเร็จ",
    "depositForm.text2": "ฝากเงินไม่สำเร็จ",
    "depositForm.text3": "การประมวลผลการฝากเงิน ...",
    "depositForm.text4": "เงินฝากของคุณได้เสร็จสิ้นการประสบความสำเร็จกับการทำธุรกรรม ID:",
    "depositForm.text5": "เงินฝากของคุณไม่เสร็จสมบูรณ์ ถ้าคุณได้รับการเรียกเก็บเงินโปรดติดต่อฝ่ายสนับสนุนที่",
    "depositForm.text6": "เงินฝากของคุณจะถูกประมวลผลและเรากำลังพยายามที่จะส่งเงินของคุณ",
    "depositForm.text7": "ปิด",
    "depositForm.text8": "จำนวนเงินฝาก",
    "depositForm.text9": "หมายเลขบัญชี",
    "depositForm.text10": "ชำระเงินด้วยบัตรเครดิต",
    "depositForm.text11": "เงินฝากจะเรียกเก็บค่าบริการจากบัตรเครดิตที่ 3.6% และต่ำสุดคือ 5 เหรียญสหรัฐ ($ 5) จำนวนเงิน (มากกว่า $ 10 แนะนำ)",
    "depositForm.text12": "เราจะไม่รับผิดชอบสำหรับรหัสบัญชีไม่ถูกต้อง",
    "depositForm.text13": "จำนวนเงินประมาณ XLM คุณจะมี",
    "depositForm.text14": "ขึ้นอยู่กับราคา XLM",
    "depositForm.text15": "ราคา",
    "depositForm.text16": "ปรับปรุง",
    "depositForm.text17": "รหัสบัญชีไม่ถูกต้อง",
    "__2": "",
    "stripeCardPay.text1": "ลองอีกครั้ง",
    "stripeCardPay.text2": "ดำเนินการต่อไปชำระเงินผ่านบัตร",
    "stripeCardPay.text3": "ชื่อบนบัตรเครดิต",
    "stripeCardPay.text4": "ปัจจัยการผลิตรีเซ็ต"
  },
  {
    "LANGUAGE": "Tagalog",
    "CODE": "tl",
    "general.text1": "Kamusta",
    "general.text2": "Salamat",
    "general.text3": "maligayang pagdating",
    "general.text4": "salamat",
    "general.text5": "stock exchange",
    "general.text6": "palitan",
    "general.text7": "pitaka",
    "general.text8": "send",
    "general.text9": "Magpadala ng pera",
    "general.text10": "tumanggap",
    "general.text11": "tumanggap ng pera",
    "general.text12": "kalakalan",
    "general.text13": "pangangalakal ng stock",
    "general.text14": "smartphone",
    "general.text15": "Nasa kamay mo",
    "general.text16": "paglalarawan",
    "general.text17": "petsa",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "merkado",
    "general.text21": "impormasyon",
    "general.text22": "sell",
    "general.text23": "bili",
    "general.text24": "transaksiyon",
    "general.text25": "uri",
    "general.text26": "pamahalaan",
    "general.text27": "kontrol",
    "general.text28": "Hanapan",
    "general.text29": "xx",
    "general.text30": "balanse",
    "general.text31": "tanggapin",
    "general.text32": "kasaysayan",
    "general.text33": "transaksyon record",
    "general.text34": "xx",
    "general.text35": "destinasyon",
    "general.text36": "piliin",
    "general.text37": "itakda",
    "general.text38": "bagay na may halaga",
    "general.text39": "input",
    "general.text40": "magpasok",
    "general.text41": "dami",
    "general.text42": "hanapin landas",
    "general.text43": "tinatayang gastos",
    "general.text44": "bayad",
    "general.text45": "pagliban",
    "general.text46": "inirerekomenda",
    "general.text47": "ipakita ang iyong balanse",
    "general.text48": "kumpirmahin input",
    "general.text49": "malinaw na input",
    "general.text50": "account",
    "general.text51": "Bank account",
    "general.text52": "pagbabayad",
    "general.text53": "dalawang hakbang",
    "general.text54": "iyong",
    "general.text55": "xx",
    "general.text56": "mga Tuntunin ng Paggamit",
    "general.text57": "patakaran sa privacy",
    "": "",
    "toppage.text1": "Deposit XLM sa iyong Account ID gamit ang isang credit card",
    "toppage.text2": "(Kung hindi ka magkaroon ng Account ID, maaari kang bumuo ng Account ID gamit App sa StellarSpace)",
    "toppage.text3": "wika",
    "toppage.text4": "pagbabago",
    "__1": "",
    "depositForm.text1": "Deposit nagtagumpay",
    "depositForm.text2": "nabigo Deposit",
    "depositForm.text3": "Pinoproseso ang deposit ...",
    "depositForm.text4": "Ang iyong deposito ay nakumpleto matagumpay sa transaksyon ID:",
    "depositForm.text5": "Ang iyong deposito ay hindi makumpleto matagumpay. Kung ikaw ay siningil, mangyaring contact ng suporta sa",
    "depositForm.text6": "Ang iyong deposito ay pinoproseso at kami ay sinusubukan upang ipadala ang iyong mga pondo.",
    "depositForm.text7": "malapit",
    "depositForm.text8": "Deposit Dami",
    "depositForm.text9": "Account ID",
    "depositForm.text10": "Pay sa Credit Card",
    "depositForm.text11": "Deposito ay nasingil ang credit card fee na 3.6% at ang minimum ay 5 USD ($ 5) na halaga (mas mababa sa $ 10 ay inirerekomenda).",
    "depositForm.text12": "Kami ay hindi mananagot para sa maling mga ID ng Account.",
    "depositForm.text13": "Tinatayang halaga XLM magkakaroon ka ng",
    "depositForm.text14": "batay sa XLM presyo",
    "depositForm.text15": "presyo",
    "depositForm.text16": "update",
    "depositForm.text17": "di-wastong ID ng Account",
    "__2": "",
    "stripeCardPay.text1": "subukan muli",
    "stripeCardPay.text2": "Magpatuloy upang card sa pagbabayad",
    "stripeCardPay.text3": "pangalan sa credit card",
    "stripeCardPay.text4": "reset input"
  },
  {
    "LANGUAGE": "Tswana",
    "CODE": "tn",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "general.text24": "#VALUE!",
    "general.text25": "#VALUE!",
    "general.text26": "#VALUE!",
    "general.text27": "#VALUE!",
    "general.text28": "#VALUE!",
    "general.text29": "#VALUE!",
    "general.text30": "#VALUE!",
    "general.text31": "#VALUE!",
    "general.text32": "#VALUE!",
    "general.text33": "#VALUE!",
    "general.text34": "#VALUE!",
    "general.text35": "#VALUE!",
    "general.text36": "#VALUE!",
    "general.text37": "#VALUE!",
    "general.text38": "#VALUE!",
    "general.text39": "#VALUE!",
    "general.text40": "#VALUE!",
    "general.text41": "#VALUE!",
    "general.text42": "#VALUE!",
    "general.text43": "#VALUE!",
    "general.text44": "#VALUE!",
    "general.text45": "#VALUE!",
    "general.text46": "#VALUE!",
    "general.text47": "#VALUE!",
    "general.text48": "#VALUE!",
    "general.text49": "#VALUE!",
    "general.text50": "#VALUE!",
    "general.text51": "#VALUE!",
    "general.text52": "#VALUE!",
    "general.text53": "#VALUE!",
    "general.text54": "#VALUE!",
    "general.text55": "#VALUE!",
    "general.text56": "#VALUE!",
    "general.text57": "#VALUE!",
    "": "",
    "toppage.text1": "#VALUE!",
    "toppage.text2": "#VALUE!",
    "toppage.text3": "#VALUE!",
    "toppage.text4": "#VALUE!",
    "__1": "",
    "depositForm.text1": "#VALUE!",
    "depositForm.text2": "#VALUE!",
    "depositForm.text3": "#VALUE!",
    "depositForm.text4": "#VALUE!",
    "depositForm.text5": "#VALUE!",
    "depositForm.text6": "#VALUE!",
    "depositForm.text7": "#VALUE!",
    "depositForm.text8": "#VALUE!",
    "depositForm.text9": "#VALUE!",
    "depositForm.text10": "#VALUE!",
    "depositForm.text11": "#VALUE!",
    "depositForm.text12": "#VALUE!",
    "depositForm.text13": "#VALUE!",
    "depositForm.text14": "#VALUE!",
    "depositForm.text15": "#VALUE!",
    "depositForm.text16": "#VALUE!",
    "depositForm.text17": "#VALUE!",
    "__2": "",
    "stripeCardPay.text1": "#VALUE!",
    "stripeCardPay.text2": "#VALUE!",
    "stripeCardPay.text3": "#VALUE!",
    "stripeCardPay.text4": "#VALUE!"
  },
  {
    "LANGUAGE": "Turkish",
    "CODE": "tr",
    "general.text1": "Merhaba",
    "general.text2": "teşekkür ederim",
    "general.text3": "Hoşgeldiniz",
    "general.text4": "Teşekkürler",
    "general.text5": "Borsa",
    "general.text6": "değiş tokuş",
    "general.text7": "cüzdan",
    "general.text8": "gönderme",
    "general.text9": "para göndermek",
    "general.text10": "teslim almak",
    "general.text11": "para almak",
    "general.text12": "ticari",
    "general.text13": "stok ticaret",
    "general.text14": "akıllı telefon",
    "general.text15": "elinde",
    "general.text16": "açıklama",
    "general.text17": "tarih",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "Market",
    "general.text21": "bilgi",
    "general.text22": "satmak",
    "general.text23": "satın",
    "general.text24": "işlem",
    "general.text25": "tip",
    "general.text26": "yönetmek",
    "general.text27": "kontrol",
    "general.text28": "Arama",
    "general.text29": "xx",
    "general.text30": "bakiyeler",
    "general.text31": "kabul etmek",
    "general.text32": "Tarih",
    "general.text33": "işlem kaydı",
    "general.text34": "xx",
    "general.text35": "hedef",
    "general.text36": "seçmek",
    "general.text37": "Ayarlamak",
    "general.text38": "varlık",
    "general.text39": "giriş",
    "general.text40": "giriş",
    "general.text41": "Miktar",
    "general.text42": "yolu bulmak",
    "general.text43": "tahmini maliyeti",
    "general.text44": "ücret",
    "general.text45": "hariç",
    "general.text46": "önerilen",
    "general.text47": "Dengenizi göstermek",
    "general.text48": "onayla girişler",
    "general.text49": "net girişler",
    "general.text50": "hesap",
    "general.text51": "banka hesabı",
    "general.text52": "ödeme",
    "general.text53": "iki adım",
    "general.text54": "sizin",
    "general.text55": "xx",
    "general.text56": "kullanım Şartları",
    "general.text57": "Gizlilik Politikası",
    "": "",
    "toppage.text1": "Kredi kartıyla Hesap numarası Mevduat XLM",
    "toppage.text2": "(Hesap numarası yoksa, sen StellarSpace de App kullanarak Hesap kimliğini oluşturabilir)",
    "toppage.text3": "Dil",
    "toppage.text4": "Değişiklik",
    "__1": "",
    "depositForm.text1": "mevduat başarılı",
    "depositForm.text2": "Ödeme yapılamadı",
    "depositForm.text3": "depozito işleniyor ...",
    "depositForm.text4": "Depozito işlem kimliğiyle başarıyla tamamlandı:",
    "depositForm.text5": "Kişisel ödemesinin başarıyla tamamlanmadı. Eğer tahsil edilip edilmediğini, iletişim desteğini de memnun",
    "depositForm.text6": "Depozito işleniyor ve dirmemizin göndermeye çalışıyorsunuz.",
    "depositForm.text7": "kapat",
    "depositForm.text8": "mevduat Miktarı",
    "depositForm.text9": "Hesap kimliği",
    "depositForm.text10": "Kredi kartıyla öde",
    "depositForm.text11": "Mevduat% 3.6 kredi kartı ücreti tahsil edilir ve minimum (önerilir fazla 10 $) 5 USD ($ 5) miktarıdır.",
    "depositForm.text12": "Biz yanlış Hesap kimlikleri sorumlu değildir.",
    "depositForm.text13": "Tahmini XLM miktarı olacak",
    "depositForm.text14": "XLM fiyata dayalı",
    "depositForm.text15": "fiyat",
    "depositForm.text16": "Güncelleme",
    "depositForm.text17": "Geçersiz Hesap Kimliği",
    "__2": "",
    "stripeCardPay.text1": "Tekrar deneyin",
    "stripeCardPay.text2": "kartı ödemesi geçin",
    "stripeCardPay.text3": "kredi kartı üzerindeki isim",
    "stripeCardPay.text4": "sıfırlama girişler"
  },
  {
    "LANGUAGE": "Tatar",
    "CODE": "tt",
    "general.text1": "Сәлам",
    "general.text2": "Рәхмәт",
    "general.text3": "рәхим итегез",
    "general.text4": "рәхмәт",
    "general.text5": "фонд биржасы",
    "general.text6": "биржа",
    "general.text7": "букча",
    "general.text8": "җибәрер",
    "general.text9": "җибәрер акча",
    "general.text10": "алу",
    "general.text11": "акча алачак",
    "general.text12": "сәүдә",
    "general.text13": "фондыннан сәүдә",
    "general.text14": "смартфон",
    "general.text15": "Синең кулыңда",
    "general.text16": "тасвир",
    "general.text17": "дата",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "базар",
    "general.text21": "мәгълүмат",
    "general.text22": "сатабыз",
    "general.text23": "busy",
    "general.text24": "килешү",
    "general.text25": "тип",
    "general.text26": "идарә итү",
    "general.text27": "контроль",
    "general.text28": "эзләү",
    "general.text29": "xx",
    "general.text30": "үлчәүдә",
    "general.text31": "кабул итү",
    "general.text32": "тарих",
    "general.text33": "операция рекорд",
    "general.text34": "xx",
    "general.text35": "максат",
    "general.text36": "сайлау",
    "general.text37": "урнаштыру",
    "general.text38": "активлар",
    "general.text39": "кертү",
    "general.text40": "керү",
    "general.text41": "микъдар",
    "general.text42": "юлын табарга",
    "general.text43": "смета бәясе",
    "general.text44": "гонорар",
    "general.text45": "истисна",
    "general.text46": "киңәш",
    "general.text47": "сезнең баланс күрсәтергә",
    "general.text48": "раслый керүләр",
    "general.text49": "ачык керүләр",
    "general.text50": "хисап",
    "general.text51": "банк хисабы",
    "general.text52": "түләү",
    "general.text53": "ике адым",
    "general.text54": "сезнең",
    "general.text55": "xx",
    "general.text56": "куллану шартлары",
    "general.text57": "шәхсилек сәясәте",
    "": "",
    "toppage.text1": "кредит картасы белән хисап ID гә чүплеге XLM",
    "toppage.text2": "(Син хисап ID булмаса, сез StellarSpace да кушымтаны кулланганда хисап ID биляр)",
    "toppage.text3": "тел",
    "toppage.text4": "үзгәртү",
    "__1": "",
    "depositForm.text1": "Банкка уңышлы",
    "depositForm.text2": "Банкка алмады",
    "depositForm.text3": "депозит эшкәртү ...",
    "depositForm.text4": "Сезнең депозит операция ID белән уңышлы тәмамланды:",
    "depositForm.text5": "Сезнең депозит уңышлы тәмамлау түгел. Сез кушты икән, контакт ярдәм риза",
    "depositForm.text6": "Сезнең депозит эшкәртелгән бара һәм без акча җибәрергә ъящд едилир.",
    "depositForm.text7": "якыннан",
    "depositForm.text8": "Банкка Саны",
    "depositForm.text9": "хисап ID",
    "depositForm.text10": "Кредитные карты белән Pay",
    "depositForm.text11": "Ташлау 3,6% кредит картасы түләүле кушты һәм минималь (тәкъдим артык $ 10) 5 доллар ($ 5) түгел.",
    "depositForm.text12": "Без хаталы хисап идентификация өчен җаваплы түгел.",
    "depositForm.text13": "Эюзлянилян XLM күләме син бар",
    "depositForm.text14": "XLM бәясе нигезендә",
    "depositForm.text15": "бәя",
    "depositForm.text16": "яңарту",
    "depositForm.text17": "етибарсыз хисап ID",
    "__2": "",
    "stripeCardPay.text1": "Кабатларга",
    "stripeCardPay.text2": "карта түләү күчү",
    "stripeCardPay.text3": "кредит картасы буенча исеме",
    "stripeCardPay.text4": "әрҗәгә керүләр"
  },
  {
    "LANGUAGE": "Tsonga",
    "CODE": "ts",
    "general.text1": "#VALUE!",
    "general.text2": "#VALUE!",
    "general.text3": "#VALUE!",
    "general.text4": "#VALUE!",
    "general.text5": "#VALUE!",
    "general.text6": "#VALUE!",
    "general.text7": "#VALUE!",
    "general.text8": "#VALUE!",
    "general.text9": "#VALUE!",
    "general.text10": "#VALUE!",
    "general.text11": "#VALUE!",
    "general.text12": "#VALUE!",
    "general.text13": "#VALUE!",
    "general.text14": "#VALUE!",
    "general.text15": "#VALUE!",
    "general.text16": "#VALUE!",
    "general.text17": "#VALUE!",
    "general.text18": "#VALUE!",
    "general.text19": "#VALUE!",
    "general.text20": "#VALUE!",
    "general.text21": "#VALUE!",
    "general.text22": "#VALUE!",
    "general.text23": "#VALUE!",
    "general.text24": "#VALUE!",
    "general.text25": "#VALUE!",
    "general.text26": "#VALUE!",
    "general.text27": "#VALUE!",
    "general.text28": "#VALUE!",
    "general.text29": "#VALUE!",
    "general.text30": "#VALUE!",
    "general.text31": "#VALUE!",
    "general.text32": "#VALUE!",
    "general.text33": "#VALUE!",
    "general.text34": "#VALUE!",
    "general.text35": "#VALUE!",
    "general.text36": "#VALUE!",
    "general.text37": "#VALUE!",
    "general.text38": "#VALUE!",
    "general.text39": "#VALUE!",
    "general.text40": "#VALUE!",
    "general.text41": "#VALUE!",
    "general.text42": "#VALUE!",
    "general.text43": "#VALUE!",
    "general.text44": "#VALUE!",
    "general.text45": "#VALUE!",
    "general.text46": "#VALUE!",
    "general.text47": "#VALUE!",
    "general.text48": "#VALUE!",
    "general.text49": "#VALUE!",
    "general.text50": "#VALUE!",
    "general.text51": "#VALUE!",
    "general.text52": "#VALUE!",
    "general.text53": "#VALUE!",
    "general.text54": "#VALUE!",
    "general.text55": "#VALUE!",
    "general.text56": "#VALUE!",
    "general.text57": "#VALUE!",
    "": "",
    "toppage.text1": "#VALUE!",
    "toppage.text2": "#VALUE!",
    "toppage.text3": "#VALUE!",
    "toppage.text4": "#VALUE!",
    "__1": "",
    "depositForm.text1": "#VALUE!",
    "depositForm.text2": "#VALUE!",
    "depositForm.text3": "#VALUE!",
    "depositForm.text4": "#VALUE!",
    "depositForm.text5": "#VALUE!",
    "depositForm.text6": "#VALUE!",
    "depositForm.text7": "#VALUE!",
    "depositForm.text8": "#VALUE!",
    "depositForm.text9": "#VALUE!",
    "depositForm.text10": "#VALUE!",
    "depositForm.text11": "#VALUE!",
    "depositForm.text12": "#VALUE!",
    "depositForm.text13": "#VALUE!",
    "depositForm.text14": "#VALUE!",
    "depositForm.text15": "#VALUE!",
    "depositForm.text16": "#VALUE!",
    "depositForm.text17": "#VALUE!",
    "__2": "",
    "stripeCardPay.text1": "#VALUE!",
    "stripeCardPay.text2": "#VALUE!",
    "stripeCardPay.text3": "#VALUE!",
    "stripeCardPay.text4": "#VALUE!"
  },
  {
    "LANGUAGE": "Ukrainian",
    "CODE": "uk",
    "general.text1": "Здравствуйте",
    "general.text2": "Дякую",
    "general.text3": "бажаний",
    "general.text4": "Дякую",
    "general.text5": "Фондова біржа",
    "general.text6": "обмін",
    "general.text7": "гаманець",
    "general.text8": "відправити",
    "general.text9": "посилати гроші",
    "general.text10": "отримати",
    "general.text11": "отримувати гроші",
    "general.text12": "торговий",
    "general.text13": "торгівля акціями",
    "general.text14": "смартфон",
    "general.text15": "в руці",
    "general.text16": "опис",
    "general.text17": "дата",
    "general.text18": "хх",
    "general.text19": "хх",
    "general.text20": "ринок",
    "general.text21": "інформація",
    "general.text22": "продам",
    "general.text23": "купити",
    "general.text24": "угода",
    "general.text25": "тип",
    "general.text26": "керувати",
    "general.text27": "контроль",
    "general.text28": "Пошук",
    "general.text29": "хх",
    "general.text30": "противаг",
    "general.text31": "приймати",
    "general.text32": "історія",
    "general.text33": "запис транзакції",
    "general.text34": "хх",
    "general.text35": "призначення",
    "general.text36": "вибрати",
    "general.text37": "набір",
    "general.text38": "актив",
    "general.text39": "вхід",
    "general.text40": "входити",
    "general.text41": "сума",
    "general.text42": "знайти шлях",
    "general.text43": "орієнтовна вартість",
    "general.text44": "плата",
    "general.text45": "виключає",
    "general.text46": "рекомендований",
    "general.text47": "показати свій баланс",
    "general.text48": "підтвердження введення",
    "general.text49": "чіткі входи",
    "general.text50": "рахунок",
    "general.text51": "банківський рахунок",
    "general.text52": "оплата",
    "general.text53": "два кроки",
    "general.text54": "ваш",
    "general.text55": "хх",
    "general.text56": "умови використання",
    "general.text57": "політика конфіденційності",
    "": "",
    "toppage.text1": "Депозит XLM на свій ID рахунку за допомогою кредитної картки",
    "toppage.text2": "(Якщо у вас немає облікового запису ID, ви можете створити ідентифікатор облікового запису за допомогою програми на StellarSpace)",
    "toppage.text3": "Мову",
    "toppage.text4": "зміна",
    "__1": "",
    "depositForm.text1": "Депозит вдалося",
    "depositForm.text2": "Платіж не пройшов",
    "depositForm.text3": "Обробка депозит ...",
    "depositForm.text4": "Ваш депозит успішно завершений з ідентифікатором транзакції:",
    "depositForm.text5": "Ваш депозит не був успішно завершений. Якщо ви були заряджені, будь ласка, зверніться в службу підтримки на",
    "depositForm.text6": "Ваш депозит обробляється, і ми намагаємося спрямувати свої кошти.",
    "depositForm.text7": "близько",
    "depositForm.text8": "Депозит Кількість",
    "depositForm.text9": "Номер рахунку",
    "depositForm.text10": "Оплата з кредитною карткою",
    "depositForm.text11": "Депозити стягується збір кредитної картки 3,6% і мінімум 5 доларів США ($ 5) кількість (понад $ 10 рекомендується).",
    "depositForm.text12": "Ми не несемо відповідальності за неправильні ідентифікатори рахунків.",
    "depositForm.text13": "Розрахункова кількість XLM ви будете мати",
    "depositForm.text14": "на основі XLM ціни",
    "depositForm.text15": "ціна",
    "depositForm.text16": "оновлення",
    "depositForm.text17": "неприпустимий ідентифікатор облікового запису",
    "__2": "",
    "stripeCardPay.text1": "Спробуйте ще раз",
    "stripeCardPay.text2": "Приступити до оплати карти",
    "stripeCardPay.text3": "Ім'я на кредитній картці",
    "stripeCardPay.text4": "скидання входів"
  },
  {
    "LANGUAGE": "Urdu",
    "CODE": "ur",
    "general.text1": "ہیلو",
    "general.text2": "آپ کا شکریہ",
    "general.text3": "خوش آمدید",
    "general.text4": "شکریہ",
    "general.text5": "اسٹاک ایکسچینج",
    "general.text6": "تبادلے",
    "general.text7": "بٹوے",
    "general.text8": "بھیجیں",
    "general.text9": "پیسے بھیجو",
    "general.text10": "وصول",
    "general.text11": "رقم حاصل کریں",
    "general.text12": "ٹریڈنگ",
    "general.text13": "اسٹاک ٹریڈنگ",
    "general.text14": "اسمارٹ فون",
    "general.text15": "آپ کے ہاتھ میں",
    "general.text16": "وضاحت",
    "general.text17": "تاریخ",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "مارکیٹ",
    "general.text21": "معلومات",
    "general.text22": "فروخت",
    "general.text23": "خریدنے",
    "general.text24": "ٹرانزیکشن",
    "general.text25": "قسم",
    "general.text26": "اس کا نظم کریں",
    "general.text27": "اختیار",
    "general.text28": "تلاش",
    "general.text29": "XX",
    "general.text30": "بیلنس",
    "general.text31": "قبول کریں",
    "general.text32": "تاریخ",
    "general.text33": "ٹرانزیکشن ریکارڈ",
    "general.text34": "XX",
    "general.text35": "منزل کے",
    "general.text36": "منتخب",
    "general.text37": "سیٹ",
    "general.text38": "اثاثہ",
    "general.text39": "ان پٹ",
    "general.text40": "درج",
    "general.text41": "رقم",
    "general.text42": "راستہ تلاش",
    "general.text43": "متوقع قیمت",
    "general.text44": "فیس",
    "general.text45": "چھوڑ کر",
    "general.text46": "سفارش",
    "general.text47": "آپ کا بیلنس دکھائے",
    "general.text48": "تصدیق آدانوں",
    "general.text49": "واضح آدانوں",
    "general.text50": "کھاتہ",
    "general.text51": "بینک اکاؤنٹ",
    "general.text52": "ادائیگی کے",
    "general.text53": "دو قدم",
    "general.text54": "آپ",
    "general.text55": "XX",
    "general.text56": "استعمال کی شرائط",
    "general.text57": "رازداری کی پالیسی",
    "": "",
    "toppage.text1": "ایک کریڈٹ کارڈ کے ساتھ آپ کے اکاؤنٹ کی شناخت کو ڈپازٹ XLM",
    "toppage.text2": "(اگر آپ کی اکاؤنٹ ID نہیں ہے تو، آپ StellarSpace میں App استعمال کرکے اکاؤنٹ آئی ڈی حاصل کر سکتے ہیں)",
    "toppage.text3": "زبان",
    "toppage.text4": "تبدیل کریں",
    "__1": "",
    "depositForm.text1": "رقم کا جمع کر کامیاب ہو گئے",
    "depositForm.text2": "ڈپازٹ میں ناکام رہے",
    "depositForm.text3": "ڈپازٹ پروسیسنگ ...",
    "depositForm.text4": "آپ کی جمع ٹرانزیکشن آئی ڈی کے ساتھ کامیابی سے مکمل کر لیا ہے:",
    "depositForm.text5": "آپ کی جمع کامیابی سے مکمل نہیں کیا. آپ کا الزام عائد کیا گیا ہے تو، پر رابطہ کی حمایت کریں",
    "depositForm.text6": "آپ کی جمع کارروائی ہو رہی ہے اور ہم آپ کی رقم بھیجنے کی کوشش کر رہے ہیں.",
    "depositForm.text7": "بند کریں",
    "depositForm.text8": "ڈپازٹ مقدار",
    "depositForm.text9": "اکاؤنٹ کی شناخت",
    "depositForm.text10": "کریڈٹ کارڈ سے ادائیگی",
    "depositForm.text11": "ذخائر 3.6 فیصد کے کریڈٹ کارڈ کی فیس وصول کر رہے ہیں اور کم از کم 5 USD ($ 5) رقم (سے زیادہ $ 10 سفارش کی جاتی ہے) ہے.",
    "depositForm.text12": "ہم غلط اکاؤنٹ آئی ڈیز کے لئے ذمہ دار نہیں ہیں.",
    "depositForm.text13": "متوقع XLM رقم ہے جو آپ حاصل ہو گا",
    "depositForm.text14": "XLM قیمت کی بنیاد پر",
    "depositForm.text15": "قیمت",
    "depositForm.text16": "اپ ڈیٹ",
    "depositForm.text17": "غلط اکاؤنٹ ID",
    "__2": "",
    "stripeCardPay.text1": "دوبارہ کوشش کریں",
    "stripeCardPay.text2": "کارڈ کی ادائیگی کے ڈاؤنلوڈ کریں",
    "stripeCardPay.text3": "کریڈٹ کارڈ پر نام",
    "stripeCardPay.text4": "ری سیٹ کے آدانوں"
  },
  {
    "LANGUAGE": "Uzbek (Latin)",
    "CODE": "uz",
    "general.text1": "Salom",
    "general.text2": "rahmat",
    "general.text3": "Xush kelibsiz",
    "general.text4": "minnatdorchilik",
    "general.text5": "Fond birjasi",
    "general.text6": "ayirboshlash",
    "general.text7": "hamyon",
    "general.text8": "yuborish",
    "general.text9": "Send pul",
    "general.text10": "qabul",
    "general.text11": "pul olish",
    "general.text12": "savdo",
    "general.text13": "aktsiyadorlik savdo",
    "general.text14": "smartfon",
    "general.text15": "Qo'lingga",
    "general.text16": "Tavsif",
    "general.text17": "sana",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "bozor",
    "general.text21": "ma `lumot",
    "general.text22": "sotish",
    "general.text23": "buy",
    "general.text24": "operatsiya",
    "general.text25": "shrift",
    "general.text26": "boshqarish",
    "general.text27": "boshqaruv",
    "general.text28": "Qidirmoq",
    "general.text29": "xx",
    "general.text30": "muvozanat",
    "general.text31": "qabul",
    "general.text32": "tarix",
    "general.text33": "bitim rekord",
    "general.text34": "xx",
    "general.text35": "manzil",
    "general.text36": "tanlash",
    "general.text37": "o'rnatilgan",
    "general.text38": "aktivlar",
    "general.text39": "kiritish",
    "general.text40": "kiritish",
    "general.text41": "miqdor",
    "general.text42": "yo'lini topish",
    "general.text43": "taxmin qiymati",
    "general.text44": "haq",
    "general.text45": "bundan mustasno",
    "general.text46": "tavsiya",
    "general.text47": "hisobingizni ko'rsatish",
    "general.text48": "tasdiqlang kirishlar",
    "general.text49": "aniq kirishlar",
    "general.text50": "hisob",
    "general.text51": "bank hisob raqami",
    "general.text52": "to'lov",
    "general.text53": "ikki qadam",
    "general.text54": "sizning",
    "general.text55": "xx",
    "general.text56": "foydalanish shartlari",
    "general.text57": "Maxfiylik siyosati",
    "": "",
    "toppage.text1": "Agar kredit karta bilan qaydnomasi ID uchun depozit xlm",
    "toppage.text2": "(Hisob ID yo'q bo'lsa, StellarSpace da App yordamida qaydnomasi ID mumkin)",
    "toppage.text3": "til",
    "toppage.text4": "o'zgarish",
    "__1": "",
    "depositForm.text1": "depozit muvaffaq",
    "depositForm.text2": "depozit olmadi",
    "depositForm.text3": "omonatini ishlash ...",
    "depositForm.text4": "Sizning depozit bitim ID muvaffaqiyatli yakunlandi:",
    "depositForm.text5": "Sizning depozit muvaffaqiyatli bajarish bermadi. Agar zaryadlangan bo'lsa, aloqa qo'llab-quvvatlash da mamnun",
    "depositForm.text6": "Sizning depozit qayta ishlanmoqda va biz sizning pul yuborish uchun harakat qilinadi.",
    "depositForm.text7": "yaqin",
    "depositForm.text8": "depozit Soni",
    "depositForm.text9": "Account ID",
    "depositForm.text10": "Kredit karta bilan to'lash",
    "depositForm.text11": "Omonatlar 3,6% kredit karta haq va minimal (tavsiya etiladi ortiq $ 10) 5 AQSh dollari ($ 5) miqdori.",
    "depositForm.text12": "Biz noto'g'ri hisobi identifikatorlari uchun javobgar emas.",
    "depositForm.text13": "Taxminiy xlm miqdori Agar bo'ladi",
    "depositForm.text14": "Xlm narxi asoslangan",
    "depositForm.text15": "narx",
    "depositForm.text16": "yangilash",
    "depositForm.text17": "yaroqsiz hisob raqami",
    "__2": "",
    "stripeCardPay.text1": "Qayta urinib ko'ring",
    "stripeCardPay.text2": "karta to'lov davom eting",
    "stripeCardPay.text3": "kredit kartada nomi",
    "stripeCardPay.text4": "Reset kirishlar"
  },
  {
    "LANGUAGE": "Vietnamese",
    "CODE": "vi",
    "general.text1": "xin chào",
    "general.text2": "Cảm ơn bạn",
    "general.text3": "Chào mừng bạn",
    "general.text4": "Cảm ơn",
    "general.text5": "chứng khoán",
    "general.text6": "đổi",
    "general.text7": "ví tiền",
    "general.text8": "gửi",
    "general.text9": "gửi tiền",
    "general.text10": "nhận được",
    "general.text11": "nhận tiền",
    "general.text12": "thương mại",
    "general.text13": "kinh doanh chứng khoán",
    "general.text14": "điện thoại thông minh",
    "general.text15": "trong tay bạn",
    "general.text16": "sự miêu tả",
    "general.text17": "ngày",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "thị trường",
    "general.text21": "thông tin",
    "general.text22": "bán",
    "general.text23": "mua",
    "general.text24": "Giao dịch",
    "general.text25": "kiểu",
    "general.text26": "quản lý",
    "general.text27": "điều khiển",
    "general.text28": "Tìm kiếm",
    "general.text29": "xx",
    "general.text30": "cân bằng",
    "general.text31": "Chấp nhận",
    "general.text32": "lịch sử",
    "general.text33": "ghi lại giao dịch",
    "general.text34": "xx",
    "general.text35": "Nơi Đến",
    "general.text36": "lựa chọn",
    "general.text37": "bộ",
    "general.text38": "tài sản",
    "general.text39": "đầu vào",
    "general.text40": "đi vào",
    "general.text41": "lượng",
    "general.text42": "tìm con đường",
    "general.text43": "Chi phí ước tính",
    "general.text44": "học phí",
    "general.text45": "Không bao gồm",
    "general.text46": "khuyến khích",
    "general.text47": "hiển thị số dư của bạn",
    "general.text48": "đầu vào xác nhận",
    "general.text49": "đầu vào rõ ràng",
    "general.text50": "tài khoản",
    "general.text51": "tài khoản ngân hàng",
    "general.text52": "thanh toán",
    "general.text53": "hai bước",
    "general.text54": "của bạn",
    "general.text55": "xx",
    "general.text56": "điều khoản sử dụng",
    "general.text57": "Chính sách bảo mật",
    "": "",
    "toppage.text1": "Tiền đặt cọc XLM để ID tài khoản của bạn bằng thẻ tín dụng",
    "toppage.text2": "(Nếu bạn không có ID tài khoản, bạn có thể tạo ID tài khoản sử dụng ứng dụng ở StellarSpace)",
    "toppage.text3": "ngôn ngữ",
    "toppage.text4": "Thay đổi",
    "__1": "",
    "depositForm.text1": "huy động thành công",
    "depositForm.text2": "Tiền đặt cọc thất bại",
    "depositForm.text3": "Chế biến tiền gửi ...",
    "depositForm.text4": "tiền gửi của bạn đã hoàn thành với ID giao dịch:",
    "depositForm.text5": "tiền gửi của bạn không hoàn thành công. Nếu bạn đã bị buộc tội, xin vui lòng liên hệ hỗ trợ tại",
    "depositForm.text6": "tiền gửi của bạn đang được xử lý và chúng tôi đang cố gắng để gửi tiền của bạn.",
    "depositForm.text7": "đóng",
    "depositForm.text8": "Tiền đặt cọc Số lượng",
    "depositForm.text9": "ID tài khoản",
    "depositForm.text10": "Trả bằng thẻ tín dụng",
    "depositForm.text11": "Tiền gửi được tính phí thẻ tín dụng 3,6% và tối thiểu là 5 USD ($ 5) số lượng (nhiều hơn $ 10 được đề nghị).",
    "depositForm.text12": "Chúng tôi không chịu trách nhiệm cho các ID tài khoản không chính xác.",
    "depositForm.text13": "lượng XLM ước tính bạn sẽ có",
    "depositForm.text14": "dựa trên giá XLM",
    "depositForm.text15": "giá bán",
    "depositForm.text16": "cập nhật",
    "depositForm.text17": "ID tài khoản không hợp lệ",
    "__2": "",
    "stripeCardPay.text1": "Thử lại",
    "stripeCardPay.text2": "Tiến hành thanh toán thẻ",
    "stripeCardPay.text3": "tên trên thẻ tín dụng",
    "stripeCardPay.text4": "đầu vào thiết lập lại"
  },
  {
    "LANGUAGE": "Xhosa",
    "CODE": "xh",
    "general.text1": "Mholo",
    "general.text2": "Enkosi",
    "general.text3": "Wamkelekile",
    "general.text4": "Enkosi",
    "general.text5": "utshintsho lesitokhwe",
    "general.text6": "exchange",
    "general.text7": "wallet",
    "general.text8": "uthumele",
    "general.text9": "imali thumela",
    "general.text10": "kufumana",
    "general.text11": "ukufumana imali",
    "general.text12": "trading",
    "general.text13": "urhwebo stock",
    "general.text14": "smart",
    "general.text15": "esandleni sakho",
    "general.text16": "incazelo",
    "general.text17": "usuku",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "market",
    "general.text21": "ulwazi",
    "general.text22": "ethengisa",
    "general.text23": "thenga",
    "general.text24": "transaction",
    "general.text25": "uhlobo",
    "general.text26": "uphathe",
    "general.text27": "ukulawula",
    "general.text28": "khangela",
    "general.text29": "xx",
    "general.text30": "ukulinganisa",
    "general.text31": "ukwamukela",
    "general.text32": "imbali",
    "general.text33": "irekhodi transaction",
    "general.text34": "xx",
    "general.text35": "indawo ekuyiwa kuyo",
    "general.text36": "khetha",
    "general.text37": "iseti",
    "general.text38": "impahla",
    "general.text39": "input",
    "general.text40": "ufake",
    "general.text41": "imali",
    "general.text42": "ukufumana indlela",
    "general.text43": "iindleko ezithekelelwayo",
    "general.text44": "imali",
    "general.text45": "ngaphandle",
    "general.text46": "Kunconywa",
    "general.text47": "sibonise ukulinganisela kwakho",
    "general.text48": "amagalelo ziqinisekisa",
    "general.text49": "igalelo ecacileyo",
    "general.text50": "akhawunti",
    "general.text51": "i-akhawunti yebhanki",
    "general.text52": "inkokhelo",
    "general.text53": "inyathelo lesibini",
    "general.text54": "yakho",
    "general.text55": "xx",
    "general.text56": "imimmiselo yokusebenzisa",
    "general.text57": "umthetho wabucala",
    "": "",
    "toppage.text1": "Idiposithi XLM kwi ID yakho akhawunti ngecredit card",
    "toppage.text2": "(Ukuba awunaso isazisi Akhawunti, ungenza uzakujikelezisa ID akhawunti usebenzisa App e StellarSpace)",
    "toppage.text3": "Language",
    "toppage.text4": "Guqula",
    "__1": "",
    "depositForm.text1": "idiphozithi waphumelela",
    "depositForm.text2": "idiphozithi akuphumelelanga",
    "depositForm.text3": "Ukuproseswa idiphozithi ...",
    "depositForm.text4": "idipozithi yakho wagqitywa ngempumelelo kunye katsaka ID:",
    "depositForm.text5": "idipozithi yakho zange ayigqibe ngempumelelo. Ukuba icala, ukwanelisa xhumana noxhaso",
    "depositForm.text6": "idipozithi yakho iyenziwa kwaye izama ukuthumela imali yakho.",
    "depositForm.text7": "close",
    "depositForm.text8": "idiphozithi Ubungakanani",
    "depositForm.text9": "ID akhawunti",
    "depositForm.text10": "Hlawula ne Credit Card",
    "depositForm.text11": "Imali ebizwayo intlawulo ikhadi lamatyala ka 3.6% kunye ubuncinane yi-5 USD ($ 5) Isixa (ngaphezu kwe $ 10 kucetyiswa).",
    "depositForm.text12": "Asinaxanduva ukuba ID akhawunti ezingachanekanga.",
    "depositForm.text13": "wena XLM Uqikelelo mali kuya kufuneka",
    "depositForm.text14": "zisekelwe kwixabiso XLM",
    "depositForm.text15": "inani",
    "depositForm.text16": "update",
    "depositForm.text17": "ID akhawunti ayisebenzi",
    "__2": "",
    "stripeCardPay.text1": "Zama kwakhona",
    "stripeCardPay.text2": "Qubeka nentlawulo yekhadi",
    "stripeCardPay.text3": "Name on credit card",
    "stripeCardPay.text4": "igalelo ukuhlela"
  },
  {
    "LANGUAGE": "Chinese",
    "CODE": "zh",
    "general.text1": "你好",
    "general.text2": "谢谢",
    "general.text3": "欢迎",
    "general.text4": "谢谢",
    "general.text5": "股票交易",
    "general.text6": "交换",
    "general.text7": "钱包",
    "general.text8": "发送",
    "general.text9": "寄钱",
    "general.text10": "接收",
    "general.text11": "收到钱",
    "general.text12": "贸易",
    "general.text13": "股票交易",
    "general.text14": "手机",
    "general.text15": "在你的手",
    "general.text16": "描述",
    "general.text17": "日期",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "市场",
    "general.text21": "信息",
    "general.text22": "卖",
    "general.text23": "购买",
    "general.text24": "交易",
    "general.text25": "类型",
    "general.text26": "管理",
    "general.text27": "控制",
    "general.text28": "搜索",
    "general.text29": "XX",
    "general.text30": "结余",
    "general.text31": "接受",
    "general.text32": "历史",
    "general.text33": "交易记录",
    "general.text34": "XX",
    "general.text35": "目的地",
    "general.text36": "选择",
    "general.text37": "组",
    "general.text38": "财富",
    "general.text39": "输入",
    "general.text40": "输入",
    "general.text41": "量",
    "general.text42": "找到路径",
    "general.text43": "估计费用",
    "general.text44": "费用",
    "general.text45": "排除",
    "general.text46": "推荐的",
    "general.text47": "显示您的余额",
    "general.text48": "确认输入",
    "general.text49": "清除输入",
    "general.text50": "帐户",
    "general.text51": "银行账户",
    "general.text52": "付款",
    "general.text53": "两步",
    "general.text54": "你的",
    "general.text55": "XX",
    "general.text56": "使用条款",
    "general.text57": "隐私政策",
    "": "",
    "toppage.text1": "存款XLM到您的帐户ID与信用卡",
    "toppage.text2": "（如果你没有账号，你可以在StellarSpace使用App生成的帐号ID）",
    "toppage.text3": "语言",
    "toppage.text4": "改变",
    "__1": "",
    "depositForm.text1": "存款成功",
    "depositForm.text2": "存款失败",
    "depositForm.text3": "处理存款......",
    "depositForm.text4": "您的存款已经与交易ID成功完成：",
    "depositForm.text5": "您的存款没有成功完成。如果你已被起诉，请联系支持在",
    "depositForm.text6": "你的存款正在处理中，我们试图把你的资金。",
    "depositForm.text7": "关闭",
    "depositForm.text8": "存款数量",
    "depositForm.text9": "帐户ID",
    "depositForm.text10": "用信用卡",
    "depositForm.text11": "存款的收费是3.6％的刷卡手续费，最低为5美元（$ 5）量（超过10 $推荐）。",
    "depositForm.text12": "我们不负责不正确的帐户ID。",
    "depositForm.text13": "估计XLM量，你将有",
    "depositForm.text14": "基于XLM价格",
    "depositForm.text15": "价格",
    "depositForm.text16": "更新",
    "depositForm.text17": "无效帐户ID",
    "__2": "",
    "stripeCardPay.text1": "再试一次",
    "stripeCardPay.text2": "前往卡支付",
    "stripeCardPay.text3": "信用卡上的名字",
    "stripeCardPay.text4": "复位输入"
  },
  {
    "LANGUAGE": "Chinese (Simplified)",
    "CODE": "zh-CN",
    "general.text1": "你好",
    "general.text2": "谢谢",
    "general.text3": "欢迎",
    "general.text4": "谢谢",
    "general.text5": "股票交易",
    "general.text6": "交换",
    "general.text7": "钱包",
    "general.text8": "发送",
    "general.text9": "寄钱",
    "general.text10": "接收",
    "general.text11": "收到钱",
    "general.text12": "贸易",
    "general.text13": "股票交易",
    "general.text14": "手机",
    "general.text15": "在你的手",
    "general.text16": "描述",
    "general.text17": "日期",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "市场",
    "general.text21": "信息",
    "general.text22": "卖",
    "general.text23": "购买",
    "general.text24": "交易",
    "general.text25": "类型",
    "general.text26": "管理",
    "general.text27": "控制",
    "general.text28": "搜索",
    "general.text29": "XX",
    "general.text30": "结余",
    "general.text31": "接受",
    "general.text32": "历史",
    "general.text33": "交易记录",
    "general.text34": "XX",
    "general.text35": "目的地",
    "general.text36": "选择",
    "general.text37": "组",
    "general.text38": "财富",
    "general.text39": "输入",
    "general.text40": "输入",
    "general.text41": "量",
    "general.text42": "找到路径",
    "general.text43": "估计费用",
    "general.text44": "费用",
    "general.text45": "排除",
    "general.text46": "推荐的",
    "general.text47": "显示您的余额",
    "general.text48": "确认输入",
    "general.text49": "清除输入",
    "general.text50": "帐户",
    "general.text51": "银行账户",
    "general.text52": "付款",
    "general.text53": "两步",
    "general.text54": "你的",
    "general.text55": "XX",
    "general.text56": "使用条款",
    "general.text57": "隐私政策",
    "": "",
    "toppage.text1": "存款XLM到您的帐户ID与信用卡",
    "toppage.text2": "（如果你没有账号，你可以在StellarSpace使用App生成的帐号ID）",
    "toppage.text3": "语言",
    "toppage.text4": "改变",
    "__1": "",
    "depositForm.text1": "存款成功",
    "depositForm.text2": "存款失败",
    "depositForm.text3": "处理存款......",
    "depositForm.text4": "您的存款已经与交易ID成功完成：",
    "depositForm.text5": "您的存款没有成功完成。如果你已被起诉，请联系支持在",
    "depositForm.text6": "你的存款正在处理中，我们试图把你的资金。",
    "depositForm.text7": "关闭",
    "depositForm.text8": "存款数量",
    "depositForm.text9": "帐户ID",
    "depositForm.text10": "用信用卡",
    "depositForm.text11": "存款的收费是3.6％的刷卡手续费，最低为5美元（$ 5）量（超过10 $推荐）。",
    "depositForm.text12": "我们不负责不正确的帐户ID。",
    "depositForm.text13": "估计XLM量，你将有",
    "depositForm.text14": "基于XLM价格",
    "depositForm.text15": "价格",
    "depositForm.text16": "更新",
    "depositForm.text17": "无效帐户ID",
    "__2": "",
    "stripeCardPay.text1": "再试一次",
    "stripeCardPay.text2": "前往卡支付",
    "stripeCardPay.text3": "信用卡上的名字",
    "stripeCardPay.text4": "复位输入"
  },
  {
    "LANGUAGE": "Chinese (Hong Kong)",
    "CODE": "zh-HK",
    "general.text1": "你好",
    "general.text2": "謝謝",
    "general.text3": "歡迎",
    "general.text4": "謝謝",
    "general.text5": "股票交易",
    "general.text6": "交換",
    "general.text7": "錢包",
    "general.text8": "發送",
    "general.text9": "寄錢",
    "general.text10": "接收",
    "general.text11": "收到錢",
    "general.text12": "貿易",
    "general.text13": "股票交易",
    "general.text14": "手機",
    "general.text15": "在你的手",
    "general.text16": "描述",
    "general.text17": "日期",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "市場",
    "general.text21": "信息",
    "general.text22": "賣",
    "general.text23": "購買",
    "general.text24": "交易",
    "general.text25": "類型",
    "general.text26": "管理",
    "general.text27": "控制",
    "general.text28": "搜索",
    "general.text29": "XX",
    "general.text30": "結餘",
    "general.text31": "接受",
    "general.text32": "歷史",
    "general.text33": "交易記錄",
    "general.text34": "XX",
    "general.text35": "目的地",
    "general.text36": "選擇",
    "general.text37": "組",
    "general.text38": "財富",
    "general.text39": "輸入",
    "general.text40": "輸入",
    "general.text41": "量",
    "general.text42": "找到路徑",
    "general.text43": "估計費用",
    "general.text44": "費用",
    "general.text45": "排除",
    "general.text46": "推薦的",
    "general.text47": "顯示您的餘額",
    "general.text48": "確認輸入",
    "general.text49": "清除輸入",
    "general.text50": "帳戶",
    "general.text51": "銀行賬戶",
    "general.text52": "付款",
    "general.text53": "兩步",
    "general.text54": "你的",
    "general.text55": "XX",
    "general.text56": "使用條款",
    "general.text57": "隱私政策",
    "": "",
    "toppage.text1": "存款XLM到您的帳戶ID與信用卡",
    "toppage.text2": "（如果你沒有賬號，你可以在StellarSpace使用App生成的帳號ID）",
    "toppage.text3": "語言",
    "toppage.text4": "改變",
    "__1": "",
    "depositForm.text1": "存款成功",
    "depositForm.text2": "存款失敗",
    "depositForm.text3": "處理存款......",
    "depositForm.text4": "您的存款已經與交易ID成功完成：",
    "depositForm.text5": "您的存款沒有成功完成。如果你已被起訴，請聯繫支持在",
    "depositForm.text6": "你的存款正在處理中，我們試圖把你的資金。",
    "depositForm.text7": "關閉",
    "depositForm.text8": "存款數量",
    "depositForm.text9": "帳戶ID",
    "depositForm.text10": "用信用卡",
    "depositForm.text11": "存款的收費是3.6％的刷卡手續費，最低為5美元（$ 5）量（超過10 $推薦）。",
    "depositForm.text12": "我們不負責不正確的帳戶ID。",
    "depositForm.text13": "估計XLM量，你將有",
    "depositForm.text14": "基於XLM價格",
    "depositForm.text15": "價格",
    "depositForm.text16": "更新",
    "depositForm.text17": "無效帳戶ID",
    "__2": "",
    "stripeCardPay.text1": "再試一次",
    "stripeCardPay.text2": "前往卡支付",
    "stripeCardPay.text3": "信用卡上的名字",
    "stripeCardPay.text4": "復位輸入"
  },
  {
    "LANGUAGE": "Chinese (Traditional)",
    "CODE": "zh-TW",
    "general.text1": "你好",
    "general.text2": "謝謝",
    "general.text3": "歡迎",
    "general.text4": "謝謝",
    "general.text5": "股票交易",
    "general.text6": "交換",
    "general.text7": "錢包",
    "general.text8": "發送",
    "general.text9": "寄錢",
    "general.text10": "接收",
    "general.text11": "收到錢",
    "general.text12": "貿易",
    "general.text13": "股票交易",
    "general.text14": "手機",
    "general.text15": "在你的手",
    "general.text16": "描述",
    "general.text17": "日期",
    "general.text18": "XX",
    "general.text19": "XX",
    "general.text20": "市場",
    "general.text21": "信息",
    "general.text22": "賣",
    "general.text23": "購買",
    "general.text24": "交易",
    "general.text25": "類型",
    "general.text26": "管理",
    "general.text27": "控制",
    "general.text28": "搜索",
    "general.text29": "XX",
    "general.text30": "結餘",
    "general.text31": "接受",
    "general.text32": "歷史",
    "general.text33": "交易記錄",
    "general.text34": "XX",
    "general.text35": "目的地",
    "general.text36": "選擇",
    "general.text37": "組",
    "general.text38": "財富",
    "general.text39": "輸入",
    "general.text40": "輸入",
    "general.text41": "量",
    "general.text42": "找到路徑",
    "general.text43": "估計費用",
    "general.text44": "費用",
    "general.text45": "排除",
    "general.text46": "推薦的",
    "general.text47": "顯示您的餘額",
    "general.text48": "確認輸入",
    "general.text49": "清除輸入",
    "general.text50": "帳戶",
    "general.text51": "銀行賬戶",
    "general.text52": "付款",
    "general.text53": "兩步",
    "general.text54": "你的",
    "general.text55": "XX",
    "general.text56": "使用條款",
    "general.text57": "隱私政策",
    "": "",
    "toppage.text1": "存款XLM到您的帳戶ID與信用卡",
    "toppage.text2": "（如果你沒有賬號，你可以在StellarSpace使用App生成的帳號ID）",
    "toppage.text3": "語言",
    "toppage.text4": "改變",
    "__1": "",
    "depositForm.text1": "存款成功",
    "depositForm.text2": "存款失敗",
    "depositForm.text3": "處理存款......",
    "depositForm.text4": "您的存款已經與交易ID成功完成：",
    "depositForm.text5": "您的存款沒有成功完成。如果你已被起訴，請聯繫支持在",
    "depositForm.text6": "你的存款正在處理中，我們試圖把你的資金。",
    "depositForm.text7": "關閉",
    "depositForm.text8": "存款數量",
    "depositForm.text9": "帳戶ID",
    "depositForm.text10": "用信用卡",
    "depositForm.text11": "存款的收費是3.6％的刷卡手續費，最低為5美元（$ 5）量（超過10 $推薦）。",
    "depositForm.text12": "我們不負責不正確的帳戶ID。",
    "depositForm.text13": "估計XLM量，你將有",
    "depositForm.text14": "基於XLM價格",
    "depositForm.text15": "價格",
    "depositForm.text16": "更新",
    "depositForm.text17": "無效帳戶ID",
    "__2": "",
    "stripeCardPay.text1": "再試一次",
    "stripeCardPay.text2": "前往卡支付",
    "stripeCardPay.text3": "信用卡上的名字",
    "stripeCardPay.text4": "復位輸入"
  },
  {
    "LANGUAGE": "Zulu",
    "CODE": "zu",
    "general.text1": "Sawubona",
    "general.text2": "Ngiyabonga",
    "general.text3": "Siyakwamukela",
    "general.text4": "Siyabonga",
    "general.text5": "ukwehlukana kwamaseli",
    "general.text6": "lokushintshisana",
    "general.text7": "isikhwama",
    "general.text8": "yokuthumela",
    "general.text9": "yokuthumela imali",
    "general.text10": "ukuthola",
    "general.text11": "ukuthola imali",
    "general.text12": "zokuhweba",
    "general.text13": "stock yokuhweba",
    "general.text14": "smartphone",
    "general.text15": "esandleni sakho",
    "general.text16": "incazelo",
    "general.text17": "usuku",
    "general.text18": "xx",
    "general.text19": "xx",
    "general.text20": "emakethe",
    "general.text21": "Imininingwane",
    "general.text22": "ukuthengisa",
    "general.text23": "Thenga",
    "general.text24": "kuthengiselana",
    "general.text25": "uhlobo",
    "general.text26": "ukuphatha",
    "general.text27": "ukulawula",
    "general.text28": "Ukucinga",
    "general.text29": "xx",
    "general.text30": "ezilinganisweni",
    "general.text31": "ukwamukela",
    "general.text32": "umlando",
    "general.text33": "irekhodi kuthengiselana",
    "general.text34": "xx",
    "general.text35": "uya khona",
    "general.text36": "ukhetha",
    "general.text37": "isethi",
    "general.text38": "kwempahla",
    "general.text39": "okokufaka",
    "general.text40": "faka",
    "general.text41": "inani",
    "general.text42": "thola indlela",
    "general.text43": "izindleko ezilinganiselwa",
    "general.text44": "imali",
    "general.text45": "Ngaphandle",
    "general.text46": "Kunconywa",
    "general.text47": "khombisa ibhalansi yakho",
    "general.text48": "qinisekisa okokufaka",
    "general.text49": "okokufaka ecacile",
    "general.text50": "akhawunti",
    "general.text51": "akhawunti yasebhange",
    "general.text52": "yokukhokha",
    "general.text53": "ezimbili",
    "general.text54": "yakho",
    "general.text55": "xx",
    "general.text56": "Imigomo Yokusebenzisa",
    "general.text57": "Inqubomgomo yobumfihlo",
    "": "",
    "toppage.text1": "Idiphozithi XLM ukuba ID ye-akhawunti yakho ngekhadi lesikweletu",
    "toppage.text2": "(Uma ungenayo i-ID ye-Akhawunti, ungakwazi ukukhiqiza ID ye-akhawunti usebenzisa i-App at StellarSpace)",
    "toppage.text3": "Ulimi",
    "toppage.text4": "Guqula",
    "__1": "",
    "depositForm.text1": "Idiphozithi kuphumelele",
    "depositForm.text2": "Idiphozithi kuhlulekile",
    "depositForm.text3": "Icubungula idiphozi ...",
    "depositForm.text4": "idiphozi yakho iqede ngokuphumelelayo ID yomsebenzi:",
    "depositForm.text5": "idiphozi lakho ayiqedenga ngempumelelo. Uma ngabe icala, ukujabulisa xhumana nokusekela ngesikhathi",
    "depositForm.text6": "idiphozi lakho liyasetshenzwa thina ozama ukuthumela imali yakho.",
    "depositForm.text7": "vala",
    "depositForm.text8": "Idiphozithi Ubuningi",
    "depositForm.text9": "ID ye-akhawunti",
    "depositForm.text10": "Khokha nge Credit Card",
    "depositForm.text11": "Imali basolwa imali credit card ka 3.6% kanye obuncane 5 USD ($ 5) isamba (ezingaphezu kuka $ 10 Kunconywa).",
    "depositForm.text12": "Asingene for ayilungile ama-ID ye-akhawunti.",
    "depositForm.text13": "Elilinganiswayo XLM lemali kuzodingeka",
    "depositForm.text14": "esekelwe XLM intengo",
    "depositForm.text15": "intengo",
    "depositForm.text16": "buyekeza",
    "depositForm.text17": "ID ye-akhawunti engavumelekile",
    "__2": "",
    "stripeCardPay.text1": "Zama futhi",
    "stripeCardPay.text2": "Qhubekisa ngokuthwebula lokukhokha ikhadi",
    "stripeCardPay.text3": "Igama ku-credit card",
    "stripeCardPay.text4": "okokufaka ukusetha kabusha"
  }
]